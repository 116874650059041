import React from 'react'
import classes from './ButtonText.module.css'

const Button = props => {

    const buttonClasses = [classes.container]
    if(props.className) {
        buttonClasses.push(props.className)
    }

    if(props.hide) {
        return <></>
    }

    return (
        <div
            className={`${classes.container} ${props.className}`}
            // className={buttonClasses.join(' ')}
            style={{
                ...props.style,
                backgroundColor: props.color,
            }}
            onClick={props.onClick}
        >{props.children}
            <p className={classes.title} id={props.children ? classes.childrenExists : null} >{props.title}</p>
        </div>
    )
}

export default Button