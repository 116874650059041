import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Logo from '../../components/Logo/Logo'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import classes from './Login.module.css'
import environment, { auth } from '../../env/environment'
import * as authActions from '../../store/actions/authActions'

const Login = props => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageInput, setErrorMessageInput] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isResetMode, setIsResetMode] = useState(false)
    const [resetSucceed, setResetSucceed] = useState(false)

    const dispatch = useDispatch()

    const loginHandler = async () => {
        setErrorMessage(null)
        setErrorMessageInput(null)
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(email.toLowerCase())) {
            console.log('Email is not Valid!!!');
            setErrorMessageInput('email')
            setErrorMessage('Por favor insira um endereço de e-mail válido!')
            return
        }
        if (password?.length < 5) {
            setErrorMessageInput('password')
            setErrorMessage('A senha deve conter pelo menos 5 caracteres')
            return
        }

        try {
            setLoading(true)
            await dispatch(authActions.signin(email, password))
            setLoading(false)
        } catch (err) {
            console.log("ERRRRR: ", err)
            let errorMessage = 'Não foi possível fazer o login. Verifique sua conexão com a Internet ou tente novamente mais tarde.'
            if (err.code === 'auth/user-not-found') {
                errorMessage = 'Seu email não está correto!'
                setErrorMessageInput('email')
            }
            if (err.code === 'auth/wrong-password') {
                errorMessage = 'Sua senha não está correta!'
                setErrorMessageInput('password')
            }
            if (err.code === 'auth/too-many-requests') {
                errorMessage = 'O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de login malsucedidas!'
            }

            if (err.message === 'no-permission') {
                errorMessage = "Você não tem permissão para acessar o conteúdo. Apenas administradores podem fazer login."
            }
            setErrorMessage(errorMessage)
            setLoading(false)
        }
    }

    const forgotPasswordHandler = async () => {
        setErrorMessageInput(null)
        setErrorMessage(null)
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailRegex.test(email.toLowerCase())) {
            console.log('Email is not Valid!!!');
            setErrorMessageInput('email')
            setErrorMessage('Por favor insira um endereço de e-mail válido!')
            return
        }
        try {
            setLoading(true)
            const user = await auth.sendPasswordResetEmail(email)
            console.log('This user will reset the password: ', user);
            setLoading(false)
            setResetSucceed(true)
        } catch (err) {
            setErrorMessageInput('email')
            setErrorMessage(err.message)
            setLoading(false)
        }
    }

    return (
        <div className={classes.screen} >
            <div className={classes.card} >
                <div className={classes.left} >
                    <Logo />
                </div>
                <div className={classes.right} >
                    {!isResetMode &&
                        <form className={classes.form} >
                            <Input
                                label='E-Mail'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                shouldValidate
                                invalid={errorMessageInput === 'email'}
                                touched={errorMessage}
                                onSubmit={loginHandler}
                                elementType="input"
                            />
                            <Input
                                label='Senha'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                shouldValidate
                                invalid={errorMessageInput === 'password'}
                                touched={errorMessage}
                                isPassword="password"
                                elementType="input"
                                onSubmit={loginHandler}
                            // elementConfig={{type: 'password'}}
                            />
                            <div className={classes.buttonContainer}>
                                <ButtonBox
                                    loading={loading}
                                    className={classes.enterButton}
                                    title="Entrar" onClick={loginHandler}
                                />
                                <ButtonBox
                                    loading={loading}
                                    className={classes.forgotButton}
                                    title="Esqueceu senha?" onClick={() => { setErrorMessageInput(null); setErrorMessage(null); setIsResetMode(true) }}
                                />
                            </div>
                        </form>
                    }
                    {isResetMode && !resetSucceed &&
                        <form className={classes.form} >
                            <Input
                                label='E-Mail'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                shouldValidate
                                invalid={errorMessageInput === 'email'}
                                touched={errorMessage}
                                elementType="input"
                                onSubmit={forgotPasswordHandler}
                            />
                            <div className={classes.buttonContainer}>
                                <ButtonBox
                                    className={classes.enterButton}
                                    title="Redefinir" onClick={forgotPasswordHandler}
                                />
                                <ButtonBox
                                    className={classes.forgotButton}
                                    title="Voltar" onClick={() => { setErrorMessageInput(null); setErrorMessage(null); setIsResetMode(false) }}
                                />
                            </div>
                        </form>
                    }
                    {isResetMode && resetSucceed &&
                        <form className={classes.form} >
                            <p className={classes.succesMessage}>Um e-mail foi enviado a você para redefinir sua senha.</p>
                            <ButtonBox
                                className={classes.button3}
                                title="Voltar Login" onClick={() => { setErrorMessageInput(null); setErrorMessage(null); setIsResetMode(false) }}
                            />
                        </form>
                    }
                    <p className={classes.error} >{errorMessage}</p>
                </div>
            </div>
        </div>
    )
}

export default Login
