import Module from '../../models/Module'
import SubModule from '../../models/SubModule'
import environment, { db, storage } from '../../env/environment'
import firebase from 'firebase/'

export const SET_MODULES = 'SET_MODULES';
export const CREATE_MODULE = 'CREATE_MODULE';
export const UPDATE_MODULE = 'UPDATE_MODULE';
export const DELETE_MODULE = 'DELETE_MODULE'

export const CREATE_SUBMODULE = 'CREATE_SUBMODULE';
export const UPDATE_SUBMODULE = 'UPDATE_SUBMODULE';
export const DELETE_SUBMODULE = 'DELETE_SUBMODULE';

export const GET_MODULES_VERSION = 'GET_MODULES_VERSION';
export const UPDATE_MODULES_VERSION = 'UPDATE_MODULES_VERSION';
export const CHANGE_ORDER_SUBMODULES = 'CHANGE_ORDER_SUBMODULES';
export const CHANGE_ORDER_MODULES = 'CHANGE_ORDER_MODULES';

export const fetchModules = () => {
    return async (dispatch) => {
        let numberOfSubModulos = 0
        try {
            let loadedModules = [];
            const response = await fetch(`${environment.databaseURL}/modulos.json`);
            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const resData = await response.json();

            // Calculate total submodules number
            for (const i in resData) {

                if (resData[i]?.submodulos) { //// add this to app
                    numberOfSubModulos = numberOfSubModulos + resData[i].submodulos.length + 1
                }
            }
            /////////
            for (const key in resData) {
                if (resData[key]) { // Add this line to app
                    const subModuleData = resData[key].submodulos
                    const loadedSubModules = []
                    if (subModuleData) {
                        for (const subKey in subModuleData) {
                            if (!key || !subKey) {
                                console.log('SOME KEY IS MISSING IN MODULE ACTIONS: ', key, subKey)
                            }
                            if (subModuleData[subKey]) { // add this to app
                                loadedSubModules.push(
                                    new SubModule(
                                        parseInt(subKey),
                                        subModuleData[subKey].listaTextosId,
                                        subModuleData[subKey].idTextoASerExibido,
                                        subModuleData[subKey].title,
                                        subModuleData[subKey].observacao,
                                        subModuleData[subKey].disabled,
                                        subModuleData[subKey].invisivel,
                                        subModuleData[subKey].exibeDiretamenteTexto,
                                        subModuleData[subKey].image,
                                        subModuleData[subKey].url,
                                        false,
                                        subModuleData[subKey].versao
                                    )
                                )
                            }
                        }
                    }
                    loadedModules.push(
                        new Module(
                            parseInt(key),
                            resData[key].id,
                            resData[key].title,
                            resData[key].observacao,
                            resData[key].disabled,
                            resData[key].invisivel,
                            resData[key].versao,
                            resData[key].image,
                            loadedSubModules,
                            resData[key].orderNumber,
                            resData[key].useSpecialDescription || false,
                        )
                    )
                }
            }
            dispatch({
                type: SET_MODULES,
                modules: loadedModules,
            })
            return loadedModules.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
        } catch (err) {
            throw new Error(err);
        }
    }
}

export const createModule = (title, description, invisible, disabled, version, file, orderNumber, useSpecialDescription) => {
    return async (dispatch) => {
        let lastExistingKey = 999
        const readRef = db.ref("/modulos");
        readRef.on("value", snapshot => {
            const modulos = snapshot.val();
            const keysArray = Object.keys(modulos)
            lastExistingKey = parseInt(keysArray[keysArray.length - 1])
        });
        //Genarate ID
        const postRef = db.ref(`/modulos/${lastExistingKey + 1}`)
        const pushedPostRef = postRef.push();
        const generatedId = pushedPostRef.getKey();

        let storageRef = storage.ref()
        let uploadTask = storageRef.child('module-images/' + generatedId).put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
            }, (error) => {
                throw error
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                    db.ref(`/modulos/${lastExistingKey + 1}`).set({
                        id: generatedId,
                        title,
                        observacao: description,
                        invisivel: invisible,
                        disabled,
                        versao: version,
                        image: url,
                        orderNumber: orderNumber,
                        useSpecialDescription
                    })
                    dispatch({
                        type: CREATE_MODULE,
                        key: lastExistingKey,
                        mid: generatedId,
                        moduleData: {
                            title,
                            observacao: description,
                            invisivel: invisible,
                            disabled,
                            versao: version,
                            image: url,
                            submodulos: [],
                            orderNumber: orderNumber,
                            useSpecialDescription
                        }
                    })
                })
            }
        )
        return generatedId
    }
}

export const updateModule = (key, moduleId, title, description, invisible, disabled, version, newFile, orderNumber, useSpecialDescription) => {
    return async (dispatch) => {

        let storageRef = storage.ref()
        if (newFile != null) {
            let uploadTask = storageRef.child('module-images/' + moduleId).put(newFile);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                }, (error) => {
                    throw error
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                        db.ref(`/modulos`).child(key).update({
                            // id: moduleId,
                            title,
                            observacao: description,
                            invisivel: invisible,
                            disabled,
                            versao: version,
                            image: url,
                            useSpecialDescription
                        })
                        dispatch({
                            type: UPDATE_MODULE,
                            key: key,
                            mid: moduleId,
                            moduleData: {
                                title,
                                observacao: description,
                                invisivel: invisible,
                                disabled,
                                versao: version,
                                image: url,
                                orderNumber,
                                useSpecialDescription
                            }
                        })
                    })
                }
            )
        } else {
            db.ref(`/modulos`).child(key).update({
                title,
                observacao: description,
                invisivel: invisible,
                disabled,
                versao: version,
                useSpecialDescription
            })
            dispatch({
                type: UPDATE_MODULE,
                key: key,
                mid: moduleId,
                moduleData: {
                    title,
                    observacao: description,
                    invisivel: invisible,
                    disabled,
                    versao: version,
                    useSpecialDescription
                }
            })
        }
        return moduleId
    }
}

export const deleteModule = (key, url) => {
    return async (dispatch) => {
        const deleteRef = db.ref(`/modulos/${key}`);
        deleteRef.remove().then(res => {
            dispatch({
                type: DELETE_MODULE,
                key: key
            });
            // DELETE IMAGE
            let imageRef = storage.refFromURL(url);
            imageRef.delete().then(async () => {
                console.log("Module deleted: ", res)
            })
        }).catch((error) => {
            throw new Error(error)
        });
    }
}

export const createSubModule = (moduleKey, title, description, invisible, disabled, version, file, exibeDiretamenteTexto) => {
    return async (dispatch) => {
        let lastExistingKey = -1
        const readRef = db.ref(`modulos/${moduleKey}/submodulos`);
        readRef.on("value", snapshot => {
            const submodulos = snapshot.val();
            if (submodulos) {
                const keysArray = Object.keys(submodulos)
                lastExistingKey = parseInt(keysArray[keysArray.length - 1])
                console.log("Last existing key is ", lastExistingKey)
            }
        });

        //Genarate ID
        const postRef = db.ref(`/modulos/${moduleKey}/submodulos/${lastExistingKey + 1}`)
        const pushedPostRef = postRef.push();
        const generatedId = pushedPostRef.getKey();

        let idTextoASerExibido = ''
        if (exibeDiretamenteTexto) {
            idTextoASerExibido = '-' + generatedId
        }

        let storageRef = storage.ref()
        let uploadTask = storageRef.child('submodule-images/' + generatedId).put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
            }, (error) => {
                throw error
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                    db.ref(`/modulos/${moduleKey}/submodulos/${lastExistingKey + 1}`).set({
                        title,
                        observacao: description,
                        invisivel: invisible,
                        disabled,
                        exibeDiretamenteTexto,
                        versao: version,
                        image: url,
                        idTextoASerExibido,
                        listaTextosId: generatedId,
                        url: "/nativo/listar"
                    })
                    dispatch({
                        type: CREATE_SUBMODULE,
                        moduleKey: moduleKey,
                        // subKey: lastExistingKey + 1,
                        subKey: lastExistingKey,
                        sid: generatedId,
                        subModuleData: {
                            title,
                            observacao: description,
                            invisivel: invisible,
                            disabled,
                            exibeDiretamenteTexto,
                            versao: version,
                            image: url,
                            idTextoASerExibido,
                            listaTextosId: generatedId,
                            url: "/nativo/listar"
                        }
                    })
                })
            }
        )
        return generatedId
    }
}

export const updateSubModule = (subKey, moduleKey, title, description, invisible, disabled, version, newFile, imageName, oldImageUrl) => {
    console.log("SUBKEY: ", subKey)
    return async (dispatch) => {

        const updateInformations = (imgUrl) => {
            db.ref(`/modulos/${moduleKey}/submodulos`).child(subKey).update({
                title,
                observacao: description,
                invisivel: invisible,
                disabled,
                versao: version,
                image: imgUrl
            })
            dispatch({
                type: UPDATE_SUBMODULE,
                moduleKey: moduleKey,
                subKey: subKey,
                subModuleData: {
                    title,
                    observacao: description,
                    invisivel: invisible,
                    disabled,
                    versao: version,
                    image: imgUrl
                }
            })
            return subKey
        }

        let newImageUrl = null

        let storageRef = storage.ref()
        console.log('NF: ', newFile)
        if (newFile != null) {
            let uploadTask = storageRef.child('submodule-images/' + imageName).put(newFile);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                }, (error) => {
                    console.log('error with uplading image: ', error)
                    throw new Error(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(async (imageUrl) => {
                        newImageUrl = imageUrl
                        console.log('New Image Url: ', newImageUrl)
                        updateInformations(newImageUrl)
                    })
                }
            )
        } else {
            updateInformations(oldImageUrl)
        }
    }
}

export const deleteSubModule = (moduleKey, subKey, imageUrl, isLastSub) => {
    return async (dispatch) => {
        const deleteRef = db.ref(`/modulos/${moduleKey}/submodulos/${subKey}`);
        deleteRef.remove().then(async (res) => {
            dispatch({
                type: DELETE_SUBMODULE,
                moduleKey: moduleKey,
                subKey: subKey
            });
            // DELETE IMAGE
            let imageRef = storage.refFromURL(imageUrl);
            imageRef.delete().then(async () => {
                console.log("Sub-Module deleted: ", res)
            })

            // REORDER TEXTS
            const response = await fetch(`${environment.databaseURL}/modulos/${moduleKey}/submodulos.json`);
            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const resData = await response.json();
            if (!resData) {
                return
            }
            for (const k in resData) {
                if (k > subKey) {
                    db.ref(`/modulos/${moduleKey}/submodulos`).child(k - 1).update({
                        listaTextosId: resData[k].listaTextosId,
                        idTextoASerExibido: resData[k].idTextoASerExibido,
                        title: resData[k].title,
                        observacao: resData[k].observacao,
                        disabled: resData[k].disabled,
                        invisivel: resData[k].invisivel,
                        exibeDiretamenteTexto: resData[k].exibeDiretamenteTexto,
                        image: resData[k].image,
                        url: resData[k].url,
                    })
                }
            }
            // DELETE THE LAST ITEM
            if (!isLastSub) {
                const deleteLastRef = db.ref(`/modulos/${moduleKey}/submodulos/${resData.length - 1}`);
                deleteLastRef.remove().then(async (res) => {
                    console.log("Last Submodule deleted: ", res)
                })
            }
        }).catch((error) => {
            throw new Error(error)
        });
    }
}

export const changeSubmodulesOrder = (moduleKey, dragKey, dragItem, dropKey, dropItem) => {
    return async (dispatch) => {
        if(dragKey === dropKey) {
            return
        }

        console.log('ids: ', dragKey, dropKey)

        db.ref(`/modulos/${moduleKey}/submodulos`).child(dragKey).update({
            disabled: dropItem.disabled,
            exibeDiretamenteTexto: dropItem.exibeDiretamenteTexto,
            idTextoASerExibido: dropItem.idTextoASerExibido,
            image: dropItem.image,
            invisivel: dropItem.invisivel,
            listaTextosId: dropItem.listaTextosId,
            observacao: dropItem.observacao,
            title: dropItem.title,
            url: dropItem.url,
            versao: dragItem.versao ? dragItem.versao + 1 : 1,
        })
        db.ref(`/modulos/${moduleKey}/submodulos`).child(dropKey).update({
            disabled: dragItem.disabled,
            exibeDiretamenteTexto: dragItem.exibeDiretamenteTexto,
            idTextoASerExibido: dragItem.idTextoASerExibido,
            image: dragItem.image,
            invisivel: dragItem.invisivel,
            listaTextosId: dragItem.listaTextosId,
            observacao: dragItem.observacao,
            title: dragItem.title,
            url: dragItem.url,
            versao: dropItem.versao ? dropItem.versao + 1 : 1,
        })

        dispatch({
            type: CHANGE_ORDER_SUBMODULES,
            moduleKey: moduleKey,
            dragKey: dragKey,
            dropKey: dropKey,
            dragItem: dragItem,
            dropItem: dropItem
        })
    }
}

// export const changeModulesOrder = (dragKey, dragOrderNumber, newDragVersao, dropKey, dropOrderNumber, newDropVersao) => {
    export const changeModulesOrder = (dragItem, dropItem) => {
    return async (dispatch) => {


        db.ref(`/modulos`).child(dragItem.key).update({
            orderNumber: dropItem.orderNumber,
            versao: dragItem.versao + 1
        })
        db.ref(`/modulos`).child(dropItem.key).update({
            orderNumber: dragItem.orderNumber,
            versao: dropItem.versao + 1
        })

        dispatch({
            type: CHANGE_ORDER_MODULES,
            dragItem,
            dropItem
        })
    }
}

export const getModulesVersion = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/version.json`);
            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const obj = await response.json();

            dispatch({
                type: GET_MODULES_VERSION,
                version: obj.modulesVersion,
            });
            return obj.modulesVersion
        } catch (err) {
            throw new Error(err);
        }
    }
}

export const updateModulesVersion = (newVersion) => {
    return async (dispatch) => {
        db.ref(`/`).child('version').update({
            modulesVersion: newVersion
        })
        dispatch({
            type: UPDATE_MODULES_VERSION,
            version: newVersion,
        });
    }
}
