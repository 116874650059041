import styled, { keyframes } from "styled-components";
import Colors from "../../../constants/colors";

export const animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const LoaderContainer = styled.div`
    display: flex;
    width: 100px;
    height: 75px;
    justify-content: center;
    align-items: center;
`

export const Loader = styled.div`
  width: 6px;
  height: 6px;
  border: 6px solid ${Colors.primary};
  border-top: 6px solid ${Colors.cream}; 
  border-radius: 50%;
  animation-name: ${animation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`

export const Box = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    margin-bottom: 32px;
    padding: 8px;
    background-color: white;
`

export const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
`

export const BannerImage = styled.img`
    width: 100px;
    height: 75px;
    display: inline-block;
`

export const SwitchButtons = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 108px);
    height: 75px;
    justify-content: space-between;
    padding: 0 4px;
    
`

export const ButtonRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

export const Label = styled.label`
    font-size: 14px;
    color: ${Colors.sgray};
`

export const AddressRow = styled.div`
    width: calc(100% - 8px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const AddressText = styled.label`
    font-size: 14px;
    color: ${Colors.sgray};
    font-weight: bold;
    margin-top: 4px;
`

export const UrlButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

