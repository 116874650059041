import React, { useState } from 'react'
import classes from './Layout.module.css'
import Sidebar from '../Sidebar/Sidebar'
import { Route } from 'react-router-dom'
import "react-activity/dist/Dots.css";
import { useHistory } from 'react-router-dom'

import Users from '../../views/users/Users'
import Advertisements from '../../views/advertisements/Advertisements'
import Notifications from '../../views/notifications/Notifications'
import Modules from '../../views/modules/Modules'
import Texts from '../../views/texts/Texts'
import Messages from '../../views/messages/Messages'
import Faqs from '../../views/faqs/Faqs'
import About from '../../views/about/About'
import EditModule from '../../views/EditModule/EditModule'
import EditSubModule from '../../views/EditSubModule/EditSubModule'
import EditText from '../../views/EditText/EditText'
import Legal from '../../views/Legal/Legal'
import EditLegal from '../../views/EditLegal/EditLegal'
import TermsAndConditions from '../../views/TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from '../../views/PrivacyPolicy/PrivacyPolicy'
import EditTextEditor from '../../views/EditTextEditor/EditTextEditor'
import PreviewTextContent from '../../views/PreviewTextContent/PreviewTextContent'
import TerraVivaAdmin from '../../views/TerraVivaAdmin/TerraVivaAdmin'
import Alert from '../UI/Alert/Alert'
import { auth } from '../../env/environment'
import TerraVivaBanners from '../../views/TerraVivaBanners';

// const Modules = React.lazy(() => import('../../views/modules/Modules'))

const Layout = props => {
    const [alertVisible, setAlertVisible] = useState(false)
    const currentPath = window.location.pathname
    console.log(currentPath)

    const history = useHistory()

    const sideBarVisible = () => {
        switch (currentPath) {
            case '/terms-and-conditions':
            case '/privacy-policy':
            case '/modules/edit-module':
            case '/modules/edit-submodule':
            case '/modules/edit-text':
            case '/EditTextEditor':
            case '/PreviewTextContent':
                return false
            default:
                return true
        }
    }

    const onClickSair = async () => {
        auth.signOut()
        setAlertVisible(false)
        history.push('/')
    }

    return (
        <div className={classes.Screen}>
            {/* <Suspense fallback={
                <div className="centered">
                    <Dots />
                </div>
            }> */}
                {sideBarVisible() && <Sidebar onClickSair={() => { setAlertVisible(true) }}/>}
                <main className={classes.Content} id={sideBarVisible() ? null : classes.noSidebar}>

                    <Route path="/" exact component={Modules}/>
                    <Route path="/users" exact component={Users} />
                    <Route path="/advertisements" exact component={Advertisements}/>
                    <Route path="/notifications" exact component={Notifications}/>
                    <Route path="/texts" exact component={Texts}/>
                    <Route path="/messages" exact component={Messages}/>
                    <Route path="/faqs" exact component={Faqs}/>
                    <Route path="/about" exact component={About}/>
                    <Route path="/terraviva-admin" exact component={TerraVivaAdmin}/>
                    <Route path="/terraviva-banners" exact component={TerraVivaBanners}/>

                    <Route path="/modules/edit-module" exact component={EditModule}/>
                    <Route path="/modules/edit-submodule" exact component={EditSubModule}/>
                    <Route path="/modules/edit-text" exact component={EditText}/>
                    <Route path="/legal" exact component={Legal}/>
                    <Route path="/legal/edit-legal" exact component={EditLegal} />
                    <Route path="/terms-and-conditions" exact component={TermsAndConditions}/>
                    <Route path="/privacy-policy" exact component={PrivacyPolicy}/>

                    <Route path="/EditTextEditor" exact component={EditTextEditor}/>
                    <Route path="/text-content" exact component={PreviewTextContent}/>
                </main>
                <Alert
                    style={{ marginTop: 20 }}
                    visible={alertVisible}
                    message='Você quer sair do Xlei?'
                    onClickButton={onClickSair}
                    onClickCancel={() => { setAlertVisible(false) }}
                    buttonName="Sair"
                />
            {/* </Suspense> */}
        </div>
    )
}

export default Layout
