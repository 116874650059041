import './App.css';
import { BrowserRouter } from 'react-router-dom'
import Navigator from './containers/Navigator/Navigator';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'
import modulesReducer from './store/reducer/modulesReducer';
import faqsReducer from './store/reducer/faqsReducer';
import supportsReducer from './store/reducer/supportsReducer';
import usersReducer from './store/reducer/usersReducer';
import notificationsReducer from './store/reducer/notificationsReducer';
import textPdfReducer from './store/reducer/textPdfReducer';
import aboutReducer from './store/reducer/aboutReducer';
import authReducer from './store/reducer/authReducer';
import advertisementsReducer from './store/reducer/advertisementsReducer';

const rootReducer = combineReducers({
  modules: modulesReducer,
  faqs: faqsReducer,
  supports: supportsReducer,
  users: usersReducer,
  notifications: notificationsReducer,
  textPdfs: textPdfReducer,
  about: aboutReducer,
  auth: authReducer,
  advertisements: advertisementsReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

function App() {

  return (
    <Provider store={store} >
      <BrowserRouter>
        <Navigator/>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
