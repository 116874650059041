import React from 'react'
import classes from './SubModuleCard.module.css'

const ModuleCard = props => {

    const item = props.item

    return (
        <div
            className={props.isSelected ? `${classes.card} ${classes.active}` : classes.card}
            draggable={true}
            // id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
            id={props.boxNumber}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
        >
            <img className={classes.image} src={item.image} alt={item.title} />
            <p className={classes.title}><b>{item.title}</b></p>
        </div>
    )
}

export default ModuleCard