class User {
  constructor (id, email, nome, tipo, createdAt, accessos, userPushToken) {
    this.id = id
    this.email = email
    this.nome = nome
    this.tipo = tipo
    this.crecreatedAt = createdAt
    this.accessos = accessos
    this.userPushToken = userPushToken
  }
}

export default User