import React, { useState, useCallback, useEffect, Fragment } from 'react'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import FindText from '../../components/FindText/FindText'
import classes from './PreviewTextContent.module.css'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import environment from '../../env/environment'
import { useHistory, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'

const HTML_BEGIN =
  '<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>'
const HTML_END = '</body></html>'

const Legal = props => {
  const [loading, setLoading] = useState(false)
  const [html, setHtml] = useState(null)

  const [body, setBody] = useState(null)
  const [defaultContent, setDefaultContent] = useState(null)
  const [content, setContent] = useState(null)
  const [isDeskTop, setIsDeskTop] = useState(false)
  const [numberOfFounds, setNumberOfFounds] = useState(0)

  const history = useHistory()
  const location = useLocation()

  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  const id = query.get('id')
  const width = query.get('width')
  const title = location?.state?.title

  const loadData = useCallback(async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${environment.databaseURL}/contents/${id}/content.json`
      )
      if (!response.ok) {
        throw new Error('Something went wrong with fetching')
      }
      const result = await response.json()
      setHtml(HTML_BEGIN + result + HTML_END)
      setBody(result)
      setDefaultContent(HTML_BEGIN + result + HTML_END)
      setContent(HTML_BEGIN + result + HTML_END)

      setLoading(false)
    } catch (err) {
      console.log('Error: ', err.message)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onPressClean = () => {
    setContent(defaultContent)
    setNumberOfFounds(0)
  }

  const onWriting = values => {
    console.log(values)
    if (values?.length < 1) {
      return
    }
    let newContent = defaultContent
    let quantity = 0
    for (const key in values) {
      if (
        values[key].length < 3
        // || ignoredSearchedStrings.find(str => str === values[key])
      ) {
        setContent(defaultContent)
      } else {
        let array = newContent?.split(values[key])
        quantity = quantity + array.length - 1
        newContent = array?.join(`<mark>${values[key]}</mark>`)
      }
    }
    setNumberOfFounds(quantity / 2)
    setContent(newContent || defaultContent)
  }

  if (html) {
    return (
      <div className={classes.Screen}>
        {/* <div className={classes.header} style={{ width: width || null }}>
          {title && (
            <ButtonText
              title={'Editar ' + title}
              onClick={() =>
                history.push('/EditTextEditor', { textId: id, title })
              }
              type='warning'
            />
          )}
          <FindText
            quantity={numberOfFounds}
            onPressClean={onPressClean}
            onWriting={onWriting}
          />
        </div> */}
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    )
  }

  return (
    <div className={classes.Screen}>
      <div className={classes.EditorContainer}></div>
    </div>
  )
}

export default Legal
