import React from 'react'
import '../../App.css'
import classes from './Sidebar.module.css'
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { NavLink, useLocation } from 'react-router-dom';
import LogoHorizontal from '../LogoHorizontal/LogoHorizontal'
import { useSelector } from 'react-redux';
import { SidebarData } from '../../data/SIdebarData';

const Sidebar = props => {

    const admin = useSelector(state => state.auth.user)

    const location = useLocation()

    console.log(location.pathname)

    return (
        <div className={classes.Sidebar} >
            <LogoHorizontal />
            <div className={classes.userContainer}>
                <p className={classes.userName} >{admin.nome}</p>
                <p className={classes.userEmail} >{admin.email}</p>
            </div>
            <ul className={classes.SidebarList}>
                {SidebarData.map((val, key) => (
                    <li key={key}>
                        <NavLink activeClassName={key === 0 ? classes.activeModules : classes.active} className={classes.row} to={val.link} >
                            <div id={classes.icon}>{val.icon}</div>
                            <div id={classes.title}>{val.title}</div>
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div className={classes.exitRow} onClick={props.onClickSair}>
                <div id={classes.icon}><ExitToAppRoundedIcon /></div>
                <div id={classes.title}>Sair</div>
            </div>
        </div>
    )
}

export default Sidebar