import Notification from "../../models/Notification";
import environment from '../../env/environment'
import { auth } from "../../env/environment";
import sendNotification from "../../utils/sendNotification";

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const fetchNotifications = () => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        try {
            const response = await fetch(`${environment.databaseURL}/notifications.json?auth=${token}`);

            if (!response.ok) {
                throw new Error("error with fetching notifications")
            }
            const resData = await response.json();
            const loadedNotifications = [];
            for (const key in resData) {
                loadedNotifications.unshift(
                    new Notification(
                        key,
                        resData[key].title,
                        resData[key].message,
                        resData[key].senderId,
                        resData[key].date,
                        resData[key].relatedScreen,
                        resData[key].relatedData
                    )
                )
            }
            dispatch({
                type: SET_NOTIFICATIONS,
                notifications: loadedNotifications,
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const createNotification = (title, message, relatedScreen, relatedData) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        const userId = auth.currentUser.uid
        const creationDate = new Date()

        const response = await fetch(`${environment.databaseURL}/notifications.json?auth=${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                title,
                message,
                senderId: userId,
                date: creationDate,
                relatedScreen,
                relatedData
            })
        })
        if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.error)
        }
        const resData = await response.json();

        /// Fetch Users and Send Notifications
        const usersResponse = await fetch(`${environment.databaseURL}/usuarios.json?auth=${token}`);

        if (!usersResponse.ok) {
            const errorData = await usersResponse.json()
            const errorId = errorData.error
            throw new Error(errorId)
        }
        let numberOfReceivers = 0
        const userResData = await usersResponse.json();
        for (const key in userResData) {
            if (userResData[key]?.userPushToken) {
                console.log('push token', userResData[key]?.userPushToken)
                await sendNotification(userResData[key]?.userPushToken, title, message, relatedScreen, relatedData)
                numberOfReceivers += 1
            }
        }
        //////////////////////////////////

        dispatch({
            type: CREATE_NOTIFICATION,
            nid: resData.name,
            notificationData: {
                title,
                message,
                senderId: userId,
                date: creationDate,
                relatedScreen,
                relatedData
            }
        })
        return numberOfReceivers
    }
};

export const deleteNotification = (id) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        const response = await fetch(
            `${environment.databaseURL}/notifications/${id}.json?auth=${token}`,
            {
                method: 'DELETE'
            });
        if(!response.ok){
            throw new Error('Something went wrong with deleting the notification!')
        }
        dispatch({
            type: DELETE_NOTIFICATION, 
            nid: id
        });
    }; 
};