import React, { useEffect, useState } from 'react'
import classes from './TerraVivaAdmin.module.css'
import firebase from 'firebase/'
import Module from '../../models/Module'
import SubModule from '../../models/SubModule'
import TextPdf from '../../models/TextPdf'
import PublicItem from '../../components/items/PublicItem'
import { db } from '../../env/environment'

const TerraVivaAdmin = () => {
  const [publicModules, setPublicModules] = useState([])
  const [publicSubModules, setPublicSubModules] = useState([])
  const [publicTexts, setPublicTexts] = useState([])

  const [modules, setModules] = useState([])
  const [subModules, setSubModules] = useState([])
  const [texts, setTexts] = useState([])

  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedSubModule, setSelectedSubModule] = useState(null)

  useEffect(() => {
    fetchPublics()
    fetchModules()
  }, [])

  useEffect(() => {
    if (selectedModule) {
      fetchSubModules()
    }
  }, [selectedModule])

  useEffect(() => {
    if (selectedSubModule) {
      fetchTexts()
    }
  }, [selectedSubModule])

  const fetchPublics = () => {
    const publicRef = firebase.database().ref('publicForTerraViva')
    const modRef = publicRef.child('modules')
    const subRef = publicRef.child('subModules')
    const texRef = publicRef.child('texts')

    modRef.on('value', snapshot => {
      let list = []
      snapshot.forEach(data => {
        const dataVal = data.val()
        list.push(dataVal)
      })
      setPublicModules(list)
    })

    subRef.on('value', snapshot => {
      let list = []
      snapshot.forEach(data => {
        const dataVal = data.val()
        list.push(dataVal)
      })
      setPublicSubModules(list)
    })

    texRef.on('value', snapshot => {
      let list = []
      snapshot.forEach(data => {
        const dataVal = data.val()
        list.push(dataVal)
      })
      setPublicTexts(list)
    })
  }

  const fetchModules = () => {
    const modulesRef = firebase.database().ref('modulos')
    modulesRef.on('value', snapshot => {
      let modList = []
      snapshot.forEach(data => {
        const dataVal = data.val()
        let mod = new Module(
          parseInt(data.key),
          dataVal.id,
          dataVal.title,
          dataVal.observacao,
          dataVal.disabled,
          dataVal.invisivel,
          dataVal.versao,
          dataVal.image,
          null,
          dataVal.orderNumber,
          dataVal.useSpecialDescription || false
        )
        modList.push(mod)
      })
      setModules(modList)
      setSelectedModule(modList[0])
    })
  }

  const fetchSubModules = () => {
    const modulesRef = firebase.database().ref('modulos')
    const tempRef = modulesRef.child(selectedModule.key)
    const subModulesRef = tempRef.child('submodulos')
    subModulesRef.on('value', snapshot => {
      let subList = []
      snapshot.forEach(subData => {
        const subVal = subData.val()
        let sub = new SubModule(
          parseInt(subData.key),
          subVal.listaTextosId,
          subVal.idTextoASerExibido,
          subVal.title,
          subVal.observacao,
          subVal.disabled,
          subVal.invisivel,
          subVal.exibeDiretamenteTexto,
          subVal.image,
          subVal.url,
          false,
          subVal.versao
        )
        subList.push(sub)
      })
      setSubModules(subList)
      setSelectedSubModule(subList[0])
    })
  }

  const fetchTexts = () => {
    const textsRef = firebase
      .database()
      .ref('textos')
      .child(selectedSubModule.listaTextosId)
    textsRef.on('value', snapshot => {
      let textList = []
      snapshot.forEach(data => {
        const dataVal = data.val()
        let mod = new TextPdf(
          dataVal.id,
          selectedSubModule.listaTextosId,
          dataVal.caminhoFirebaseStorage,
          dataVal.dataPublicacao,
          dataVal.dou,
          null,
          dataVal.observacao,
          dataVal.origem,
          dataVal.pagina,
          dataVal.sessao,
          dataVal.subModuloId,
          dataVal.textoPago,
          dataVal.title,
          dataVal.versao,
          data.key,
          dataVal.image,
          dataVal.type
        )
        textList.push(mod)
      })
      setTexts(textList)
    })
  }

  const moduleCheckBoxHandler = id => {
    let isVisible = publicModules?.find(i => i === id)
    let newMods = []
    if (isVisible) {
      newMods = publicModules?.filter(m => m !== id)
    } else {
      newMods = publicModules
      newMods.push(id)
    }

    setPublicModules(newMods)
    db.ref(`/publicForTerraViva`).update({
      modules: newMods
    })
  }

  const subModuleCheckBoxHandler = id => {
    let isVisible = publicSubModules.find(i => i === id)
    let newSubMods = []
    if (isVisible) {
      newSubMods = publicSubModules.filter(m => m !== id)
    } else {
      newSubMods = publicSubModules
      newSubMods.push(id)
    }
    setPublicSubModules(newSubMods)
    db.ref(`/publicForTerraViva`).update({
      subModules: newSubMods
    })
  }

  const textCheckBoxHandler = id => {
    let isVisible = publicTexts.find(i => i === id)
    let newTexts = []
    if (isVisible) {
      newTexts = publicTexts.filter(m => m !== id)
    } else {
      newTexts = publicTexts
      newTexts.push(id)
    }
    setPublicTexts(newTexts)
    db.ref(`/publicForTerraViva`).update({
      texts: newTexts
    })
  }

  return (
    <div className={classes.screen}>
      <div className={classes.column}>
        <h3>Modules</h3>
        {modules.map((item, index) => (
          <PublicItem
            key={item.id}
            item={item}
            onClick={() => {
              setSelectedModule(item)
            }}
            isSelected={selectedModule === item}
            onChange={moduleCheckBoxHandler.bind(this, item.id)}
            checked={publicModules?.find(i => i === item.id)}
          />
        ))}
      </div>

      <div className={classes.column}>
        <h3>Sub-Modules</h3>
        {subModules.map((item, index) => (
          <PublicItem
            key={item.listaTextosId}
            item={item}
            onClick={() => {
              setSelectedSubModule(item)
            }}
            isSelected={selectedSubModule === item}
            onChange={subModuleCheckBoxHandler.bind(this, item.listaTextosId)}
            checked={publicSubModules.find(i => i === item.listaTextosId)}
          />
        ))}
      </div>

      <div className={classes.column}>
        <h3>Texts</h3>
        {texts.map((item, index) => (
          <PublicItem
            key={index.toString()}
            item={item}
            onClick={() => {}}
            isSelected={false}
            onChange={textCheckBoxHandler.bind(this, item.id)}
            checked={publicTexts.find(i => i === item.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default TerraVivaAdmin
