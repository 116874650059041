import React, { useEffect, useCallback, useState } from 'react'
import classes from './Messages.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as supportActions from '../../store/actions/supportActions'
import * as userActions from '../../store/actions/userActions'
import MessageItem from '../../components/items/MessageItem/MessageItem'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import Colors from '../../constants/colors'
import moment from 'moment'
import Input from '../../components/UI/Input/Input'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import SearchBar from '../../components/UI/SearchBar/SearchBar';

const Messages = props => {
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [answerText, setAnswerText] = useState('')
    const [searchedItems, setSearchedItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const dispatch = useDispatch()

    const isSupportsLoaded = useSelector(state => state.supports.loaded)
    const isusersLoaded = useSelector(state => state.users.loaded)

    const supports = useSelector(state => state.supports.availableSupports)
    const users = useSelector(state => state.users.availableUsers)
    const client = !selectedMessage ? null : users.find(u => u.id === selectedMessage.clientId)
    const admin = !selectedMessage ? null : users.find(u => u.id === selectedMessage.adminId)

    useEffect(() => {
        if (supports?.length > 0) {
            setSearchedItems(supports)
        }
    }, [supports])

    const loadData = useCallback(async () => {
        setLoading(true)
        try {
            if(!isSupportsLoaded) await dispatch(supportActions.fetchSupports());
            if(!isusersLoaded) await dispatch(userActions.fetchUsers());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch, isSupportsLoaded, isusersLoaded]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    const answerMessageHandler = async () => {
        setErrorMessage(null)
        if (answerText.length < 3) {
            setErrorMessage('A resposta não pode ter menos de 3 caracteres')
            return
        }
        setButtonLoading(true)
        try {
            await dispatch(supportActions.answerSupport(selectedMessage.id, answerText, selectedMessage.pushToken))
            let message = { ...selectedMessage }
            message.answer = answerText
            setAnswerText('')
            loadData()
            setSelectedMessage(message)
            setButtonLoading(false)
        } catch (err) {
            console.log("Error: ", err);
            setButtonLoading(false)
        }
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.messagesContainer}>
                <SearchBar
                    style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                    data={supports}
                    setFixedData={(val) => { setSearchedItems(val) }}
                    searchedValue1='question'
                    searchedValue2='relatedText.title'
                />
                {searchedItems && searchedItems.map(item => (
                    <MessageItem
                        key={item.id}
                        item={item} onClick={() => { setSelectedMessage(item); }}
                        client={users.find(u => u.id === item.clientId)}
                        isSelected={selectedMessage === item}
                    />
                ))}
            </div>
            {selectedMessage &&
                <div className={classes.chatContainer}>
                    <div>
                        <div className={classes.titleContainer}>
                            <DashboardRoundedIcon style={{ color: Colors.primary }} />
                            <h4>{selectedMessage.relatedText.title}</h4>
                        </div>

                        <div className={classes.questionContainer}>
                            <div className={classes.boxTitle}>
                                <div className={classes.nameContainer}>
                                    <div className={classes.imageCircle} >{!client?.nome?.includes(' ') ? client?.nome?.split(" ")[0].charAt(0) : client?.nome?.split(" ")[0].charAt(0) + client?.nome?.split(" ")[1].charAt(0)}</div>
                                    <h5 className={classes.name} >{client?.nome}</h5>
                                </div>
                                <p classes={classes.time}>{moment(selectedMessage.createdAt).format('hh:mm DD/MM/YY')}</p>
                            </div>
                            <p className={classes.questionText} >{selectedMessage.question}</p>
                        </div>

                        {selectedMessage.answer &&
                            <div className={classes.questionContainer} id={classes.answerContainer}>
                                <div className={classes.boxTitle}>
                                    <div className={classes.nameContainer}>
                                        <div className={classes.imageCircle} id={classes.adminCircle} >{!admin?.nome?.includes(' ') ? admin?.nome?.split(" ")[0].charAt(0) : admin?.nome?.split(" ")[0].charAt(0) + admin?.nome?.split(" ")[1].charAt(0)}</div>
                                        <h5 className={classes.name} >{admin?.nome}</h5>
                                    </div>
                                    <p classes={classes.time}>{moment(selectedMessage.updatedAt).format('hh:mm DD/MM/YY')}</p>
                                </div>
                                <p className={classes.questionText} >{selectedMessage.answer}</p>
                            </div>
                        }
                    </div>

                    {!selectedMessage.answer &&
                        <div className={classes.inputContainer} >
                            <Input
                                label='Resposta'
                                value={answerText}
                                onChange={(e) => setAnswerText(e.target.value)}
                                elementType="textarea"
                                shouldValidate
                                invalid={answerText.length < 3}
                                touched={errorMessage}
                            />
                            <ButtonBox
                                title="Enviar"
                                loading={buttonLoading}
                                onClick={answerMessageHandler}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Messages
