import React, { useLayoutEffect, useRef, useState } from "react";
import { Container, Input, Label } from "./styles";
import { Props } from "./types";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Colors from "../../../constants/colors";

const FileSelector: React.FC<Props> = ({ onChange, style, label }) => {
  const [width, setWidth] = useState(0)

  const containerRef = useRef<any>(null)

  useLayoutEffect(() => {
      const offsetWidth = containerRef?.current?.offsetWidth
      if (offsetWidth) {
          setWidth(containerRef.current.offsetWidth);
      }
  }, [width, containerRef]);

  return (
    <Container ref={containerRef} style={style} >
      <CloudUploadIcon style={{ fontSize: 16, color: Colors.sgray }}/>
      <Label htmlFor="upload-photo">{label}</Label>
      <Input width={width} type="file" id="file" accept="image/*" onChange={onChange} />
    </Container>
  );
};

export default FileSelector;
