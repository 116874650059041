import React from 'react'
import classes from './ButtonBoxSmall.module.css'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

const Button = props => {

    let buttonType = 'default'

    switch (props.type) {
        case 'success':
            buttonType = classes.success
            break
        case 'warning':
            buttonType = classes.warning
            break
        case 'danger':
            buttonType = classes.danger
            break
        default:
            buttonType = 'default'
            break
    }

    if (props.loading) {
        return (
            <div
                // className={`${classes.container} ${props.className}`}
                id={buttonType}
                style={{
                    ...props.style,
                }}
            ><Dots />
            </div>
        )
    }

    if (props.hide) {
        return <></>
    }

    return (
        <div
            className={`${classes.container} ${props.className}`}
            id={buttonType}
            style={{
                ...props.style,
            }}
            onClick={props.onClick}
        >
            <p className={classes.title} >{props.title}</p>
        </div>
    )
}

export default Button