import React, { useEffect, useState, useCallback } from 'react'
import classes from './MessageItem.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as moduleActions from '../../../store/actions/moduleActions'
import moment from 'moment'
import HourglassFullRoundedIcon from '@material-ui/icons/HourglassFullRounded';
import Colors from '../../../constants/colors';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';

const MessageItem = props => {
    const [submodulo, setSubmodulo] = useState(null)
    const [text, setText] = useState(null)

    const item = props.item

    const isModulosLoaded = useSelector(state => state.modules.loaded)
    const modulos = useSelector(state => state.modules.availableModules)

    const dispatch = useDispatch()

    const loadModules = useCallback(async () => {
        try {
            if(!isModulosLoaded) await dispatch(moduleActions.fetchModules());
        } catch (err) {
            console.log('Error: ', err.message);
        }
    }, [dispatch, isModulosLoaded]);

    useEffect(() => {
        loadModules()
    }, [loadModules]);

    useEffect(() => {
        if (modulos && modulos.length > 0) {
            for (const key in modulos) {
                const sub1 = modulos[key].submodulos.find(i => i.listaTextosId === props.item.relatedText.subModuloId)
                if (sub1) {
                    setSubmodulo(sub1)
                    return
                }
            }
            for (const key in modulos) {
                const sub2 = modulos[key].submodulos.find(i => i.id === props.item.relatedText.id)
                if (sub2) {
                    setText(sub2)
                    return
                }
            }
        }
    }, [modulos, submodulo, text, props])

    const imageUrl = text ? text.image : submodulo ? submodulo.image : null

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
        >
            <img className={classes.image} src={imageUrl} alt={''}
            />
            <div className={classes.titleContainer}>
                {/* <p id={classes.oneLine}><b>{item.relatedText.title}</b></p> */}
                <p id={classes.oneLine}><b>{props.client?.nome}</b></p>
                <p id={classes.oneLine}>{item.question}</p>
            </div>
            <div className={classes.timeContainer}>
                <p className={classes.time}>{moment(item.updatedAt).format('DD/MM')}</p>
                {!item.answer && <HourglassFullRoundedIcon style={{ color: Colors.warning, width: 20 }} />}
                {item.answer && <DoneAllRoundedIcon style={{ color: Colors.success, width: 20 }} />}
            </div>
        </div>
    )
}

export default MessageItem