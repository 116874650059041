import React, { useState, useCallback, useEffect } from 'react'
import ButtonBoxSmall from '../../components/UI/ButtonBoxSmall/ButtonBoxSmall'
import classes from './Legal.module.css'
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import environment from '../../env/environment'
import { useHistory } from 'react-router-dom'

const Legal = props => {
    const [loading, setLoading] = useState(false)
    const [policyEditorState, setPolicyEditorState] = useState(EditorState.createEmpty())
    const [termsEditorState, setTermsEditorState] = useState(EditorState.createEmpty())

    const history = useHistory()

    const loadData = useCallback(async () => {
        setLoading(true)
        try {
            // Privacy Policy
            const policyResponse = await fetch(`${environment.databaseURL}/legal/privacy_policy/content.json`);
            if (!policyResponse.ok) {
                throw new Error('Something went wrong with fetching about')
            }
            const loadedPolicy = await policyResponse.json();
            const rawPolicyContent = convertFromRaw(JSON.parse(loadedPolicy))
            setPolicyEditorState(() => EditorState.createWithContent(rawPolicyContent))

            // Terms of Use
            const termsResponse = await fetch(`${environment.databaseURL}/legal/terms_of_use/content.json`);
            if (!termsResponse.ok) {
                throw new Error('Something went wrong with fetching about')
            }
            const loadedTerms = await termsResponse.json();
            const rawTermsContent = convertFromRaw(JSON.parse(loadedTerms))
            setTermsEditorState(() => EditorState.createWithContent(rawTermsContent))

            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        loadData()
    }, [loadData]);

    return (
        <div className={classes.Screen}>
            <div className={classes.column}>
                <div className={classes.EditorContainer}>
                    {/* <ButtonBox
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 999 }}
                        title="Editar"
                        onClick={() => { history.push('/legal/edit-legal', { document: 'privacy_policy' }) }}
                    /> */}
                    <ButtonBoxSmall
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 999 }}
                        title="Editar"
                        onClick={() => { history.push('/legal/edit-legal', { document: 'privacy_policy' }) }}
                        type="warning"
                    />
                    <Editor
                        editorState={policyEditorState}
                        readOnly={true}
                    />
                </div>
            </div>

            <div className={classes.column}>
                <div className={classes.EditorContainer}>
                    {/* <ButtonBox
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 999 }}
                        title="Editar"
                        onClick={() => { history.push('/legal/edit-legal', { document: 'terms_of_use' }) }}
                    /> */}
                    <ButtonBoxSmall
                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 999 }}
                        title="Editar"
                        onClick={() => { history.push('/legal/edit-legal', { document: 'terms_of_use' }) }}
                        type="warning"
                    />
                    <Editor
                        editorState={termsEditorState}
                        readOnly={true}
                    />
                </div>
            </div>
        </div >
    )
}

export default Legal
