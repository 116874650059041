import { SET_FAQS, DELETE_FAQ, CREATE_FAQ, UPDATE_FAQ } from '../actions/faqActions';
import Faq from "../../models/Faq";

const initialState = {
    availableFaqs: [],
    loaded: false
}

const faqsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FAQS:
            return {
                ...state,
                availableFaqs: action.faqs,
                loaded: true
            }
        case DELETE_FAQ:
            return {
                ...state,
                availableFaqs: state.availableFaqs.filter(f => f.id !== action.fid),
            };
        case CREATE_FAQ:
            const newFaq = new Faq(
                action.faqData.id,
                action.faqData.title,
                action.faqData.description
            )
            return {
                ...state,
                availableFaqs: state.availableFaqs.concat(newFaq),
            }
        case UPDATE_FAQ:
            const faqIndex = state.availableFaqs.findIndex(f => f.id === action.fid);
            const updatedFaq = new Faq(
                action.fid,
                action.faqData.title,
                action.faqData.description
            )
            const updatedFaqs = [...state.availableFaqs];
            updatedFaqs[faqIndex] = updatedFaq;

            return {
                ...state,
                availableFaqs: updatedFaqs,
            }
        default:
            return state
    }
}

export default faqsReducer