import React from 'react'
import { Container, Icon, Loader } from './styles'
import { Props } from './types'

const ButtonIcon: React.FC<Props> = ({
    name,
    size,
    onClick,
    style,
    loading
}) => {

    const iconObject = icons.find(ic => ic.name === name)

    if (loading) {
        return (
            <Container style={style} onClick={() => {}}>
                <Loader/>
            </Container>
        )
    }

    return (
        <Container style={style} onClick={onClick}>
            <Icon src={iconObject?.link} size={size} />
        </Container>
    )
}

const icons = [
    { name: 'filter', link: require('../../../assets/icons/filter.png').default },
    { name: 'search', link: require('../../../assets/icons/search.png').default },
    { name: 'close', link: require('../../../assets/icons/close.png').default },
    { name: 'delete', link: require('../../../assets/icons/delete.png').default },
    { name: 'edit', link: require('../../../assets/icons/edit.png').default },
    { name: 'on-button', link: require('../../../assets/icons/on-button.png').default },
    { name: 'off-button', link: require('../../../assets/icons/off-button.png').default },
    { name: 'file', link: require('../../../assets/icons/file.png').default },
    { name: 'pencil', link: require('../../../assets/icons/pencil.png').default },
    { name: 'info', link: require('../../../assets/icons/info.png').default },
    { name: 'trash', link: require('../../../assets/icons/trash.png').default },
    { name: 'trash-gray', link: require('../../../assets/icons/trash-gray.png').default },
    { name: 'arrow-up', link: require('../../../assets/icons/arrow-up.png').default },
    { name: 'arrow-down', link: require('../../../assets/icons/arrow-down.png').default },

]

export default ButtonIcon