import React from 'react'
import Checkbox from '../../UI/Checkbox'
import defaultImage from '../../../assets/default_image.jpg'
import { Props } from './types'
import { Container, Image, TitleContainer, TimeContainer, Title } from './styles'

const PublicItem: React.FC<Props> = ({ item, isSelected, onClick, onChange, checked }) => {

    const imgSrc = !item.image || item.image === '' ? defaultImage : item.image

    return (
        <Container active={isSelected}>
            <Image
                src={imgSrc}
                alt={''}
                onClick={onClick}
            />
            <TitleContainer onClick={onClick}>
                <Title disabled={item.disabled || item.invisivel} >{item.title}</Title>
            </TitleContainer>
            <TimeContainer>
                <Checkbox checked={checked} onChange={onChange} />
            </TimeContainer>
        </Container>
    )
}

export default PublicItem
