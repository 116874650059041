import React from "react";
import { Container, Ball, Label, Box } from "./styles";
import { Props } from "./types";

const Switch: React.FC<Props> = ({ value, onChange, style, label }) => {
  return (
    <Container style={style}>
      <Box on={value || false} onClick={onChange}>
        <Ball on={value || false} />
      </Box>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

export default Switch;
