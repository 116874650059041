import React from 'react'
import Logo from '../../assets/logo.svg'
import classes from './Logo.module.css'

const logo = props => (
    <div className={classes.Logo}>
        <img src={Logo} alt={'XLEI'} />
    </div>
)

export default logo
