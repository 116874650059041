class Advertisement {
    constructor(id, ativo, nome, observacao, enderecoDaImagem, link) {
        this.id = id
        this.ativo = ativo
        this.nome = nome
        this.observacao = observacao
        this.enderecoDaImagem = enderecoDaImagem
        this.link = link
    }
}

export default Advertisement