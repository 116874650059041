import React from 'react'
import { Check, Label, Container } from './styles'
import { Props } from './types'

const Checkbox: React.FC<Props> = ({ label, checked, onChange }) => {
    return (
        <Container>
            <Check 
                type="checkbox" 
                id="cbx" 
                name="cbx" 
                checked={checked || false} 
                onChange={onChange}
            />
            {label && <Label>{label}</Label>}
        </Container>
    )
}

export default Checkbox