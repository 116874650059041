import React, { useState, useEffect } from 'react';
import classes from './SearchBar.module.css'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const SearchBar = props => {
    const [input, setInput] = useState('')
    const [inMemoryData, setInMemoryData] = useState()

    useEffect(() => {
        setInMemoryData(props.data)
    }, [props.data])

    const searchHandler = (value) => {
        setInput(value)
        const filteredData = inMemoryData.filter(
            item => {
                // console.log('item: ', getValue(item, props.searchedValue1))
                let itemLowerCase1 = getValue(item, props.searchedValue1).toLowerCase()
                let itemLowerCase2 = getValue(item, props.searchedValue2).toLowerCase()
                // let itemLowerCase3 = item.key3.toLowerCase()

                let searchTermLowerCase = value.toLowerCase()

                return (
                    itemLowerCase1.indexOf(searchTermLowerCase) > -1
                    || itemLowerCase2.indexOf(searchTermLowerCase) > -1
                    // || itemLowerCase3.indexOf(searchTermLowerCase) > -1
                )
            }
        )
        props.setFixedData(filteredData)
    }

    const getValue = (obj, path) => {
        return path.split(".").reduce(function (obj, name) {
            return obj[name]
        }, obj);
    }

    return (
        <div className={classes.container} style={{...props.style}} >
            <input
                className={`${classes.input} ${props.className}`}
                type="text"
                value={input}
                placeholder="Pesquisar"
                onChange={(e) => searchHandler(e.target.value)}
            />
            <SearchRoundedIcon style={{width: 20, padding: 0}} />

        </div >
    )
}

export default SearchBar