import React, { useState, useCallback, useEffect } from 'react'
import classes from './PrivacyPolicy.module.css'
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import environment from '../../env/environment'

const PrivacyPolicy = props => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const loadData = useCallback(async () => {
        try {
            const policyResponse = await fetch(`${environment.databaseURL}/legal/privacy_policy/content.json`);
            if (!policyResponse.ok) {
                throw new Error('Something went wrong with fetching privacy_policy')
            }
            const loadedPolicy = await policyResponse.json();
            const rawContent = convertFromRaw(JSON.parse(loadedPolicy))
            setEditorState(() => EditorState.createWithContent(rawContent))

        } catch (err) {
            console.log('Error: ', err.message);
        }
    }, []);

    useEffect(() => {
        loadData()
    }, [loadData]);

    return (
        <div className={classes.Screen}>
            <Editor
                editorState={editorState}
                readOnly={true}
            />
        </div >
    )
}

export default PrivacyPolicy
