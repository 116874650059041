import React, { useEffect, useCallback, useState } from 'react'
import classes from './Faqs.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as faqActions from '../../store/actions/faqActions'
import FaqItem from '../../components/items/FaqItem/FaqItem'
import SearchBar from '../../components/UI/SearchBar/SearchBar';
import Colors from '../../constants/colors'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import "react-activity/dist/Dots.css";

const FaqsPage = props => {
    const [selectedFaq, setSelectedFaq] = useState(null)
    const [searchedItems, setSearchedItems] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [success, setSuccess] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const dispatch = useDispatch()

    const isFaqsLoaded = useSelector(state => state.faqs.loaded)
    const faqs = useSelector(state => state.faqs.availableFaqs)

    useEffect(() => {
        if (faqs && faqs.length > 0) {
            setSearchedItems(faqs)
        }
    }, [faqs])

    const loadData = useCallback(async () => {
        try {
            setLoading(true)
            if (!isFaqsLoaded) await dispatch(faqActions.fetchFaqs());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch, isFaqsLoaded,]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    useEffect(() => {
        if (selectedFaq) {
            setTitle(selectedFaq.title)
            setDescription(selectedFaq.description)
        } else {
            setTitle('')
            setDescription('')
        }
    },[selectedFaq])

    const createFaqHandler = async () => {
        setErrorMessage(null)
        if (title?.length < 3) {
            setErrorMessage("O título não pode ter menos de 3 caracteres!")
            return
        }
        if (description?.length < 3) {
            setErrorMessage("A descrição não pode ter menos de 3 caracteres!")
            return
        }
        setButtonLoading(true)
        try {
            if(selectedFaq) {
                await dispatch(faqActions.updateFaq(selectedFaq.id, title, description))
            } else {
                await dispatch(faqActions.createFaq(title, description))
                
            }
            setButtonLoading(false)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    const deleteFaqHandler = async () => {
        setButtonLoading(true)
        try {
            await dispatch(faqActions.deleteFaq(selectedFaq.id))
            setButtonLoading(false)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.usersContainer}>
                <ButtonText
                    hide={!selectedFaq}
                    title="Nova FAQ"
                    style={{ marginBottom: 20, position: 'absolute', right: 5, top: 5 }}
                    onClick={() => { setSelectedFaq(null); setSuccess(false) }}
                >
                    <AddRoundedIcon style={{ color: Colors.primary, width: 16 }} />
                </ButtonText>
                <SearchBar
                    style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                    data={faqs}
                    setFixedData={(val) => { setSearchedItems(val) }}
                    searchedValue1='title'
                    searchedValue2='description'
                />
                {searchedItems && searchedItems.map((item, index) => (
                    <FaqItem
                        key={item.id}
                        item={item}
                        onClick={() => { setSelectedFaq(item); setSuccess(false) }}
                        isSelected={item.id === selectedFaq?.id}
                        number={index + 1}
                    />
                ))}
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.titleRow}>
                    <div className={classes.titleContainer} >
                        <LiveHelpRoundedIcon style={{ color: selectedFaq?.tipo === "ADMIN" ? Colors.primary : Colors.quaternary }} />
                        <h4>{selectedFaq ? selectedFaq.title : 'Enviar Nova FAQ'}</h4>
                    </div>
                    <ButtonBox
                        hide={!selectedFaq}
                        style={{ marginTop: 10 }}
                        className={classes.deleteButton}
                        title='Deletar'
                        buttonLoading={buttonLoading}
                        onClick={deleteFaqHandler}
                        loading={buttonLoading}
                    />
                </div>
                <div className={classes.configurationContainer} >

                    <Input
                        hide={success}
                        label='Título'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        shouldValidate
                        invalid={title.length < 3}
                        touched={errorMessage}
                    />
                    <Input
                        hide={success}
                        className={classes.desc}
                        label='Descrição'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        elementType="textarea"
                        shouldValidate
                        invalid={description.length < 3}
                        touched={errorMessage}
                    />

                    <ButtonBox
                        hide={success || loading}
                        style={{ marginTop: 10 }}
                        title={selectedFaq ? 'Atualizar' : 'Adicionar'}
                        loading={buttonLoading}
                        onClick={createFaqHandler}
                    />
                    <div className={classes.error}>{errorMessage}</div>
                </div>
            </div>
        </div>
    )
}

export default FaqsPage