import React, { useState, useCallback, useEffect } from 'react'
import classes from './TermsAndConditions.module.css'
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import environment from '../../env/environment'

const TermsAndConditions = props => {
    const [termsEditorState, setTermsEditorState] = useState(EditorState.createEmpty())

    const loadData = useCallback(async () => {
        try {
            const termsResponse = await fetch(`${environment.databaseURL}/legal/terms_of_use/content.json`);
            if (!termsResponse.ok) {
                throw new Error('Something went wrong with fetching about')
            }
            const loadedTerms = await termsResponse.json();
            const rawTermsContent = convertFromRaw(JSON.parse(loadedTerms))
            setTermsEditorState(() => EditorState.createWithContent(rawTermsContent))

        } catch (err) {
            console.log('Error: ', err.message);
        }
    }, []);

    useEffect(() => {
        loadData()
    }, [loadData]);

    return (
        <div className={classes.Screen}>
            <Editor
                editorState={termsEditorState}
                readOnly={true}
            />
        </div >
    )
}

export default TermsAndConditions
