import React from 'react'
import logoHorizontal from '../../assets/logo-horizontal.svg'
import classes from './LogoHorizontal.module.css'

const LogoHorizontal = props => (
    <div className={classes.Logo}>
        <img src={logoHorizontal} alt={'XLEI'} />
    </div>
)

export default LogoHorizontal
