import React from 'react'
import classes from './SubModuleLine.module.css'
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';

const SubModuleLine = props => {

    const item = props.item

    return (
        <div
            className={classes.container}
            draggable={true}
            id={props.boxNumber}
            onClick={props.onClick}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
        >
            <p className={classes.title}><b>{item.title}</b></p>
            <DragIndicatorRoundedIcon />
        </div>
    )
}

export default SubModuleLine