import environment from '../../env/environment'
import { auth } from "../../env/environment";

export const SET_ABOUT = 'SET_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';

export const fetchAbout = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/about/about.json`);

            if (!response.ok) {
                throw new Error('Something went wrong with fetching about')
            }
            const loadedAbout = await response.json();
            
            dispatch({
                type: SET_ABOUT,
                about: loadedAbout,
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const updateAbout = (about) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token

        try {
            const response = await fetch(`${environment.databaseURL}/about.json?auth=${token}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({about})
            })
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error)
            }
            dispatch({
                type: UPDATE_ABOUT,
                about: about,
            })
        } catch (error) {
            throw new Error(error);
        }
    }
};
