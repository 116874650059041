import TextPdf from '../../models/TextPdf';
import { SET_TEXT_PDFS, CREATE_TEXT_PDF, UPDATE_TEXT_PDF, DELETE_TEXT_PDF, GET_TEXTS_VERSION, UPDATE_TEXTS_VERSION, CHANGE_ORDER_TEXTS } from '../actions/textPdfActions';

const initialState = {
    availableTextPdfs: [],
    semTextPdfs: [],
    textsVersion: 0,
    loaded: false
}

const textPdfReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEXT_PDFS:
            let rTexts = [...action.textPdfs.filter(t => t.listaTextosId === 'textosSemAssociacao')]
            let reversedSemTexts = rTexts.reverse()
            return {
                ...state,
                availableTextPdfs: action.textPdfs,
                semTextPdfs: reversedSemTexts,
                loaded: true
            }
        // case CREATE_TEXT_PDF:
        //     const newText = new TextPdf(
        //         action.tid,
        //         action.listaTextosId,
        //         action.textData.caminhoFirebaseStorage,
        //         action.textData.dataPublicacao,
        //         action.textData.dou,
        //         action.textData.metaTags,
        //         action.textData.observacao,
        //         action.textData.origem,
        //         action.textData.pagina,
        //         action.textData.sessao,
        //         action.textData.subModuloId,
        //         action.textData.textoPago,
        //         action.textData.title,
        //         action.textData.versao,
        //         action.key,
        //         action.textData.image,
        //         action.textData.type,
        //     )
        //     const isSem = action.textData.subModuloId === 'textosSemAssociacao'
        //     console.log('issem: ', isSem)
        //     const NewSemTextPdfs = [...state.semTextPdfs]
        //     if (isSem) {
        //         NewSemTextPdfs.unshift(newText)
        //     }

        //     return {
        //         ...state,
        //         availableTextPdfs: state.availableTextPdfs.concat(newText),
        //         semTextPdfs: NewSemTextPdfs
        //     }
        // case UPDATE_TEXT_PDF:
        //     const textIndex = state.availableTextPdfs.findIndex(t => t.id === action.tid);
        //     const semTextIndex = state.semTextPdfs.findIndex(t => t.id === action.tid);
        //     const updatedText = new TextPdf(
        //         action.tid,
        //         action.listaTextosId,
        //         action.textData.caminhoFirebaseStorage,
        //         action.textData.dataPublicacao,
        //         action.textData.dou,
        //         action.textData.metaTags,
        //         action.textData.observacao,
        //         action.textData.origem,
        //         action.textData.pagina,
        //         action.textData.sessao,
        //         action.textData.subModuloId,
        //         action.textData.textoPago,
        //         action.textData.title,
        //         action.textData.versao,
        //         action.key,
        //         action.textData.image,
        //         action.textData.type,
        //     )
        //     const updatedAllText = [...state.availableTextPdfs];
        //     updatedAllText[textIndex] = updatedText;

        //     const isSemT = action.listaTextosId === 'textosSemAssociacao'
        //     console.log('issem: ', isSemT)
        //     const updatedSemText = [...state.semTextPdfs];
        //     if (isSemT) {
        //         updatedSemText[semTextIndex] = updatedText;
        //     }


        //     return {
        //         ...state,
        //         availableTextPdfs: updatedAllText,
        //         semTextPdfs: updatedSemText,
        //     }
        case DELETE_TEXT_PDF:
            const isSemText = action.listaId === 'textosSemAssociacao'
            console.log('issem: ', isSemText)
            let deletedSemTexts = [...state.semTextPdfs]
            if (isSemText) {
                deletedSemTexts = deletedSemTexts.filter(t => t.id !== action.id)
            }
            return {
                ...state,
                availableTextPdfs: state.availableTextPdfs.filter(t => t.id !== action.id),
                semTextPdfs: deletedSemTexts
            };
        // case CHANGE_ORDER_TEXTS:
        //     const reorderedTexts = [...state.availableTextPdfs]
        //     // reorderedTexts[action.dragKey] = action.dropItem
        //     // reorderedTexts[action.dropKey] = action.dragItem
        //     console.log(reorderedTexts[action.dragKey])
        //     console.log(reorderedTexts[action.dropKey])

        //     return {
        //         ...state,
        //         availableTextPdfs: reorderedTexts
        //     }
        case GET_TEXTS_VERSION:
            return {
                ...state,
                textsVersion: action.version
            }
        case UPDATE_TEXTS_VERSION:
            return {
                ...state,
                textsVersion: action.version
            }
        default:
            return state;
    }
}

export default textPdfReducer