import React from 'react'
import classes from './AdvertisementItem.module.css'

const NotificationItem = props => {

    const item = props.item

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
        >
            <img className={classes.image} src={item.enderecoDaImagem} alt={''} />
            <div className={classes.titleContainer}>
                <p id={classes.oneLine}><b>{item.nome}</b></p>
                <p id={classes.oneLine}>{item.observacao}</p>
            </div>
            <div className={classes.tipoContainer}>
                <p className={classes.tipo} style={{color: item.ativo ? '#5ac18e' : '#ccc'}}>{item.ativo ? 'Ativo' : 'Desativado'}</p>
            </div>
        </div >
    )
}

export default NotificationItem