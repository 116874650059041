class SubModule {
    constructor(id, listaTextosId, idTextoASerExibido, title, observacao, disabled, invisivel, exibeDiretamenteTexto, image, url, noNavigate, versao) {
        this.id = id
        this.listaTextosId = listaTextosId
        this.idTextoASerExibido = idTextoASerExibido
        this.title = title
        this.observacao = observacao
        this.disabled = disabled
        this.invisivel = invisivel
        this.exibeDiretamenteTexto = exibeDiretamenteTexto
        this.image = image
        this.url = url
        this.noNavigate = noNavigate
        this.versao = versao
    }
}

export default SubModule