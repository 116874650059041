import { CREATE_NOTIFICATION, DELETE_NOTIFICATION, SET_NOTIFICATIONS } from '../actions/notificationActions';
import Notification from '../../models/Notification';

const initialState = {
    availableNotifications: [],
    loaded: false
}

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {
                ...state,
                availableNotifications: action.notifications,
                loaded: true
            }
        case CREATE_NOTIFICATION:
            const newNotification = new Notification(
                action.nid,
                action.notificationData.title,
                action.notificationData.message,
                action.notificationData.senderId,
                action.notificationData.date,
                action.notificationData.relatedScreen,
                action.notificationData.relatedData,
            )
            return {
                ...state,
                availableNotifications: state.availableNotifications.concat(newNotification),
            }
        case DELETE_NOTIFICATION:
            return {
                ...state,
                availableNotifications: state.availableNotifications.filter(n => n.id !== action.nid),
            };
        default:
            return state
    }
}

export default notificationReducer