import React, { useState, useEffect } from 'react'
import classes from './ModuleRow.module.css'
import SubModuleCard from '../SubModuleCard/SubModuleCard'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Colors from '../../../constants/colors';

const ModuleRow = props => {
    const [open, setOpen] = useState(false)

    // console.log('shouldOpen', props.row.key, props.shouldOpened)

    const row = props.row

    const Icon = open ? KeyboardArrowDownRoundedIcon : ChevronLeftRoundedIcon

    useEffect(() => {
        if (props.extended || props.shouldOpened) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [props.extended, props.shouldOpen])

    return (
        <div className={classes.container}>
            <div
                className={classes.titleContainer}
                id={props.isModuleSelected ? classes.titleActive : null}
                onClick={props.onClickModule}
                draggable={true}
                onDragOver={(ev) => ev.preventDefault()}
                onDragStart={props.handleDrag}
                onDrop={props.handleDrop}
            >
                <p className={classes.title}><b>{row.title}</b></p>
                <div onClick={() => setOpen(!open)}>
                    <Icon style={{ color: Colors.primary, width: 24 }} />
                </div>

            </div>
            <div className={classes.gridContainer}>
                {open && row.submodulos.map((item, index) => (
                    <SubModuleCard
                        key={item.id}
                        className={classes.gridItem}
                        isSelected={props.selected === item}
                        item={item}
                        onClick={() => props.onClickSubModule(item, row.key)}
                    />
                ))}
            </div>
        </div>
    )
}

export default ModuleRow