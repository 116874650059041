import React, { useState, useEffect, useCallback } from 'react'
import classes from './EditText.module.css'
import { useDispatch, useSelector } from 'react-redux'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import "react-activity/dist/Dots.css";
import { useLocation } from 'react-router-dom';
import * as textPdfActions from '../../store/actions/textPdfActions'
import { useHistory } from 'react-router-dom'
import defaultImage from '../../assets/default_image.jpg'

const EditSubModulePage = props => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [succeed, setSucceed] = useState(false)

    const [title, setTitle] = useState('')
    const [observacao, setObservacao] = useState('')
    const [semAssociation, setSemAssociation] = useState(false)
    const [textType, setTextType] = useState('PDF')
    const [exclusive, setExclusive] = useState(false)
    const [version, setVersion] = useState(1)
    const [pdf, setPdf] = useState(null)
    const [pdfPath, setPdfPath] = useState(null)
    const [origem, setOrigem] = useState('')
    const [dou, setDou] = useState('')
    const [sessao, setSessao] = useState('')
    const [pagina, setPagina] = useState('')
    const [publishDate, setPublishDate] = useState(null)
    const [tags, setTags] = useState('')
    const [image, setImage] = useState(null)
    const [imagePath, setImagePath] = useState(null)
    const [contentId, setContentId] = useState(null)
    const [textId, setTextId] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const currentSubModule = location?.state?.subModule
    const currentText = location?.state?.text
    const semAs = location?.state?.semAs

    const directTextId = currentSubModule.exibeDiretamenteTexto ? currentSubModule.idTextoASerExibido : null

    const textsVersion = useSelector(state => state.textPdfs.textsVersion)
    console.log('Global texts version: ', textsVersion)

    const loadVersion = useCallback(async () => {
        try {
            await dispatch(textPdfActions.getTextsVersion())
        } catch (err) {
            console.log('Errorr: ', err.message);
        }
    }, [dispatch]);

    useEffect(() => {
        loadVersion()
    }, [loadVersion]);

    useEffect(() => {
        if (currentText) {
            setTextId(currentText.id)
            setTitle(currentText.title)
            setObservacao(currentText.observacao)
            setSemAssociation(currentText.association)
            setTextType(currentText.type || 'PDF')
            setExclusive(currentText.textoPago)
            setOrigem(currentText.origem)
            setDou(currentText.dou)
            setSessao(currentText.sessao)
            setPagina(currentText.pagina)
            setImagePath(currentText.image)
            setContentId(currentText.contentId)

            if (currentText.dataPublicacao !== 'Invalid date') {
                const day = currentText.dataPublicacao.substring(0, 2)
                const month = currentText.dataPublicacao.substring(3, 5)
                const year = currentText.dataPublicacao.substring(6, 10)
                const currentDate = new Date(`${year}-${month}-${day}T00:00:00`)
                setPublishDate(currentDate)
            }
            if (currentText.metaTags?.length > 0) {
                setTags(currentText.metaTags.join(' '))
            }
            if (currentText.versao === undefined) {
                console.log("versao: ", currentText.versao)
                setVersion(2)
            } else {
                setVersion(currentText.versao + 1)
            }
            setPdfPath(currentText.pdfUrl)
        }
        setSemAssociation(semAs)
    }, [currentText, semAs])

    const createTextHandler = async () => {
        setErrorMessage(null)
        console.log('Text Type: ', textType)
        if (textType === 'PDF' && !currentText && !pdfPath) {
            setErrorMessage("Selecione um PDF.")
            return
        }
        if (title?.length < 3) {
            setErrorMessage("O título não pode ter menos de 3 caracteres!")
            return
        }
        if (origem?.length < 3) {
            setErrorMessage("O origem não pode ter menos de 3 caracteres!")
            return
        }
        if (semAssociation?.length < 1) {
            setErrorMessage("Selecione o estado invisível!")
            return
        }
        if (exclusive?.length < 1) {
            setErrorMessage("Selecione o estado desativado!")
            return
        }
        if (observacao?.length < 3) {
            setErrorMessage("A observação não pode ter menos de 3 caracteres!")
            return
        }
        setButtonLoading(true)
        const listId = semAssociation ? 'textosSemAssociacao' : currentSubModule.listaTextosId
        try {
            if (currentText) {
                await dispatch(textPdfActions.updateTextPdf(currentText.id, currentText.key, currentSubModule.listaTextosId, currentSubModule.listaTextosId, title, observacao, origem, dou, pdf, sessao, pagina, publishDate, version, exclusive === "true" ? true : false, tags, image, textType))
                await dispatch(textPdfActions.updateTextsVersion(textsVersion + 1))
                await dispatch(textPdfActions.fetchTextPdfs())
            } else {
                const id = await dispatch(textPdfActions.createTextPdf(currentSubModule.id, listId, title, observacao, origem, dou, pdf, sessao, pagina, publishDate, version, exclusive === 'true' ? true : false, tags, semAssociation, directTextId, image, textType))
                setTextId(id)
                await dispatch(textPdfActions.updateTextsVersion(textsVersion + 1))
                await dispatch(textPdfActions.fetchTextPdfs())
            }
            // await dispatch(textPdfActions.fetchTextPdfs())
            setButtonLoading(false)
            setSucceed(true)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    console.log('CT: ', currentText)

    const handlePDFChange = (e) => {
        if (e.target.files[0]) {
            setPdf(e.target.files[0])
            setPdfPath(URL.createObjectURL(e.target.files[0]))
        }
    }

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            setImagePath(URL.createObjectURL(e.target.files[0]))
        }
    }

    if (succeed) {
        return (
            <div className={classes.SuccessMessage}>
                <div>O texto adicionado com sucesso!</div>
                <ButtonBox
                    type="success"
                    style={{ marginTop: 10, alignSelf: 'center' }}
                    title={textType === 'EDITOR' ? 'Editar Conteúdo' : 'Voltar para módulos'}
                    onClick={async() => { 
                        await dispatch(textPdfActions.fetchTextPdfs());
                        if(textType === 'EDITOR') {
                            history.push('/EditTextEditor', {
                                textId : textId,
                                title: title
                            })
                        } else {
                            history.push('/', { moduleId: null} )
                        }
                    }}
                />
            </div>
        )
    }

    return (
        <div className={classes.Screen}>
            <p className={classes.title}>{currentText ? 'EDITAR TEXTO - ' + currentText.title : "NOVO TEXTO"}</p>

            <div className={classes.formContainer}>
                <div className={classes.left}>
                    <Input
                        label='Título'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        shouldValidate
                        invalid={title.length < 3}
                        touched={errorMessage}
                    />
                    <Input
                        label='Origem'
                        value={origem}
                        onChange={(e) => setOrigem(e.target.value)}
                        shouldValidate
                        invalid={origem.length < 3}
                        touched={errorMessage}
                    />
                    <Input
                        label='Dou'
                        value={dou}
                        onChange={(e) => setDou(e.target.value)}
                        // shouldValidate
                        // invalid={dou.length < 3}
                        touched={errorMessage}
                    />

                    <Input
                        label='Texto sem associação'
                        value={semAssociation}
                        onChange={(e) => setSemAssociation(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{
                            options: [{ id: 1, label: 'SIM', value: true }, { id: 2, label: 'NAO', value: false }],
                            disabled: true
                        }}
                        shouldValidate
                        invalid={semAssociation === ''}
                        touched={errorMessage}
                    />
                    <Input
                        className={classes.desc}
                        label='Adicionar Tags'
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                    />
                    <Input
                        label='Versão'
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        elementConfig={{ disabled: true }}
                    />
                    <Input
                        label='Tipo de Texto'
                        value={textType}
                        onChange={(e) => setTextType(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{
                            options: [{ id: 1, label: 'EDITOR', value: 'EDITOR' }, { id: 2, label: 'PDF', value: 'PDF' }],
                        }}
                        shouldValidate
                        invalid={textType === '' || textType === null}
                        touched={errorMessage}
                    />
                    {textType === 'PDF' &&
                        <div className={classes.fileContainer}>
                            <p className={classes.imageName}>{pdf ? pdf.name : currentText?.caminhoFirebaseStorage ? currentText.caminhoFirebaseStorage : 'Nenhuma PDF selecionada'}</p>
                            <div className={classes.fileSelector}>
                                <label htmlFor="upload-pdf">Escolha a PDF</label>
                                <input className={classes.fileInput} type="file" id="file" accept=".pdf" onChange={handlePDFChange} />
                            </div>
                        </div>
                    }
                    
                </div>
                <div className={classes.right}>
                    
                    <Input
                        label='Texto Exclusivo'
                        value={exclusive}
                        onChange={(e) => setExclusive(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{
                            options: [{ id: 1, label: 'SIM', value: true }, { id: 2, label: 'NAO', value: false }],
                            exclusive: currentSubModule ? 'false' : true
                        }}
                        shouldValidate
                        invalid={exclusive === ''}
                        touched={errorMessage}
                    />
                    <Input
                        label='Data Publicação'
                        value={publishDate}
                        onChange={(date) => { console.log(typeof (date)); setPublishDate(date) }}
                        locale="pt"
                        // shouldValidate
                        // invalid={publishDate.length < 3}
                        touched={errorMessage}
                        elementType="date"
                    />
                    <div className={classes.imageContainer} >
                        <img className={classes.image} src={imagePath ? imagePath : defaultImage} alt={title} />
                    </div>
                    <div className={classes.fileContainer}>
                        <p className={classes.imageName}>{image ? image.name : currentText?.image ? currentText.image : 'Nenhuma imagem selecionada'}</p>
                        <div className={classes.fileSelector}>
                            <label htmlFor="upload-photo">Escolha a Imagem</label>
                            <input className={classes.fileInput} type="file" id="file" accept=".png" onChange={handleImageChange} />
                        </div>
                    </div>
                    <Input
                        className={classes.desc}
                        label='Observação'
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                        elementType="textarea"
                        shouldValidate
                        invalid={observacao.length < 3}
                        touched={errorMessage}
                    />
                </div>
            </div>
            <ButtonBox
                hide={buttonLoading}
                style={{ marginTop: 10 }}
                title={currentText ? 'Atualizar' : 'Adicionar'}
                loading={buttonLoading}
                onClick={createTextHandler}
            />
            <div className={classes.error}>{errorMessage}</div>
        </div>
    )
}

export default EditSubModulePage