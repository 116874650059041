import Support from "../../models/Support";
import environment, { auth } from '../../env/environment'
import sendNotification from "../../utils/sendNotification";

// import firebase from '@react-native-firebase/app';
// import auth from '@react-native-firebase/auth';

export const ANSWER_SUPPORT = 'ANSWER_SUPPORT';
export const SET_SUPPORTS = 'SET_SUPPORTS';

export const fetchSupports = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/supports.json`);

            if (!response.ok) {
                const errorData = await response.json()
                const errorId = errorData.error
                throw new Error(errorId)
            }
            const resData = await response.json();
            const loadedSupports = [];
            for (const key in resData) {
                loadedSupports.unshift(
                    new Support(
                        key,
                        resData[key].relatedText,
                        resData[key].question,
                        resData[key].answer,
                        resData[key].clientId,
                        resData[key].adminId,
                        resData[key].createdAt,
                        resData[key].updatedAt,
                        resData[key].pushToken,
                    )
                )
            }
            dispatch({
                type: SET_SUPPORTS,
                supports: loadedSupports,
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const answerSupport = (id, answer, pushToken) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        const userId = auth.currentUser.uid
        const answerDate = new Date()
        const response = await fetch(`${environment.databaseURL}/supports/${id}.json?auth=${token}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                adminId: userId,
                answer,
                updatedAt: answerDate
            })
        });
        if (!response.ok) {
            const errorResData = await response.json();
            throw new Error(errorResData.error);
        }
        if (pushToken) {

            const supportResponse = await fetch(`${environment.databaseURL}/supports/${id}.json?auth=${token}`);
            if (!supportResponse.ok) {
                const errorData = await supportResponse.json()
                const errorId = errorData.error
                throw new Error(errorId)
            }
            let supportItem = await supportResponse.json();
            await sendNotification(pushToken, 'Sua pergunta foi respondida!', answer, 'SupportDetail', supportItem)
        }
        dispatch({
            type: ANSWER_SUPPORT,
            sid: id,
            supportData: {
                adminId: userId,
                answer: answer,
                updatedAt: answerDate,
            }
        });
        // if (pushToken) {

        //     const supportResponse = await fetch(`${environment.databaseURL}/supports/${id}.json?auth=${token}`);
        //     if (!supportResponse.ok) {
        //         const errorData = await supportResponse.json()
        //         const errorId = errorData.error
        //         throw new Error(errorId)
        //     }
        //     let supportItem = await supportResponse.json();
        //     await sendNotificationAndroid(pushToken, 'Sua pergunta foi respondida!', answer, 'SupportDetail', supportItem)
        // }
    }
};