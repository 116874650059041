import React from 'react'
import classes from './FaqItem.module.css'

const FaqItem = props => {

    const item = props.item

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
        >
            <p id={classes.oneLine}><b>{props.number + '- ' + item.title}</b></p>
            <p id={classes.oneLine}>{item.description}</p>
        </div >
    )
}

export default FaqItem