import React from 'react'
import classes from './Texts.module.css'

const Texts = props => {
        return (
            <div className={classes.Screen}>
                Texts
            </div>
        )
}

export default Texts
