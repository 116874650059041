class Support {
    constructor(id, relatedText, question, answer, clientId, adminId, createdAt, updatedAt, pushToken) {
        this.id = id
        this.relatedText = relatedText
        this.question = question
        this.answer = answer
        this.clientId = clientId
        this.adminId = adminId
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.pushToken = pushToken
    }
}

export default Support