import React, { useEffect, useCallback, useState } from 'react'
import classes from './Notifications.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as notificationActions from '../../store/actions/notificationActions'
import * as userActions from '../../store/actions/userActions'
import * as moduleActions from '../../store/actions/moduleActions'
import * as textPdfActions from '../../store/actions/textPdfActions'
import NotificationItem from '../../components/items/NotificationItem/NotificationItem'
import SearchBar from '../../components/UI/SearchBar/SearchBar';
import Colors from '../../constants/colors'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import moment from 'moment'
import "react-activity/dist/Dots.css";

const Notifications = props => {
    const [selectedNotification, setSelectedNotification] = useState(null)
    const [searchedItems, setSearchedItems] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [moduleId, setModuleId] = useState('')
    const [module, setModule] = useState(null)
    const [subModule, setSubModule] = useState('')
    const [textId, setTextId] = useState('')
    const [relatedData, setRelatedData] = useState(null)
    const [success, setSuccess] = useState(null)
    const [totalReceivers, setTotalReceivers] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const dispatch = useDispatch()

    const isNotificationsLoaded = useSelector(state => state.notifications.loaded)
    const isUsersLoaded = useSelector(state => state.users.loaded)
    const isModulesLoaded = useSelector(state => state.modules.loaded)
    const isTextsLoaded = useSelector(state => state.textPdfs.loaded)

    const notifications = useSelector(state => state.notifications.availableNotifications)
    const sender = useSelector(state => state.users.availableUsers)?.find(u => u.id === selectedNotification?.senderId)
    const modules = useSelector(state => state.modules.availableModules);
    const texts = useSelector(state => state.textPdfs.availableTextPdfs)

    useEffect(() => {
        if (notifications && notifications.length > 0) {
            setSearchedItems(notifications)
        }
    }, [notifications])

    const loadData = useCallback(async () => {
        try {
            setLoading(true)
            if (!isNotificationsLoaded) await dispatch(notificationActions.fetchNotifications());
            if (!isUsersLoaded) await dispatch(userActions.fetchUsers());
            if (!isModulesLoaded) await dispatch(moduleActions.fetchModules());
            if (!isTextsLoaded) await dispatch(textPdfActions.fetchTextPdfs());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch, isNotificationsLoaded, isUsersLoaded, isModulesLoaded, isTextsLoaded]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    const sendNotificationHandler = async () => {
        setErrorMessage(null)
        if (title?.length < 3) {
            setErrorMessage("O título não pode ter menos de 3 caracteres!")
            return
        }
        if (message?.length < 3) {
            setErrorMessage("A mensagem não pode ter menos de 3 caracteres!")
            return
        }
        if (moduleId === '' || moduleId === null) {
            setErrorMessage("Selecione um módulo!!")
            return
        }
        if (subModule === '' || subModule === null) {
            setErrorMessage("Selecione um sub módulo!")
            return
        }

        setButtonLoading(true)

        let relatedScreen = ''
        let RD = null

        if (relatedData) {
            relatedScreen = 'DetailPdf'
            RD = relatedData
        } else {
            relatedScreen = 'PdfOverview'
            RD = module.submodulos.find(sm => sm.listaTextosId === subModule)
        }
        if (!RD) {
            setErrorMessage("Este submódulo redireciona para um texto direto. Selecione o texto único!")
            return
        }

        try {
            const number = await dispatch(notificationActions.createNotification(title, message, relatedScreen, RD))
            console.log("sending notifications is successfull")
            setTotalReceivers(number)
            loadData()
            setSuccess(true)
            setTitle('')
            setMessage('')
            setModuleId(null)
            setModule(null)
            setSubModule(null)
            setTextId(null)
            setRelatedData(null)
            setErrorMessage(null)
            setButtonLoading(false)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    const deleteNotificationHandler = async () => {
        setButtonLoading(true)
        try {
            await dispatch(notificationActions.deleteNotification(selectedNotification.id))
            setSelectedNotification(null)
            setButtonLoading(false)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    useEffect(() => {
        const modObj = modules.find(m => m.id === moduleId)
        setModule(modObj)
    }, [moduleId, modules])

    useEffect(() => {
        const textObj = texts.find(t => t.id === textId)
        setRelatedData(textObj)
        console.log('RE - DA: ', textObj)
    }, [textId, texts])

    return (
        <div className={classes.Screen}>
            <div className={classes.usersContainer}>
                <ButtonText
                    hide={!selectedNotification}
                    title="Nova Notificação"
                    style={{ marginBottom: 20, position: 'absolute', right: 5, top: 5 }}
                    onClick={() => { setSelectedNotification(null); setSuccess(false) }}
                >
                    <AddRoundedIcon style={{ color: Colors.primary, width: 16 }} />
                </ButtonText>
                <SearchBar
                    style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                    data={notifications}
                    setFixedData={(val) => { setSearchedItems(val) }}
                    searchedValue1='title'
                    searchedValue2='message'
                />
                {searchedItems && searchedItems.map(item => (
                    <NotificationItem
                        key={item.id}
                        item={item}
                        onClick={() => { setSelectedNotification(item); setSuccess(false) }}
                        isSelected={item.id === selectedNotification?.id}
                        client={notifications.find(u => u.id === item.clientId)}
                    />
                ))}
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.titleRow}>
                    <div className={classes.titleContainer} >
                        <NotificationsRoundedIcon style={{ color: selectedNotification?.tipo === "ADMIN" ? Colors.primary : Colors.quaternary }} />
                        <h4>{selectedNotification ? selectedNotification.title : 'Enviar Nova Notificação'}</h4>
                    </div>
                    <ButtonBox
                        hide={!selectedNotification}
                        style={{ marginTop: 10 }}
                        className={classes.deleteButton}
                        title='Deletar'
                        buttonLoading={buttonLoading}
                        onClick={deleteNotificationHandler}
                        loading={buttonLoading}
                    />
                </div>
                {!selectedNotification &&
                    <div className={classes.configurationContainer} >
                        {success &&
                            <div>
                                Notificação enviada com sucesso para {totalReceivers} usuários!
                            </div>
                        }
                        <Input
                            hide={success}
                            label='Título'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            shouldValidate
                            invalid={title.length < 3}
                            touched={errorMessage}
                        />
                        <Input
                            hide={success}
                            label='Descrição'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            elementConfig={{ disabled: selectedNotification ? true : false }}
                            elementType="textarea"
                            shouldValidate
                            invalid={message.length < 3}
                            touched={errorMessage}
                        />
                        <Input
                            hide={success}
                            label='Módulo'
                            value={moduleId}
                            onChange={(e) => { setModuleId(e.target.value); setSubModule(null) }}
                            elementConfig={{ options: modules }}
                            elementType="select"
                            selectedLabel="title"
                            selectedValue="id"
                            shouldValidate
                            invalid={moduleId === ''}
                            touched={errorMessage}
                            loading={loading}
                        />
                        {module && <Input
                            hide={!module || success}
                            label='Sub Módulo'
                            value={subModule}
                            onChange={(e) => {
                                setSubModule(e.target.value);
                                let subObj = module.submodulos.find(sm => sm.listaTextosId === e.target.value)
                                console.log('Direct?: ', subObj.idTextoASerExibido)
                                if(subObj.idTextoASerExibido) {
                                    setTextId(subObj.idTextoASerExibido)
                                } else {
                                    setTextId(null)
                                }
                            }}
                            elementConfig={{ options: module?.submodulos }}
                            elementType="select"
                            selectedLabel="title"
                            selectedValue="listaTextosId"
                            shouldValidate
                            invalid={subModule === ''}
                            touched={errorMessage}
                            loading={loading}
                        />}
                        <Input
                            hide={!module || !subModule || success}
                            label='Documento'
                            value={textId}
                            onChange={(e) => setTextId(e.target.value)}
                            elementConfig={{ options: texts.filter(t => t.listaTextosId === subModule) }}
                            elementType="select"
                            selectedLabel="title"
                            selectedValue="id"
                            shouldValidate
                            invalid={textId === ''}
                            touched={errorMessage}
                            loading={loading}
                        />
                        <ButtonBox
                            hide={success || loading}
                            style={{ marginTop: 10 }}
                            title='Enviar'
                            loading={buttonLoading}
                            onClick={sendNotificationHandler}
                        />
                        <div className={classes.error}>{errorMessage}</div>
                    </div>
                }
                {selectedNotification &&
                    <div className={classes.configurationContainer} >

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Code:</p>
                            <p className={classes.value}>{selectedNotification.id}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Título:</p>
                            <p className={classes.value}>{selectedNotification.title}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Mensagem:</p>
                            <p className={classes.value}>{selectedNotification.message}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Data:</p>
                            <p className={classes.value}>{moment(selectedNotification.date).format('DD/MM/YYYY hh:mm')}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Módulo:</p>
                            <p className={classes.value}>{selectedNotification.relatedData?.origem}</p>
                        </div>

                        {/* <div className={classes.infoRow} >
                            <p className={classes.label}>Documento:</p>
                            <p className={classes.value}>{selectedNotification.relatedData?.origem}</p>
                        </div> */}

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Enviado por:</p>
                            <p className={classes.value}>{sender.nome}</p>
                        </div>


                    </div>
                }


            </div>
        </div>
    )
}

export default Notifications
