class Notification {
    constructor(id, title, message, senderId, date, relatedScreen, relatedData) {
        this.id = id
        this.title = title
        this.message = message
        this.senderId = senderId
        this.date = date
        this.relatedScreen = relatedScreen
        this.relatedData = relatedData
    }
}

export default Notification