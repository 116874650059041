import React, {useState, useEffect, useCallback} from 'react'
import classes from './NotificationItem.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as moduleActions from '../../../store/actions/moduleActions'

const NotificationItem = props => {
    const [submodulo, setSubmodulo] = useState(null)
    const [text, setText] = useState(null)

    const item = props.item
    const modulos = useSelector(state => state.modules.availableModules)

    const dispatch = useDispatch()

    const loadModules = useCallback(async () => {
        try {
            await dispatch(moduleActions.fetchModules());
        } catch (err) {
            console.log('Error: ', err.message);
        }
    }, [dispatch]);

    useEffect(() => {
        loadModules()
    }, [loadModules]);

    useEffect(() => {
        if (modulos && modulos.length > 0) {
            for (const key in modulos) {
                const sub1 = modulos[key].submodulos.find(i => i.listaTextosId === props.item.relatedData?.subModuloId)
                if (sub1) {
                    setSubmodulo(sub1)
                    return
                }
            }
            for (const key in modulos) {
                const sub2 = modulos[key].submodulos.find(i => i.id === props.item.relatedData?.id)
                if (sub2) {
                    setText(sub2)
                    return
                }
            }
        }
    }, [modulos, submodulo, text, props])

    const imageUrl = text ? text.image : submodulo ? submodulo.image : null

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
        >
            <img className={classes.image} src={imageUrl} alt={''} />
            <div className={classes.titleContainer}>
                <p id={classes.oneLine}><b>{item.title}</b></p>
                <p id={classes.oneLine}>{item.message}</p>
            </div>
            <div className={classes.tipoContainer}>
                {/* <p className={classes.tipo}>{item.tipo === "ADMIN" ? 'Admin' : ''}</p> */}
            </div>
        </div >
    )
}

export default NotificationItem