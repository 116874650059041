import React, { useEffect, useState } from 'react'
import classes from './Navigator.module.css'
import Layout from '../../components/Layout/Layout'
import Login from '../../views/login/Login'
import { useSelector, useDispatch } from 'react-redux'
import * as authActions from '../../store/actions/authActions'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { Route } from 'react-router-dom'
import TermsAndConditions from '../../views/TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from '../../views/PrivacyPolicy/PrivacyPolicy'
import PreviewTextContent from '../../views/PreviewTextContent/PreviewTextContent'

const Public = props => {
    return (
        <main>
            {/* <Route exact path="/"><Redirect to="/login" /></Route> */}
            <Route path="/" exact component={Login} />
            <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/text-content" exact component={PreviewTextContent} />
        </main>
    )
}

const Navigator = props => {
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
    const isAuth = useSelector(state => state.auth.user)
    const triedAutoLogin = useSelector(state => state.auth.triedAutoLogin)

    useEffect(() => {
        const tryAutoLogin = async () => {
            setLoading(true)
            try {
                await dispatch(authActions.autoLogin())
                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }
        tryAutoLogin()
    }, [dispatch])

    if (loading) {
        return <div className={classes.centerIndicator}>
            <Dots />
        </div>
    }

    return (
        < div className="App" >
            {triedAutoLogin && isAuth && <Layout></Layout>}
            {triedAutoLogin && !isAuth && <Public />}
        </div >
    )
}

export default Navigator