import { CREATE_USER, SET_USERS, UPDATE_USER } from '../actions/userActions';
import User from '../../models/User';

const initialState = {
    availableUsers: [],
    loaded: false
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                availableUsers: action.users,
                loaded: true
            }
        case CREATE_USER:
            const newUser = new User(
                action.userData.id,
                action.userData.email,
                action.userData.nome,
                action.userData.tipo,
                action.userData.createdAt,
                null,
                null
            )
            return {
                ...state,
                availableUsers: state.availableUsers.concat(newUser)
            }
        case UPDATE_USER:
            const userIndex = state.availableUsers.findIndex(user => user.id === action.uid);
            const updatedUser = new User(
                action.uid,
                state.availableUsers.email,
                action.userData.nome,
                action.userData.tipo,
                state.availableUsers.createdAt,
                state.availableUsers.accessos,
                state.availableUsers.userPushToken
            )
            const updatedUsers = [...state.availableUsers];
            updatedUsers[userIndex] = updatedUser;

            return {
                ...state,
                availableUsers: updatedUsers,
            }
        default:
            return state
    }
}

export default usersReducer