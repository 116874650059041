import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0;
    margin-bottom: 4px;
`

export const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    color: rgb(56, 60, 73);
    margin-left: 4px;
    padding: 0;
`

export const Check = styled.input`
    margin: 0;
    padding: 0;
`