import { SET_ABOUT, UPDATE_ABOUT } from '../actions/aboutActions';

const initialState = {
    availableAbout: [],
    loaded: false
}

const aboutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ABOUT:
            return {
                ...state,
                availableAbout: action.about,
                loaded: true
            }
        
        case UPDATE_ABOUT:
            return {
                ...state,
                availableAbout: action.about,
            }
        default:
            return state
    }
}

export default aboutReducer