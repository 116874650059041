import styled, { css } from "styled-components";
import Colors from "../../../constants/colors";

export const Container = styled.div`
    display: flex;
    align-items: center;
`

export const Box = styled.div<{on: boolean}>`
    width: 34px;
    height: 16px;
    display: flex;
    flex-direction: column;
    background-color: ${({ on }) => on ? Colors.success : Colors.grey2};
    border-radius: 10px;
    justify-content: center;
`

export const Ball = styled.div<{on: boolean}>`
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 7px;
    margin: 0 2px;
    align-self: ${({ on }) => on ? 'flex-end' : 'flex-start'};
`

export const Label = styled.label`
    font-weight: bold;
    font-size: 14px;
    color: rgb(56, 60, 73);
    margin-left: 4px;
    padding: 0;
`