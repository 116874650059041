import React from 'react'
import classes from './TextItem.module.css'

const TextItem = props => {

    const item = props.item

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
            draggable={props.nodraggable ? false : true}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={props.handleDrag}
            onDrop={props.handleDrop}
        >
            <p className={classes.title} id={classes.oneLine}><b>{item.title}</b></p>
        </div >
    )
}

export default TextItem