import styled from "styled-components";
import Colors from "../../../constants/colors";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center
    position: relative;
    padding: 4px 8px;
    width: fit-content;
    border: 1px solid ${Colors.sgray};
    position: relative;
`

export const Input = styled.input<{width: number}>`
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: ${({width}) => width}px;
    background-color: red;
    z-index: 999;
`

export const Label = styled.label`
    font-weight: bold;
    font-size: 12px;
    color: rgb(56, 60, 73);
    padding: 0;
    margin-left: 4px;
    margin-top: 1px;
`