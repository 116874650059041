const sendNotification = async (pushToken, title, message, screenName, item) => {
    try {
        await fetch('https://exp.host/--/api/v2/push/send', {
            'mode': 'no-cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Accept-Encoding': 'gzip, deflate',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                to: pushToken,
                sound: 'default',
                title: title,
                body: message,
                data: { screenName: screenName, item: item }
            })
        })
    } catch (error) {
        console.log('Error with sending the notification');
        throw new Error('Error with sending the notification');
    }
}

export default sendNotification