import React, { useEffect, useCallback, useState } from 'react'
import classes from './Advertisements.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as notificationActions from '../../store/actions/notificationActions'
import * as advertisementActions from '../../store/actions/advertisementActions'
import AdvertisementItem from '../../components/items/AdvertisementItem/AdvertisementItem'
import SearchBar from '../../components/UI/SearchBar/SearchBar';
import Colors from '../../constants/colors'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import SlideshowRoundedIcon from '@material-ui/icons/SlideshowRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import "react-activity/dist/Dots.css";

const Advertisements = props => {
    const [selectedAd, setSelectedAd] = useState(null)
    const [editMode, setEditMode] = useState(true)
    const [searchedItems, setSearchedItems] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('')
    const [link, setLink] = useState(null)
    const [description, setDescription] = useState('')
    const [active, setActive] = useState('')
    const [image, setImage] = useState('');
    const [success, setSuccess] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [serverError, setServerError] = useState(null)

    const dispatch = useDispatch()

    const isAdLoaded = useSelector(state => state.advertisements.loaded)
    const allAdds = useSelector(state => state.advertisements.allAds)

    useEffect(() => {
        if (allAdds && allAdds.length > 0) {
            setSearchedItems(allAdds)
        }
    }, [allAdds])

    const loadData = useCallback(async () => {
        try {
            setLoading(true)
            if (!isAdLoaded) await dispatch(advertisementActions.fetchAds());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch, isAdLoaded]);

    useEffect(() => {
        loadData()
    }, [loadData]);

    const createAdHandler = async () => {
        setErrorMessage(null)
        setServerError(null)
        if (name?.length < 3) {
            setErrorMessage("O nome não pode ter menos de 3 caracteres!")
            return
        }
        if (!selectedAd && !image) {
            setErrorMessage("Selecione uma imagem")
            return
        }
        if (active === '') {
            setErrorMessage("Selecione um estado")
            return
        }
        setButtonLoading(true)
        try {
            if(selectedAd) {
                await dispatch(advertisementActions.updateAd(selectedAd.id, name, description, active, image, selectedAd.enderecoDaImagem, link))
            } else {
                await dispatch(advertisementActions.createAds(image, name, description, active, link))
            }
            setButtonLoading(false)
            loadData()
            setSuccess(true)
        } catch (err) {
            console.log(err)
            setServerError('Ocorreu um erro ao salvar o patrocinador')
            setButtonLoading(false)
        }
    }

    const deleteAdHandler = async () => {
        setButtonLoading(true)
        setServerError(null)
        try {
            await dispatch(advertisementActions.deleteAd(selectedAd.id, selectedAd.enderecoDaImagem))
            setSelectedAd(null)
            setButtonLoading(false)
            setSuccess(true)
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
            setServerError('Ocorreu um erro ao deletar o patrocinador')
        }
    }

    const onClickEdit = () => {
        setEditMode(true)
        setSuccess(false)
        setImage(null)
        setName(selectedAd.nome)
        setLink(selectedAd.link)
        setDescription(selectedAd.observacao)
        setActive(selectedAd.ativo)
    }

    const onClickNewAd = () => {
        setSelectedAd(null);
        setSuccess(false)
        setImage(null)
        setName('')
        setLink('')
        setDescription('')
        setActive(null)
        setEditMode(true)
    }

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.usersContainer}>
                <ButtonText
                    hide={!selectedAd}
                    title="Nova Patrocinadora"
                    style={{ marginBottom: 20, position: 'absolute', right: 5, top: 5 }}
                    onClick={onClickNewAd}
                >
                    <AddRoundedIcon style={{ color: Colors.primary, width: 16 }} />
                </ButtonText>
                <SearchBar
                    style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                    data={allAdds}
                    setFixedData={(val) => { setSearchedItems(val) }}
                    searchedValue1='nome'
                    searchedValue2='observacao'
                />
                {searchedItems && searchedItems.map(item => (
                    <AdvertisementItem
                        key={item.id}
                        item={item}
                        onClick={() => { setSelectedAd(item); setEditMode(false); setSuccess(false) }}
                        isSelected={item.id === selectedAd?.id}
                    />
                ))}
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.titleRow}>
                    <div className={classes.titleContainer} >
                        <SlideshowRoundedIcon style={{ color: Colors.primary }} />
                        <h4>{selectedAd ? selectedAd.nome : 'Adicionar Nova Patrocinadora'}</h4>
                    </div>
                    <div className={classes.buttonContainer} >
                        <ButtonBox
                            hide={!selectedAd || editMode}
                            style={{ marginTop: 10 }}
                            className={classes.editButton}
                            title='Editar'
                            // buttonLoading={buttonLoading}
                            onClick={onClickEdit}
                            loading={buttonLoading}
                        />
                        <ButtonBox
                            hide={!selectedAd || editMode}
                            style={{ marginTop: 10 }}
                            className={classes.deleteButton}
                            title='Deletar'
                            buttonLoading={buttonLoading}
                            onClick={deleteAdHandler}
                            loading={buttonLoading}
                        />
                    </div>
                </div>
                {success &&
                    <div>Suas alterações foram salvas com sucesso!</div>
                }
                {editMode && <div className={classes.configurationContainer} >
                    {!success &&
                        <div className={classes.fileContainer}>
                            <p className={classes.imageName}>{image ? image.name : selectedAd?.enderecoDaImagem ? selectedAd.enderecoDaImagem : 'Nenhuma imagem selecionada'}</p>
                            <div className={classes.fileSelector}>
                                <label htmlFor="upload-photo">Escolha a Imagem</label>
                                <input className={classes.fileInput} type="file" id="file" accept=".png" onChange={handleImageChange} />
                            </div>
                        </div>
                    }
                    <Input
                        hide={success}
                        label='Nome'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        shouldValidate
                        invalid={name.length < 3}
                        touched={errorMessage}
                    />
                    <Input
                        hide={success}
                        label='Link'
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                    <Input
                        hide={success}
                        label='Observação'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        elementType="textarea"
                    />
                    <Input
                        hide={success}
                        label='Ativo'
                        value={active}
                        onChange={(e) => { setActive(e.target.value === "true" ? true : false); }}
                        elementConfig={{ 
                            options: [{ id: 1, label: "SIM", value: true }, { id: 2, label: "NAO", value: false }]
                        }}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        shouldValidate
                        invalid={active === ''}
                        touched={errorMessage}
                        loading={loading}
                    />
                    <ButtonBox
                        hide={success || loading}
                        style={{ marginTop: 10 }}
                        title={selectedAd ? 'Atualizar' : 'Adicionar'}
                        loading={buttonLoading}
                        onClick={createAdHandler}
                    />
                    <div className={classes.error}>{errorMessage}</div>
                    <div className={classes.error}>{serverError}</div>
                </div>
                }
                {selectedAd && !editMode &&
                    <div className={classes.configurationContainer} >

                        <div style={{ width: "100%", marginBottom: 20 }} >
                            <img className={classes.image} src={selectedAd.enderecoDaImagem} alt={selectedAd.nome} />
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Código:</p>
                            <p className={classes.value}>{selectedAd.id}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Nome:</p>
                            <p className={classes.value}>{selectedAd.nome}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Link:</p>
                            <p className={classes.value}>{selectedAd.link || 'Não há link para este add'}</p>
                        </div>

                        <div className={classes.infoRow} >
                            <p className={classes.label}>Ativo:</p>
                            <p className={classes.value} style={{ color: selectedAd.ativo ? "green" : "red" }} >{selectedAd.ativo ? "SIM" : "NAO"}</p>
                        </div>

                        <div className={classes.infoColumn} >
                            <p className={classes.label} style={{ marginBottom: 5 }}>Observação:</p>
                            <p className={classes.value}>{selectedAd.observacao}</p>
                        </div>

                        <div className={classes.infoColumn} >
                            <p className={classes.label} style={{ marginBottom: 5 }}>Endereço:</p>
                            <p className={classes.value}>{selectedAd.enderecoDaImagem}</p>
                        </div>

                    </div>
                }


            </div>
        </div>
    )
}

export default Advertisements
