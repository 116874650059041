import React, { useEffect, useCallback, useState } from 'react'
import classes from './Users.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as userActions from '../../store/actions/userActions'
import UserItem from '../../components/items/UserItem/UserItem'
import SearchBar from '../../components/UI/SearchBar/SearchBar';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import Colors from '../../constants/colors'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { green } from '@material-ui/core/colors'
import Input from '../../components/UI/Input/Input'
import userTypes from '../../data/userTypes'

const Users = props => {
    const [selectedUser, setSelectedUser] = useState(null)
    const [searchedItems, setSearchedItems] = useState([])
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [userType, setUserType] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageInput, setErrorMessageInput] = useState(null)

    const dispatch = useDispatch()
    const isUsersLoaded = useSelector(state => state.users.loaded)
    const users = useSelector(state => state.users.availableUsers)

    useEffect(() => {
        if (users && users.length > 0) {
            setSearchedItems(users)
        }
    }, [users])

    const loadUsers = useCallback(async () => {
        setLoading(true)
        try {
            if(!isUsersLoaded) await dispatch(userActions.fetchUsers());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch, isUsersLoaded]);

    useEffect(() => {
        loadUsers()
    }, [loadUsers]);

    useEffect(() => {
        if (selectedUser) {
            setName(selectedUser.nome)
            setEmail(selectedUser.email)
            setUserType(selectedUser.tipo)
        } else {
            setName('')
            setEmail('')
            setUserType(null)
        }
    }, [selectedUser])

    const editUserHandler = async () => {
        setErrorMessage(null)
        setErrorMessageInput(null)
        if (name?.length < 5) {
            setErrorMessageInput('name')
            setErrorMessage('O nome deve conter pelo menos 5 caracteres')
            return
        }
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!selectedUser && !emailRegex.test(email.toLowerCase())) {
            console.log('Email is not Valid!!!');
            setErrorMessageInput('email')
            setErrorMessage('Por favor insira um endereço de e-mail válido!')
            return
        }
        if (!selectedUser && password?.length < 5) {
            setErrorMessageInput('password')
            setErrorMessage('A senha deve conter pelo menos 5 caracteres')
            return
        }
        if (userType==='') {
            setErrorMessageInput('userType')
            setErrorMessage('O tipo de usuário deve ser escolhido')
            return
        }
        setLoading(true)
        try {
            if(selectedUser) {
                await dispatch(userActions.updateUser(selectedUser.id, name, userType))
            } else {
                await dispatch(userActions.createUser(email, password, name, userType))
            }
        setLoading(false)
        } catch (err) {
            console.log(err)
        setLoading(false)
        }
    }

    console.log('user type: ', userType)

    return (
        <div className={classes.Screen}>
            <div className={classes.usersContainer}>
                <ButtonText
                    hide={!selectedUser}
                    title="Adicionar Usuário"
                    style={{ color: green, marginBottom: 20, position: 'absolute', right: 5, top: 5 }}
                    onClick={() => { setSelectedUser(null) }}
                >
                    <AddRoundedIcon style={{ color: Colors.primary, width: 16 }} />
                </ButtonText>
                <SearchBar
                    style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                    data={users}
                    setFixedData={(val) => { setSearchedItems(val) }}
                    searchedValue1='nome'
                    searchedValue2='email'
                />
                {searchedItems && searchedItems.map(item => (
                    <UserItem
                        key={item.id}
                        item={item} onClick={() => { setSelectedUser(item) }}
                        isSelected={item.id === selectedUser?.id}
                        client={users.find(u => u.id === item.clientId)}
                    />
                ))}
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.titleContainer}>
                    <AccountBoxRoundedIcon style={{ color: selectedUser?.tipo === "ADMIN" ? Colors.primary : Colors.quaternary }} />
                    <h4>{selectedUser ? selectedUser?.nome : 'Novo Usuário'}</h4>
                </div>
                <div className={classes.configurationContainer} >
                    <Input
                        label='Nome'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        shouldValidate
                        invalid={name.length < 3}
                        touched={errorMessageInput === 'name'}
                    />
                    <Input
                        label='E-Mail'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        elementConfig={{ disabled: selectedUser ? true : false }}
                        shouldValidate
                        invalid={email.length < 3}
                        touched={errorMessageInput === 'email'}
                    />
                    <Input
                        hide={selectedUser}
                        label='Senha'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        shouldValidate
                        invalid={password.length < 5}
                        touched={errorMessageInput === 'password'}
                    />
                    <Input
                        label='Tipo de Usuário'
                        value={userType}
                        onChange={(e) => setUserType(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{ options: userTypes}}
                        shouldValidate
                        invalid={userType===''}
                        touched={errorMessageInput === 'userType'}
                    />
                    <ButtonBox
                        style={{ marginTop: 10 }}
                        title={selectedUser ? 'Atualizar Usuário' : "Criar Usuário"}
                        loading={loading}
                        onClick={editUserHandler}
                    />
                    <div className={classes.error}>{errorMessage}</div>
                    {/* <ButtonBox
                        hide={!selectedUser}
                        style={{ marginTop: 10, width: 120 }}
                        title='Deletar Usuário'
                        loading={loading}
                        onClick={deleteUserHandler}
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default Users
