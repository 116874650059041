import styled, { css } from 'styled-components'
import Colors from '../../../constants/colors'

export const Container = styled.div<{active: boolean}>`
    width: 85%;
    margin-bottom: 5px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    &: hover {
        background-color: #f2f2f2;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
    }
    ${({active}) => active && activeContainerStyle}
`

const activeContainerStyle = css`
    background-color: #f2f2f2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
`

export const Image = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 25px;
    margin-right: 10px;
`

export const TitleContainer = styled.div`
    width: 300px;
    justify-content: center;
`

export const TimeContainer = styled.div`
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const Title = styled.p<{disabled: boolean}>`
    font-size: 13px;
    color: ${({disabled}) => disabled ? Colors.grey2 : Colors.sgray};
    font-weight: ${({disabled}) => disabled ? 'bold' : 'normal'};
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`