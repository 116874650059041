import React, { useState, useEffect } from 'react';
import classes from './Input.module.css';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import { IconButton } from '@material-ui/core';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

const Input = props => {
    const [type, setType] = useState('text')
    const [showPassword, setShowPassword] = useState(false)

    let inputElement = null;
    const inputClasses = [classes.InputElement];

    useEffect(() => {
        setType(props.isPassword)
    }, [props.isPassword])

    const handleKeyDown = (event) => {
        if (props.onSubmit && event.key === 'Enter') {
            props.onSubmit()
        }
    }

    const EyeComponent = showPassword ? VisibilityRoundedIcon : VisibilityOffRoundedIcon;

    const showPasswordHandler = () => {
        setShowPassword(!showPassword)
        setType(type === 'password' ? 'text' : 'password')
    }

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid);
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.onChange}
                type={type}
                onKeyDown={handleKeyDown}
            />;
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                style={props.style}
                id={classes.textarea}
                {...props.elementConfig}
                value={props.value}
                onKeyDown={handleKeyDown}
                onChange={props.onChange} />;
            break;
        case ('select'):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.onChange}
                    {...props.elementConfig}
                    defaultValue={undefined}
                >
                    <option
                        value=""
                    // disabled
                    // selected
                    >
                        {props.placeHolder ? props.placeHolder : 'Selecione uma opção'}
                    </option>
                    {props.elementConfig.options.map(option => (
                        <option key={option.id} value={getValue(option, props.selectedValue)}>
                            {getValue(option, props.selectedLabel)}
                        </option>
                    ))}
                </select>
            );
            break;
        case ('date'):
            inputElement =
                <DatePicker
                    className={inputClasses.join(' ')}
                    selected={props.value}
                    onChange={props.onChange}
                    locale="pt"
                />
            break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.onChange} />;
    }

    // if (props.label === 'Observação') {
    //     console.log("input: ", props.hide, typeof(props.hide))
    // }

    if (props.hide) {
        return <></>
    }

    if (props.loading) {
        return <Dots />
    }

    return (
        <div className={classes.Input}>
            <label className={classes.Label}>{props.label}</label>
            {inputElement}
            {props.isPassword &&
                <div className={classes.eye} >
                    <IconButton onClick={showPasswordHandler} style={{ margin: 0, padding: 0, position: 'absolute' }} >
                        <EyeComponent style={{ width: 15, margin: 0, padding: 0 }} />
                    </IconButton>
                </div>
            }
        </div>
    );

};

const getValue = (obj, path) => {
    return path.split(".").reduce(function (obj, name) {
        return obj[name]
    }, obj);
}

export default Input;
