import User from "../../models/User";
import environment, { auth } from '../../env/environment'

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USERS = 'SET_USERS';

export const fetchUsers = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/usuarios.json`);

            if (!response.ok) {
                const errorData = await response.json()
                const errorId = errorData.error
                throw new Error(errorId)
            }
            const resData = await response.json();
            const loadedUsers = [];
            for (const key in resData) {
                loadedUsers.unshift(
                    new User(
                        resData[key].id,
                        resData[key].email,
                        resData[key].nome,
                        resData[key].tipo,
                        resData[key].crecreatedAt,
                        resData[key].accessos,
                        resData[key].userPushToken,
                    )
                )
            }
            dispatch({
                type: SET_USERS,
                users: loadedUsers
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const createUser = (email, password, nome, tipo) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        const creationDate = new Date()

        try {
            // CREATE USER IN AUTHENTICATION TAB
            const res = await auth.createUserWithEmailAndPassword(email, password)
            console.log('User account created & signed in!');
            const newUserId = res.user.uid
            console.log(newUserId);
            // ADD USER TO USUARIOS TABLE
            const response = await fetch(`${environment.databaseURL}/usuarios/${newUserId}.json?auth=${token}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: newUserId,
                    email: email,
                    nome: nome,
                    tipo: tipo,
                    crecreatedAt: creationDate,
                })
            })
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error)
            }
            dispatch({
                type: CREATE_USER,
                userData: {
                    id: newUserId,
                    email: email,
                    nome: nome,
                    tipo: tipo,
                    crecreatedAt: creationDate,
                }
            })
        } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
                console.log('Esse endereço de email já esta em uso!');
                throw new Error('Esse endereço de email já esta em uso!');
            }
            if (error.code === 'auth/invalid-email') {
                console.log('Esse endereço de e-mail é inválido!');
                throw new Error('Esse endereço de e-mail é inválido!');
            }
            console.error(error);
        }
    }
};

export const updateUser = (id, nome, tipo) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token

        try {
            const response = await fetch(`${environment.databaseURL}/usuarios/${id}.json?auth=${token}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    nome: nome,
                    tipo: tipo,
                })
            })
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error)
            }
            dispatch({
                type: UPDATE_USER,
                uid: id,
                userData: {
                    nome: nome,
                    tipo: tipo,
                }
            })
        } catch (error) {
            throw new Error(error);
        }
    }
};

