import TextPdf from '../../models/TextPdf'
import environment, { db, storage } from '../../env/environment'
import firebase from 'firebase/'
import moment from 'moment';

export const SET_TEXT_PDFS = 'SET_TEXT_PDFS';
export const CREATE_TEXT_PDF = 'CREATE_TEXT_PDF';
export const UPDATE_TEXT_PDF = 'UPDATE_TEXT_PDF';
export const DELETE_TEXT_PDF = 'DELETE_TEXT_PDF';

export const GET_TEXTS_VERSION = 'GET_TEXTS_VERSION';
export const UPDATE_TEXTS_VERSION = 'UPDATE_TEXTS_VERSION';

export const CHANGE_ORDER_TEXTS = 'CHANGE_ORDER_TEXTS'

export const fetchTextPdfs = () => {
    return async (dispatch) => {
        try {
            let loadedTextPdfs = [];
            const response = await fetch(`${environment.databaseURL}/textos.json`);

            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const resData = await response.json();
            for (const listKey in resData) {
                for (const textKey in resData[listKey]) {
                    if (!listKey || !textKey) {
                        console.log('SOME KEY IS MISSING IN TEXT ACTIONS: ', listKey, textKey)
                    }
                    if (resData[listKey][textKey]) {
                        const metaTagsData = resData[listKey][textKey].metaTags
                        const loadedMetaTags = []
                        if (metaTagsData) {
                            for (const metaKey in metaTagsData) {
                                loadedMetaTags.push(metaTagsData[metaKey])
                            }
                        }
                        loadedTextPdfs.push(
                            new TextPdf(
                                resData[listKey][textKey].id,
                                listKey,
                                resData[listKey][textKey].caminhoFirebaseStorage,
                                resData[listKey][textKey].dataPublicacao,
                                resData[listKey][textKey].dou,
                                metaTagsData ? loadedMetaTags : null,
                                resData[listKey][textKey].observacao,
                                resData[listKey][textKey].origem,
                                resData[listKey][textKey].pagina,
                                resData[listKey][textKey].sessao,
                                resData[listKey][textKey].subModuloId,
                                resData[listKey][textKey].textoPago,
                                resData[listKey][textKey].title,
                                resData[listKey][textKey].versao,
                                textKey,
                                resData[listKey][textKey].image,
                                resData[listKey][textKey].type,
                            )
                        )
                    }
                }
            }

            dispatch({
                type: SET_TEXT_PDFS,
                textPdfs: loadedTextPdfs,
            })
            return loadedTextPdfs
        } catch (err) {
            throw new Error(err);
        }
    }
}

export const createTextPdf = (subModuloId, listaTextosId, title, description, origem, dou, file, sessao, pagina, dataPublicacao, versao, textoPago, metaTags, semAssociation, directTextId, image, type) => {
    return async (dispatch) => {
        const group = semAssociation ? 'textosSemAssociacao' : listaTextosId
        let lastExistingKey = -1
        const readRef = db.ref(`/textos/${group}`);
        readRef.on("value", snapshot => {
            const textos = snapshot.val();
            if (textos) {
                const keysArray = Object.keys(textos)
                lastExistingKey = parseInt(keysArray[keysArray.length - 1])
            }
        });
        //Genarate ID
        const postRef = db.ref(`/textos/${group}/${lastExistingKey + 1}`)
        const pushedPostRef = postRef.push();
        const generatedId = pushedPostRef.getKey();

        const fileName = directTextId ? directTextId : generatedId

        let imageUrl = null

        let storageRef = storage.ref()

        // let storageRef = storage.ref()
        let uploadTask = storageRef.child('textos/' + fileName + '.pdf').put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
            }, (error) => {
                throw error
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                    db.ref(`/textos/${group}/${lastExistingKey + 1}`).set({
                        id: fileName,
                        title,
                        observacao: description,
                        caminhoFirebaseStorage: `textos/${fileName}`,
                        versao: versao,
                        dataPublicacao: moment(dataPublicacao).format('DD/MM/YYYY'),
                        dou: dou,
                        origem: origem,
                        pagina: pagina,
                        sessao: sessao,
                        subModuloId: semAssociation ? 'textosSemAssociacao' : subModuloId,
                        textoPago: textoPago,
                        type: type,
                    })
                    if (metaTags) {
                        const metaArray = metaTags.split(' ')
                        console.log('META ARRAY: ', metaArray)
                        for (let index in metaArray) {
                            console.log(index, ': ', metaArray[index])
                            db.ref(`/textos/${group}/${lastExistingKey}/metaTags`).set({
                                [index]: metaArray[index],
                            })
                        }
                    }
                    if (image) {
                        let uploadImage = storageRef.child('text-images/' + fileName).put(image);
                        uploadImage.on(firebase.storage.TaskEvent.STATE_CHANGED,
                            (snapshot) => {
                            }, (error) => {
                                throw error
                            }, () => {
                                uploadImage.snapshot.ref.getDownloadURL().then(async (url) => {
                                    imageUrl = url
                                    db.ref(`/textos/${group}`).child(lastExistingKey).update({
                                        image: url
                                    })
                                })
                            }
                        )
                    }
                    dispatch({
                        type: CREATE_TEXT_PDF,
                        key: lastExistingKey + 1,
                        tid: fileName,
                        listaTextosId: group,
                        textData: {
                            id: fileName,
                            title,
                            observacao: description,
                            caminhoFirebaseStorage: `textos/${fileName}`,
                            versao: versao,
                            dataPublicacao: moment(dataPublicacao).format('DD/MM/YYYY'),
                            dou: dou,
                            origem: origem,
                            pagina: pagina,
                            sessao: sessao,
                            subModuloId: semAssociation ? 'textosSemAssociacao' : subModuloId,
                            textoPago: textoPago,
                            image: imageUrl,
                            type: type
                        }
                    })
                    return fileName
                })
            }
        )
        return fileName
    }
}

export const updateTextPdf = (id, key, subModuloId, listaTextosId, title, description, origem, dou, newFile, sessao, pagina, dataPublicacao, versao, textoPago, metaTags, newImage, type) => {
    return async (dispatch) => {

        let storageRef = storage.ref()

        if (newImage != null) {
            let uploadImage = storageRef.child('text-images/' + id).put(newImage);
            uploadImage.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                }, (error) => {
                    throw error
                }, () => {
                    uploadImage.snapshot.ref.getDownloadURL().then(async (url) => {
                        // imageUrl = url
                        db.ref(`/textos/${listaTextosId}`).child(key).update({
                            image: url
                        })
                    })
                }
            )
        }


        if (newFile != null) {
            let uploadTask = storageRef.child('textos/' + id + '.pdf').put(newFile);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                }, (error) => {
                    throw error
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                        console.log('URL: ', url)
                    })
                }
            )
        }
        db.ref(`/textos/${listaTextosId}`).child(key).update({
            // id: moduleId,
            title,
            observacao: description,
            versao: versao,
            dataPublicacao: moment(dataPublicacao).format('DD/MM/YYYY'),
            dou: dou,
            origem: origem,
            pagina: pagina,
            sessao: sessao,
            subModuloId: subModuloId,
            textoPago: textoPago,
            type: type,
        })
        if (metaTags) {
            const metaArray = metaTags.split(' ')
            console.log('META ARRAY: ', metaArray)
            for (let index in metaArray) {
                console.log(index, ': ', metaArray[index])
                db.ref(`/textos/${listaTextosId}/${key}/metaTags`).set({
                    [index]: metaArray[index],
                })
            }
        }
        dispatch({
            type: UPDATE_TEXT_PDF,
            key: key,
            tid: id,
            listaTextosId: listaTextosId,
            textData: {
                id: id,
                title,
                observacao: description,
                caminhoFirebaseStorage: `textos/${id}`,
                versao: versao,
                dataPublicacao: moment(dataPublicacao).format('DD/MM/YYYY'),
                dou: dou,
                origem: origem,
                pagina: pagina,
                sessao: sessao,
                subModuloId: subModuloId,
                textoPago: textoPago,
                metaTags: metaTags,
                type: type,
            }
        })
    }
}

export const deleteTextPdf = (listaId, id, key, isLastText, imageUrl) => {
    return async (dispatch) => {
        const deleteRef = db.ref(`/textos/${listaId}/${key}`);
        deleteRef.remove().then(async (res) => {
            console.log("Text deleted: ", res)
            dispatch({
                type: DELETE_TEXT_PDF,
                listaId: listaId,
                id: id,
                key: key
            });
            // DELETE PDF
            let pdfRef = storage.ref(`/textos/${id}.pdf`);
            pdfRef.delete().then(async () => {
                console.log("PDF deleted: ", res)
            })
            // DELETE IMAGE
            if (imageUrl) {
                let imageRef = storage.refFromURL(imageUrl);
                imageRef.delete().then(async () => {
                    console.log("image deleted: ", res)
                })
            }
            // REORDER TEXTS
            const response = await fetch(`${environment.databaseURL}/textos/${listaId}.json`);
            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const resData = await response.json();
            if (!resData) {
                return
            }
            for (const k in resData) {
                if (k > key) {
                    db.ref(`/textos/${listaId}`).child(k - 1).update({
                        id: resData[k].id,
                        caminhoFirebaseStorage: resData[k].caminhoFirebaseStorage,
                        dataPublicacao: resData[k].dataPublicacao,
                        dou: resData[k].dou,
                        observacao: resData[k].observacao,
                        origem: resData[k].origem,
                        pagina: resData[k].pagina,
                        sessao: resData[k].sessao,
                        subModuloId: resData[k].subModuloId,
                        textoPago: resData[k].textoPago,
                        title: resData[k].title,
                        versao: resData[k].versao,
                    })
                }
            }
            // DELETE THE LAST ITEM
            if (!isLastText) {
                const deleteLastRef = db.ref(`/textos/${listaId}/${resData.length - 1}`);
                deleteLastRef.remove().then(async (res) => {
                    console.log("Last Text deleted: ", res)
                })
            }
        }).catch((error) => {
            throw new Error(error)
        });
    }
}



export const changeTextsOrder = (listId, dragKey, dragItem, dropKey, dropItem) => {
    return async (dispatch) => {
        if (dragKey === dropKey) {
            console.log('ids: ', dragKey, dropKey)
            return
        }

        console.log('ids: ', dragKey, dropKey)

        db.ref(`/textos/${listId}`).child(dragKey).update({
            id: dropItem.id,
            title: dropItem.title,
            observacao: dropItem.observacao,
            caminhoFirebaseStorage: dropItem.caminhoFirebaseStorage,
            versao: dropItem.versao,
            dataPublicacao: dropItem.dataPublicacao,
            dou: dropItem.dou,
            origem: dropItem.origem,
            pagina: dropItem.pagina,
            sessao: dropItem.sessao,
            subModuloId: dropItem.subModuloId,
            textoPago: dropItem.textoPago,
            type: dropItem.type,
        })
        if (dropItem.image) {
            db.ref(`/textos/${listId}`).child(dragKey).update({
                image: dropItem.image
            })
        } else {
            db.ref(`/textos/${listId}`).child(dragKey).update({
                image: null
            })
        }
        db.ref(`/textos/${listId}`).child(dropKey).update({
            id: dragItem.id,
            title: dragItem.title,
            observacao: dragItem.observacao,
            caminhoFirebaseStorage: dragItem.caminhoFirebaseStorage,
            versao: dragItem.versao,
            dataPublicacao: dragItem.dataPublicacao,
            dou: dragItem.dou,
            origem: dragItem.origem,
            pagina: dragItem.pagina,
            sessao: dragItem.sessao,
            subModuloId: dragItem.subModuloId,
            textoPago: dragItem.textoPago,
            image: dragItem.image ? dragItem.image : null,
            type: dragItem.type
        })
        if (dragItem.image) {
            db.ref(`/textos/${listId}`).child(dropKey).update({
                image: dragItem.image
            })
        } else {
            db.ref(`/textos/${listId}`).child(dropKey).update({
                image: null
            })
        }

        console.log('order finished')

        dispatch({
            type: CHANGE_ORDER_TEXTS,
            listId: listId,
            dragKey: dragKey,
            dropKey: dropKey,
            dragItem: dragItem,
            dropItem: dropItem
        })
    }
}

export const getTextsVersion = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/version.json`);
            if (!response.ok) {
                throw new Error('Something went wrong')
            }
            const obj = await response.json();

            dispatch({
                type: GET_TEXTS_VERSION,
                version: obj.textsVersion,
            });
            return obj.textsVersion
        } catch (err) {
            throw new Error(err);
        }
    }
}

export const updateTextsVersion = (newVersion) => {
    return async (dispatch) => {
        db.ref(`/`).child('version').update({
            textsVersion: newVersion
        })
        dispatch({
            type: UPDATE_TEXTS_VERSION,
            version: newVersion,
        });
    }
}

export const uploadContentImage = (image) => {
    return async (dispatch) => {
        let storageRef = storage.ref()
        let uploadImage = storageRef.child('content/amcik5').put(image)
        uploadImage.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => {},
          error => {
            console.log('Errorrr: ', error)
            throw error
          },
          () => {
            uploadImage.snapshot.ref.getDownloadURL().then(async url => {
              console.log('Worked firebase: ', url)
              return url
            })
          }
        )
    }
}