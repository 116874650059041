import { auth } from "../../env/environment";
import environment from '../../env/environment'

export const SIGNIN = "SIGNIN"
export const AUTO_LOGIN = "AUTO_LOGIN"
export const SIGN_OUT = "SIGN_OUT"

export const signin = (email, password) => {
    return async dispatch => {

        let loginResponse = await auth.signInWithEmailAndPassword(email, password)

        if (loginResponse && loginResponse.user) {
            const userId = loginResponse.user.uid
            // const idTokenResult = await auth.currentUser.getIdTokenResult();
            // // console.log("ID TOKEN RESULT: ", idTokenResult);
            const userResponse = await fetch(`${environment.databaseURL}/usuarios/${userId}.json`)
            const user = await userResponse.json()
            if (user.tipo === "ADMIN" || user.tipo === "SUB_ADMIN") {
                dispatch({
                    type: SIGNIN,
                    user: user,
                })
            } else {
                await auth.signOut()
                dispatch({
                    type: SIGNIN,
                    user: null,
                })
                throw new Error("no-permission")
            }
        }
    }
}

export const autoLogin = () => {
    return async dispatch => {
        let userId = null
        auth.onAuthStateChanged(user => {
            if (!user) {
                dispatch({
                    type: AUTO_LOGIN,
                    user: null,
                })
                return
            }
            userId = user.uid

            fetch(`${environment.databaseURL}/usuarios/${userId}.json`)
                .then(userResponse => {
                    if (!userResponse.ok) {
                        throw new Error('Cannot fetch the user')
                    } else {
                        userResponse.json()
                            .then(currentUser => {
                                dispatch({
                                    type: AUTO_LOGIN,
                                    user: currentUser,
                                })
                            })
                    }
                })
        })
    }
}