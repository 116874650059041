import Module from '../../models/Module';
import SubModule from '../../models/SubModule';
import { SET_MODULES, CREATE_MODULE, UPDATE_MODULE, DELETE_MODULE, CREATE_SUBMODULE, UPDATE_SUBMODULE, DELETE_SUBMODULE, GET_MODULES_VERSION, UPDATE_MODULES_VERSION, CHANGE_ORDER_MODULES, CHANGE_ORDER_SUBMODULES } from '../actions/moduleActions';

const initialState = {
    availableModules: [],
    modulesVersion: 0,
    loaded: false
}

const modulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MODULES:
            return {
                ...state,
                availableModules: action.modules.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1),
                loaded: true
            }
        case CREATE_MODULE:
            const newModule = new Module(
                action.key,
                action.mid,
                action.moduleData.title,
                action.moduleData.observacao,
                action.moduleData.disabled,
                action.moduleData.invisivel,
                action.moduleData.versao,
                action.moduleData.image,
                action.moduleData.submodulos,
                action.moduleData.orderNumber,
                action.moduleData.useSpecialDescription,
            )
            return {
                ...state,
                availableModules: state.availableModules.concat(newModule),
            }
        case UPDATE_MODULE:
            const moduleIndex = state.availableModules.findIndex(mod => mod.id === action.mid);
            const updatedModule = new Module(
                action.key,
                action.mid,
                action.moduleData.title,
                action.moduleData.observacao,
                action.moduleData.disabled,
                action.moduleData.invisivel,
                action.moduleData.versao,
                action.moduleData.image ? action.moduleData.image : state.availableModules[moduleIndex].image,
                state.availableModules[moduleIndex].submodulos,
                action.moduleData.orderNumber,
                action.moduleData.useSpecialDescription
            )
            const updatedModules = [...state.availableModules];
            updatedModules[moduleIndex] = updatedModule;

            return {
                ...state,
                availableModules: updatedModules,
            }
        case DELETE_MODULE:
            return {
                ...state,
                availableModules: state.availableModules.filter(mod => mod.key != action.key),
            };
        case CREATE_SUBMODULE:
            const modIndex = state.availableModules.findIndex(mod => mod.key == action.moduleKey);
            const newSubModule = new SubModule(
                action.subKey,
                action.subModuleData.listaTextosId,
                action.subModuleData.idTextoASerExibido,
                action.subModuleData.title,
                action.subModuleData.observacao,
                action.subModuleData.disabled,
                action.subModuleData.invisivel,
                action.subModuleData.exibeDiretamenteTexto,
                action.subModuleData.image,
                action.subModuleData.url,
                false,
                action.subModuleData.versao
            )
            let updatedMod = { ...state.availableModules[modIndex] }
            if (!updatedMod.submodulos) {
                updatedMod.submodulos = []
                updatedMod.submodulos.push(newSubModule)
            } else {
                updatedMod.submodulos = [...updatedMod.submodulos, newSubModule]
            }

            const updatedMods = [...state.availableModules];
            updatedMods[modIndex] = updatedMod

            return {
                ...state,
                availableModules: updatedMods
            }
        // case UPDATE_SUBMODULE:
        //     const modInd = state.availableModules.findIndex(mod => mod.key == action.moduleKey);
        //     console.log('MD: ', action.moduleKey);
        //     const oldSubMod = state.availableModules[modInd].submodulos[action.subKey]
        //     // console.log("OLD ONE: ", oldSubMod)
        //     const updatedSubMod = new SubModule(
        //         action.subKey,
        //         oldSubMod.listaTextosId,
        //         oldSubMod.idTextoASerExibido,
        //         action.subModuleData.title,
        //         action.subModuleData.observacao,
        //         action.subModuleData.disabled,
        //         action.subModuleData.invisivel,
        //         oldSubMod.exibeDiretamenteTexto,
        //         action.subModuleData.image,
        //         oldSubMod.url,
        //         false,
        //         action.subModuleData.versao
        //     )
        //     const mods = [...state.availableModules]
        //     let currentMod = mods[action.moduleKey] 
        //     console.log('CR: ', mods)
        //     currentMod.submodulos[action.subKey] = updatedSubMod

        //     const updatedCurrentMods = [...state.availableModules];
        //     updatedCurrentMods[action.moduleKey] = currentMod
        //     return {
        //         ...state,
        //         availableModules: updatedCurrentMods
        //     }
        case DELETE_SUBMODULE:
            let filteredModule = { ...state.availableModules[action.moduleKey] }
            let filteredSubModules = [...filteredModule.submodulos]
            filteredSubModules = filteredSubModules.filter(sub => sub.id !== action.subKey)
            filteredModule.submodulos = filteredSubModules

            const allModules = [...state.availableModules]
            allModules[action.moduleKey] = filteredModule

            return {
                ...state,
                availableModules: allModules
            }
        case CHANGE_ORDER_SUBMODULES:
            // let reorderedModule = { ...state.availableModules[action.moduleKey] }

            // reorderedModule.submodulos[action.dragKey] = action?.dropItem
            // reorderedModule.submodulos[action.dragKey].id = action?.dragKey

            // reorderedModule.submodulos[action.dropKey] = action?.dragItem
            // reorderedModule.submodulos[action.dropKey].id = action?.dropKey

            // const newModules = [...state.availableModules];
            // newModules[action.moduleKey] = reorderedModule
            // return {
            //     ...state,
            //     availableModules: newModules
            // }
        case GET_MODULES_VERSION:
            return {
                ...state,
                modulesVersion: action.version
            }
        case UPDATE_MODULES_VERSION:
            return {
                ...state,
                modulesVersion: action.version
            }
        default:
            return state
    }
}

export default modulesReducer