class Module {
    constructor(key, id, title, observacao, disabled, invisivel, versao, image, submodulos, orderNumber, useSpecialDescription) {
        this.key = key
        this.id = id
        this.title = title
        this.observacao = observacao
        this.disabled = disabled
        this.invisivel = invisivel
        this.versao = versao
        this.image = image
        this.submodulos = submodulos
        this.orderNumber = orderNumber
        this.useSpecialDescription = useSpecialDescription
    }
}

export default Module