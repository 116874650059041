import React, { useEffect, useState } from "react";
import Colors from "../../../constants/colors";
import Button from "../../UI/Button";
import ButtonIcon from "../../UI/ButtonIcon";
import {
  AddressRow,
  AddressText,
  BannerImage,
  Box,
  ButtonRow,
  Loader,
  LoaderContainer,
  SwitchButtons,
  TopRow,
  Label,
  UrlButtons,
} from "./styles";
import { Props } from "./types";
import environment, { auth, storage } from "../../../env/environment";
import firebase from "firebase/";
import Switch from "../../UI/Switch";
import FileSelector from "../../UI/FileSelector";
import moment from "moment";
import TimeSetter from "../TimeSetter";
import Input from "../../UI/Input/Input";
import { validURL } from "../../../utils/helpers";

const BannerCard: React.FC<Props> = ({ item, onActionCompleted, route }) => {
  const [loading, setLoading] = useState(false);
  const [urlSaving, setUrlSaving] = useState(false);
  const [activatingPressable, setActivatingPressable] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [time, setTime] = useState(0);
  const [editingAddress, setEditingAddress] = useState(false);
  const [addressUrl, setAddressUrl] = useState("");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setTime(item.time || 1);
  }, []);

  const deleteHandler = async () => {
    setLoading(true);
    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    const token = idTokenResult?.token;

    // DELETE OLD FILE
    let desertRef = storage.refFromURL(item.url || "");
    desertRef
      .delete()
      .then(async () => {
        await fetch(
          `${environment.databaseURL}/terravivaBanners/${route}/${item.id}.json?auth=${token}`,
          {
            method: "DELETE",
          }
        );
        onActionCompleted();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const onUpdate = async (type: string) => {
    const setUpdating = (isUpdating: boolean) =>
      type === "disabled"
        ? setUrlSaving(isUpdating)
        : type === "pressable"
        ? setActivatingPressable(isUpdating)
        : setDisabling(isUpdating);

    const object =
      type === "disabled"
        ? { disabled: !item.disabled }
        : type === "pressable"
        ? { pressable: !item.pressable }
        : { address: addressUrl };
    try {
      setUpdating(true)
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      const token = idTokenResult?.token;
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners//${route}/${item.id}.json?auth=${token}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(object),
        }
      );
      onActionCompleted();
      setUpdating(false)
    } catch (error) {
      setUpdating(false)
      console.log(error);
    }
  };

  const onChangeImage = async (e: any) => {
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    setLoading(true);
    let date = new Date();
    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    const token = idTokenResult?.token;
    const newFileName =
      `${route}-banner-` + moment(date).format("YY-MM-DD-hh-mm-ss");
    let storageRef = storage.ref();

    let uploadTask = storageRef
      .child("terraviva-banners/" + newFileName)
      .put(image);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {},
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async (newUrl) => {
          // DELETE OLD FILE
          let desertRef = storage.refFromURL(item.url || "");
          desertRef
            .delete()
            .then(async () => {
              await fetch(
                `${environment.databaseURL}/terravivaBanners//${route}/${item.id}.json?auth=${token}`,
                {
                  method: "PATCH",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    url: newUrl,
                  }),
                }
              );
              onActionCompleted();
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        });
      }
    );
  };

  const updateTime = async (type: string) => {
    if (type === "down" && time <= 1) {
      return;
    }
    const newTime = type === "up" ? time + 1 : time - 1;
    try {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      const token = idTokenResult?.token;
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners//${route}/${item.id}.json?auth=${token}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            time: newTime,
          }),
        }
      );
      if (response.ok) {
        setTime(newTime);
      }
    } catch (error) {
      console.log("time error: ", error);
    }
  };

  const onPressAddressButton = () => {
    if (!editingAddress) {
      setEditingAddress(true);
      setAddressUrl(item.address);
      return;
    }
    setTouched(true);
    if (!validURL(addressUrl)) {
      return;
    }
    onUpdate("address");
    setTouched(false);
    setEditingAddress(false);
  };

  return (
    <Box>
      <TopRow>
        {loading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <BannerImage src={item.url} />
        )}
        <SwitchButtons>
          <ButtonRow>
            <Label>Ativo</Label>
            <Switch
              value={!item.disabled}
              onChange={onUpdate.bind(this, "disabled")}
            />
          </ButtonRow>
          <ButtonRow>
            <Label>Pressionável</Label>
            <Switch
              value={item.pressable}
              onChange={onUpdate.bind(this, "pressable")}
            />
          </ButtonRow>
          <ButtonRow>
            <FileSelector
              style={{ marginRight: 8 }}
              onChange={onChangeImage}
              label="Alterar Imagem"
            />
            <ButtonIcon name="trash" size={20} onClick={deleteHandler} />
          </ButtonRow>
        </SwitchButtons>
      </TopRow>
      <AddressRow>
        <Label>Endereço:</Label>
        <UrlButtons>
          {editingAddress && (
            <Button
              style={{ marginRight: 4 }}
              label="Cancelar"
              size="small"
              color={Colors.grey2}
              onClick={() => {
                setEditingAddress(false);
                setTouched(false);
              }}
            />
          )}
          <Button
            label={
              editingAddress ? "Salvar" : item.address ? "Alterar" : "Adicionar"
            }
            size="small"
            color={
              editingAddress
                ? Colors.success
                : item.address
                ? Colors.warning
                : Colors.quinary
            }
            onClick={onPressAddressButton}
            loading={urlSaving}
          />
        </UrlButtons>
      </AddressRow>
      {!editingAddress ? (
        <AddressText>{item.address}</AddressText>
      ) : (
        <Input
          value={addressUrl}
          onChange={(e: any) => setAddressUrl(e.target.value)}
          shouldValidate
          touched={touched}
          errorMessage={"Please enter a valid TC link"}
          invalid={!validURL(addressUrl) && touched}
        />
      )}
    </Box>
  );
};

export default BannerCard;
