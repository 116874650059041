import React, { useState, useCallback, useEffect } from 'react'
import Input from '../../components/UI/Input/Input'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import classes from './About.module.css'
import { useSelector, useDispatch } from 'react-redux'
import * as aboutActions from '../../store/actions/aboutActions'

const About = props => {
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [editMode, setEditMode] = useState(false)

    const dispatch = useDispatch()
    const about = useSelector(state => state.about.availableAbout)

    useEffect(() => {
        if (about)
            setText(about)
    }, [about]);

    const loadAbout = useCallback(async () => {
        setLoading(true)
        try {
            await dispatch(aboutActions.fetchAbout());
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, [dispatch]);

    useEffect(() => {
        loadAbout()
    }, [loadAbout]);

    const updateAboutHandler = async () => {
        setLoading(true)
        try {
            await dispatch(aboutActions.updateAbout(text));
            setLoading(false)
            setEditMode(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.titleContainer}>
                <h5>SOBRE</h5>
                <ButtonBox
                    hide={editMode}
                    title="Editar"
                    onClick={() => setEditMode(true)}
                />
            </div>
            {!editMode &&
                <div>
                    <p>{about}</p>
                </div>
            }
            {editMode &&
                <div className={classes.inputContainer} >
                    <Input
                        style={{height: 200}}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        elementType="textarea"
                        shouldValidate
                        invalid={text.length < 3}
                        touched={errorMessage}
                    />
                    <ButtonBox
                        title="Enviar"
                        loading={loading}
                        onClick={updateAboutHandler}
                    />
                </div>
            }
        </div>
    )
}

export default About
