const Colors = {
    // primary: 'rgb(131, 66, 147)',
    primary: '#2F4050',
    secondary: 'rgb(246, 88, 55)', 
    tertiary: 'rgb(254, 173, 87)',
    quaternary: 'rgb(36, 179, 89)',
    quinary: 'rgb(40, 38, 92)',

    success: '#5ac18e',
    warning: '#ffa500',
    danger: '#ff4040',

    primaryLight: 'rgba(90,193,142, 0.7)',

    sgray: 'rgb(56,60,73)',
    grey1: '#f5f5f5',
    grey2: '#dddddd',
    cream: '#f5f5dc',
    ivory: '#fffff0',
    whiteBlue: '#f8f8ff',
    backgroundColor: '#f8f8ff',
}

export default Colors