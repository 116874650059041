import firebase from 'firebase/'
import app from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyCMA_otWJFZze0m2k1hsJVM6XnD-4Oo7wY",
    authDomain: "xlei-af96a.firebaseapp.com",
    databaseURL: "https://xlei-af96a.firebaseio.com",
    projectId: "xlei-af96a",
    storageBucket: "xlei-af96a.appspot.com",
    messagingSenderId: "869764055710",
    appId: "1:869764055710:web:19c57c18c70d528fc5718f",
    measurementId: "G-9TJESEZQLJ"
};

app.initializeApp(firebaseConfig);

export const auth = firebase.auth()
// export const db = firebase.firestore()
export const db = firebase.database()
export const storage = firebase.storage()

export default firebaseConfig