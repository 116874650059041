import React, { useState, useCallback, useEffect } from 'react'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import classes from './EditLegal.module.css'
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import environment, { db } from '../../env/environment'
import { useLocation, useHistory } from 'react-router-dom';

const EditLegal = props => {
    const [loading, setLoading] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    
    const location = useLocation()
    const history = useHistory()
    const document = location?.state?.document

    const loadData = useCallback(async () => {
        setLoading(true)
        try {
            const response = await fetch(`${environment.databaseURL}/legal/${document}/content.json`);

            if (!response.ok) {
                throw new Error('Something went wrong with fetching about')
            }
            const loadedAbout = await response.json();


            const rawContentFromStore = convertFromRaw(JSON.parse(loadedAbout))
            setEditorState(() => EditorState.createWithContent(rawContentFromStore))
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        loadData()
    }, [loadData]);

    const updateDataHandler = async () => {
        setLoading(true)
        const content = convertToRaw(editorState.getCurrentContent());
        try {
            db.ref(`/legal`).child(document).update({ content: JSON.stringify(content) })
            history.push('/legal')
        } catch (err) {
            console.log('Error: ', err.message);
            setLoading(false)
        }
    }

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    const onItalicClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    }

    const onBoldClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    }

    const onUnderlineClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.buttonsContainer}>
                <div className={classes.textButtons}>
                    <div className={classes.textButton} onClick={onBoldClick} >NEGRITO</div>
                    <div className={classes.textButton} onClick={onItalicClick} >ITÁLICO</div>
                    <div className={classes.textButton} onClick={onUnderlineClick} >SUBLINHADO</div>
                </div>
                <ButtonBox
                    title="Atualizar"
                    onClick={updateDataHandler}
                />
            </div>
            <div className={classes.EditorContainer}>
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={handleKeyCommand}
                />
            </div>
        </div >
    )
}

export default EditLegal
