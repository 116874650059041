import Faq from "../../models/Faq";
import environment from '../../env/environment'
import { auth } from "../../env/environment";

export const SET_FAQS = 'SET_FAQS';
export const DELETE_FAQ = 'DELETE_FAQ';
export const CREATE_FAQ = 'CREATE_FAQ';
export const UPDATE_FAQ = 'UPDATE_FAQ';

export const fetchFaqs = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/faqs.json`);

            if (!response.ok) {
                throw new Error('Something went wrong with fetching FAQs')
            }
            const resData = await response.json();
            const loadedFaqs = [];
            for (const key in resData) {
                loadedFaqs.push(
                    new Faq(
                        key,
                        resData[key].title,
                        resData[key].description,
                    )
                )
            }
            dispatch({
                type: SET_FAQS,
                faqs: loadedFaqs,
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const deleteFaq = (id) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        const response = await fetch(
            `${environment.databaseURL}/faqs/${id}.json?auth=${token}`,
            {
                method: 'DELETE'
            });
        if (!response.ok) {
            throw new Error('Something went wrong!')
        }
        dispatch({
            type: DELETE_FAQ,
            fid: id
        });
    };
};

export const createFaq = (title, description) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token

        const response = await fetch(`${environment.databaseURL}/faqs.json?auth=${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                title,
                description
            })
        }
        )
        if (!response.ok) {
            const errorData = await response.json()
            throw new Error(errorData.error)
        }
        const resData = await response.json();

        dispatch({
            type: CREATE_FAQ,
            faqData: {
                id: resData.name,
                title,
                description
            }
        })
    }
};

export const updateFaq = (id, title, description) => {
    return async (dispatch) => {
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token

        try {
            const response = await fetch(`${environment.databaseURL}/faqs/${id}.json?auth=${token}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: title,
                    description: description,
                })
            })
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error)
            }
            dispatch({
                type: UPDATE_FAQ,
                fid: id,
                faqData: {
                    title: title,
                    description: description,
                }
            })
        } catch (error) {
            throw new Error(error);
        }
    }
};
