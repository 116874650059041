class TextPdf {
    constructor(id, listaTextosId, caminhoFirebaseStorage, dataPublicacao, dou, metaTags, observacao, origem, pagina, sessao, subModuloId, textoPago, title, versao, key, image, type, contentId) {
        this.id = id
        this.listaTextosId = listaTextosId
        this.caminhoFirebaseStorage = caminhoFirebaseStorage
        this.dataPublicacao = dataPublicacao
        this.dou = dou
        this.metaTags = metaTags
        this.observacao = observacao
        this.origem = origem
        this.pagina = pagina
        this.sessao = sessao
        this.subModuloId = subModuloId
        this.textoPago = textoPago
        this.title = title
        this.versao = versao
        this.key = key
        this.image = image
        this.type = type
        this.contentId = contentId
    }
}

export default TextPdf