import React, { useState, useEffect } from 'react'
import classes from './EditSubModule.module.css'
import { useDispatch, useSelector } from 'react-redux'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import Input from '../../components/UI/Input/Input'
import "react-activity/dist/Dots.css";
import { useLocation } from 'react-router-dom';
import * as moduleActions from '../../store/actions/moduleActions'
import defaultImage from '../../assets/default_image.jpg'
import { useHistory } from 'react-router-dom'

const EditSubModulePage = props => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [succeed, setSucceed] = useState(false)

    const [title, setTitle] = useState('')
    const [observacao, setObservacao] = useState('')
    const [invisivel, setInvisivel] = useState('')
    const [disabled, setDisabled] = useState('')
    const [version, setVersion] = useState(1)
    const [image, setImage] = useState(null)
    const [imagePath, setImagePath] = useState(null)
    const [exibeDiretamenteTexto, setExibeDiretamenteTexto] = useState('')
    const [modulesVersion, setModulesVersion] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const currentSubModule = location?.state?.subModule
    const moduleKey = location?.state?.moduleKey

    // const modulesVersion = useSelector(state => state.modules.modulesVersion)

    useEffect(() => {
        fetchModulesVersion()
        if (currentSubModule) {
            console.log("SUB-KEY: ", currentSubModule.id)
            setTitle(currentSubModule.title)
            setInvisivel(currentSubModule.invisivel)
            setDisabled(currentSubModule.disabled)
            setExibeDiretamenteTexto(currentSubModule.exibeDiretamenteTexto)
            if (currentSubModule.versao === undefined) {
                console.log("versao: ", currentSubModule.versao)
                setVersion(2)
            } else {
                setVersion(currentSubModule.versao + 1)
            }
            setObservacao(currentSubModule.observacao)
            setImagePath(currentSubModule.image)
        } else {
            setDisabled(true)
        }
    }, [currentSubModule])

    const fetchModulesVersion = async () => {
        try {
            const modVer = await dispatch(moduleActions.getModulesVersion());
            setModulesVersion(modVer)
        } catch (err) {
            console.log('error getting modules version: ', err)
        }
    }

    const createSubModuleHandler = async () => {
        setErrorMessage(null)
        if (!imagePath) {
            setErrorMessage("Selecione uma imagem.")
            return
        }
        if (title?.length < 3) {
            setErrorMessage("O título não pode ter menos de 3 caracteres!")
            return
        }
        if (invisivel?.length < 1) {
            setErrorMessage("Selecione o estado invisível!")
            return
        }
        if (disabled?.length < 1) {
            setErrorMessage("Selecione o estado desativado!")
            return
        }
        if (exibeDiretamenteTexto?.length < 1) {
            setErrorMessage("Selecione o estado 'Exibir texto diretamente'")
            return
        }
        if (observacao?.length < 3) {
            setErrorMessage("A observação não pode ter menos de 3 caracteres!")
            return
        }
        setButtonLoading(true)
        try {
            if (currentSubModule) {
                await dispatch(moduleActions.updateSubModule(currentSubModule.id, moduleKey, title, observacao, invisivel === 'true' ? true : false, disabled === 'true' ? true : false, version, image, currentSubModule.listaTextosId, currentSubModule.image))
                await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
            } else {
                await dispatch(moduleActions.createSubModule(moduleKey, title, observacao, invisivel === 'true' ? true : false, disabled === 'true' ? true : false, version, image, exibeDiretamenteTexto === 'true' ? true : false))
                await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
            }
            setButtonLoading(false)
            setSucceed(true)
            // history.goBack()
            // history.push('/modules')
        } catch (err) {
            console.log('Error: ', err);
            setButtonLoading(false)
        }
    }

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0])
            setImagePath(URL.createObjectURL(e.target.files[0]))
        }
    }

    if (succeed) {
        return (
            <div className={classes.SuccessMessage}>
                <div>O submódulo adicionado com sucesso!</div>
                <ButtonBox
                    type="success"
                    style={{ marginTop: 10, alignSelf: 'center' }}
                    title='Voltar para módulos'
                    onClick={() => history.push('/', { moduleId: moduleKey, subModuleId: currentSubModule?.id || 'last'  })}
                />
            </div>
        )
    }

    return (
        <div className={classes.Screen}>
            <p className={classes.title}>{currentSubModule ? 'EDITAR SUB-MÓDULO - ' + currentSubModule.title : "NOVO SUB-MÓDULO"}</p>
            <div className={classes.formContainer}>
                <div className={classes.left}>
                    <Input
                        label='Título'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        shouldValidate
                        invalid={title.length < 3}
                        touched={errorMessage}
                    />
                    <Input
                        label='Invisível'
                        value={invisivel}
                        onChange={(e) => setInvisivel(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{ options: [{ id: 1, label: 'SIM', value: true }, { id: 2, label: 'NAO', value: false }] }}
                        shouldValidate
                        invalid={invisivel === ''}
                        touched={errorMessage}
                    />
                    <Input
                        label='Desabilitado'
                        value={disabled}
                        onChange={(e) => setDisabled(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{
                            options: [{ id: 1, label: 'SIM', value: true }, { id: 2, label: 'NAO', value: false }]
                        }}
                        shouldValidate
                        invalid={disabled === ''}
                        touched={errorMessage}
                    />
                    <Input
                        label='Exibir Diretamente Texto?'
                        value={exibeDiretamenteTexto}
                        onChange={(e) => setExibeDiretamenteTexto(e.target.value)}
                        elementType="select"
                        selectedLabel="label"
                        selectedValue="value"
                        elementConfig={{
                            options: [{ id: 1, label: 'SIM', value: true }, { id: 2, label: 'NAO', value: false }],
                            disabled: currentSubModule ? true : false
                        }}
                        shouldValidate
                        invalid={exibeDiretamenteTexto === ''}
                        touched={errorMessage}
                    />
                    <Input
                        label='Versão'
                        value={version}
                        onChange={(e) => setVersion(e.target.value)}
                        elementConfig={{ disabled: true }}
                    />
                </div>

                <div className={classes.right}>

                    <Input
                        className={classes.desc}
                        label='Observação'
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                        elementType="textarea"
                        shouldValidate
                        invalid={observacao.length < 3}
                        touched={errorMessage}
                    />
                    <div className={classes.imageContainer} >
                        <img className={classes.image} src={imagePath ? imagePath : defaultImage} alt={title} />
                    </div>

                    <div className={classes.fileContainer}>
                        <p className={classes.imageName}>{image ? image.name : currentSubModule?.image ? currentSubModule.image : 'Nenhuma imagem selecionada'}</p>
                        <div className={classes.fileSelector}>
                            <label htmlFor="upload-photo">Escolha a Imagem</label>
                            <input className={classes.fileInput} type="file" id="file" accept=".png" onChange={handleImageChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <ButtonBox
                    hide={buttonLoading}
                    style={{ marginTop: 10 }}
                    title={currentSubModule ? 'Atualizar' : 'Adicionar'}
                    loading={buttonLoading}
                    onClick={createSubModuleHandler}
                />
            </div>
            <div className={classes.error}>{errorMessage}</div>
        </div>
    )
}

export default EditSubModulePage