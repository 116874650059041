import styled from "styled-components";
import Colors from "../../constants/colors";

export const Container = styled.div`
    display: flex;
    padding: 16px;
    height: 95vh;
`
export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`

export const Title = styled.p`
    font-weight: bold;
    font-size: 20px;
    color: ${Colors.sgray};
`

export const Column = styled.div`
    width: 300px;
    height: 100vh;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 30px 10px 40px 10px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-right: 16px;
`

export const BannerImage = styled.img`
    width: 100%;
`

export const Label = styled.label`
    font-size: 14px;
    color: ${Colors.sgray};
`

export const AddressRow = styled.div`
    width: calc(100% - 8px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
`

export const ButtonRow = styled.div`
    width: calc(100% - 8px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const AddressText = styled.label`
    font-size: 14px;
    color: ${Colors.sgray};
    font-weight: bold;
    margin-top: 4px;
`

export const UrlButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`