import { AUTO_LOGIN, SIGNIN } from '../actions/authActions';

const initialState = {
    user: null,
    triedAutoLogin: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGNIN:
            return {
                ...state,
                user: action.user,
                triedAutoLogin: true
            }
        case AUTO_LOGIN:
            return {
                ...state,
                user: action.user,
                triedAutoLogin: true
            }
        default:
            return state
    }
}

export default authReducer