import React from 'react'
import classes from './ButtonBox.module.css'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

const Button = props => {

    if (props.loading) {
        return (
            <div
                className={`${classes.container} ${props.className}`}
                style={{
                    ...props.style,
                    backgroundColor: props.color,
                }}
            ><Dots />
            </div>
        )
    }

    if (props.hide) {
        return <></>
    }

    return (
        <div
            className={`${classes.container} ${props.className}`}
            style={{
                ...props.style,
                backgroundColor: props.color,
            }}
            onClick={props.onClick}
        >
            <p className={classes.title} >{props.title}</p>
        </div>
    )
}

export default Button