import styled, { keyframes } from "styled-components";
import Colors from "../../../constants/colors";

export const Container = styled.div<{color: string, size: 'large' | 'medium' | 'small'}>`
    width: fit-content;
    display: flex;
    padding: ${({size}) => size === 'small' ? '4px 8px' : size === 'medium' ? '6px 12px' : '8px 16px'};
    background-color: ${({color}) => color};
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`

export const Label = styled.label<{size: 'large' | 'medium' | 'small'}>`
    font-weight: bold;
    font-size: ${({size}) => size === 'small' ? '10' : size === 'medium' ? '12' : '14'}px;
    margin-right: 4px;
    padding: 0;
    color: 'white';
`

export const animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const Loader = styled.div`
  width: 6px;
  height: 6px;
  border: 6px solid ${Colors.primary};
  border-top: 6px solid ${Colors.cream}; 
  border-radius: 50%;
  animation-name: ${animation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`