import React from 'react'
import classes from './UserItem.module.css'

const UserItem = props => {

    const item = props.item

    return (
        <div
            className={classes.container}
            id={props.isSelected ? classes.active : null}
            onClick={props.onClick}
        >
            {item.nome && <div className={classes.imageCircle} id={item.tipo === "ADMIN" ? classes.adminCircle : null} >{!item.nome.includes(' ') ? item.nome.split(" ")[0].charAt(0) : item.nome.split(" ")[0].charAt(0) + item.nome.split(" ")[1].charAt(0)}</div>}
            <div className={classes.titleContainer}>
                <p id={classes.oneLine}><b>{item.nome}</b></p>
                <p id={classes.oneLine}>{item.email}</p>
            </div>
            <div className={classes.tipoContainer}>
                {/* <p className={classes.tipo}>{item.tipo === "ADMIN" ? 'Admin' : ''}</p> */}
            </div>
        </div>
    )
}

export default UserItem