import { CREATE_AD, DELETE_AD, SET_ADS, UPDATE_AD } from '../actions/advertisementActions';
import Advertisement from '../../models/Advertisement';

const initialState = {
    allAds: [],
    loaded: false
}

const advertisementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADS:
            return {
                ...state,
                allAds: action.ads,
                loaded: true
            }
        case CREATE_AD:
            const newAd = new Advertisement(
                action.adData.id,
                action.adData.ativo,
                action.adData.nome,
                action.adData.observacao,
                action.adData.enderecoDaImagem,
                action.adData.link,
            )

            let newAdds = state.allAds
            newAdds = newAdds.reverse()
            newAdds = newAdds.concat(newAd)
            newAdds = newAdds.reverse()
            return {
                ...state,
                allAds: newAdds,
            }
        case DELETE_AD:
            return {
                ...state,
                allAds: state.allAds.filter(ad => ad.id !== action.aid),
            };
        case UPDATE_AD:
            const adIndex = state.allAds.findIndex(ad => ad.id === action.aid);
            const updateAd = new Advertisement(
                action.aid,
                action.adData.ativo,
                action.adData.nome,
                action.adData.observacao,
                action.adData.enderecoDaImagem,
                action.adData.link,
            )
            const updatedAllAds = [...state.allAds];
            updatedAllAds[adIndex] = updateAd;

            return {
                ...state,
                allAds: updatedAllAds,
            }
        default:
            return state
    }
}

export default advertisementsReducer