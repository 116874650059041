import { SET_SUPPORTS, ANSWER_SUPPORT } from '../actions/supportActions';
import Support from '../../models/Support';

const initialState = {
    availableSupports: [],
    loaded: false
}

const supportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUPPORTS:
            return {
                ...state,
                availableSupports: action.supports,
                loaded: true
            }
        case ANSWER_SUPPORT:
            const supporIndex = state.availableSupports.findIndex(sup => sup.id === action.sid);
            const answeredSupport = new Support(
                action.sid,
                state.availableSupports[supporIndex].relatedText,
                state.availableSupports[supporIndex].question,
                action.supportData.answer,
                state.availableSupports[supporIndex].clientId,
                action.supportData.adminId,
                state.availableSupports[supporIndex].createdAt,
                action.supportData.updatedAt,
                state.availableSupports[supporIndex].pushToken,
            )
            const updatedSupports = [...state.availableSupports];
            updatedSupports[supporIndex] = answeredSupport;

            return {
                ...state,
                availableSupports: updatedSupports,
            }
        default:
             return state
    }
}

export default supportsReducer