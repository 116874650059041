import React from 'react'
import classes from './Alert.module.css'

const Alert = props => {

    return (
        <div style={props.style}>
            {props.visible &&
                <div className={classes.container} >
                    <div className={classes.info} >{props.message}</div>
                    <div className={classes.buttonsContainer}>
                        <div className={classes.cancel} onClick={props.onClickCancel}>Cancel</div>
                        <div className={classes.delete} onClick={props.onClickButton}>{props.buttonName ? props.buttonName : 'Delete'}</div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Alert