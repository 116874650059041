import moment from "moment";
import React, { useEffect, useState } from "react";
import Button from "../../components/UI/Button";
import Checkbox from "../../components/UI/Checkbox";
import Colors from "../../constants/colors";
import environment, { auth, storage } from "../../env/environment";
import firebase from "firebase/";
import {
  BannerImage,
  Container,
  Column,
  Title,
  TitleContainer,
  Label,
  UrlButtons,
  AddressRow,
  AddressText,
  ButtonRow,
} from "./styles";
import { PrimaryBannerType } from "./types";
import BannerCard from "../../components/items/BannerCard";
import FileSelector from "../../components/UI/FileSelector";
import Switch from "../../components/UI/Switch";
import Input from "../../components/UI/Input/Input";
import { validURL } from "../../utils/helpers";
import Spinner from "../../components/UI/Spinner";

const TerraVivaBanners: React.FC = () => {
  const [primaryBanner, setPrimaryBanner] = useState<PrimaryBannerType | null>(
    null
  );
  const [masterBanners, setMasterBanners] = useState<any[]>([]);
  const [newsBanners, setNewsBanners] = useState<any[]>([]);
  const [statisticBanners, setStatisticBanners] = useState<any[]>([]);
  const [editingAddress, setEditingAddress] = useState(false);
  const [addressUrl, setAddressUrl] = useState<any>("");
  const [touched, setTouched] = useState(false);
  const [urlSaving, setUrlSaving] = useState(false);
  const [imageSaving, setImageSaving] = useState(false);

  useEffect(() => {
    // getPrimaryBanner();
    getMasterBanners();
    getNewsBanners();
    getStatisticBanners();
  }, []);

  const getPrimaryBanner = async () => {
    try {
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners/primary.json`
      );
      const resData = await response.json();
      setPrimaryBanner(resData);
    } catch (error) {
      console.log(error);
    }
  };

  const getMasterBanners = async () => {
    try {
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners/master.json`
      );
      const resData = await response.json();
      const loadedBanners = [];
      for (const key in resData) {
        loadedBanners.unshift({
          id: key,
          disabled: resData[key].disabled,
          url: resData[key].url,
          time: resData[key].time,
          pressable: resData[key].pressable,
          address: resData[key].address,
        });
      }
      setMasterBanners(loadedBanners);
    } catch (error) {
      console.log(error);
    }
  };

  const getNewsBanners = async () => {
    try {
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners/news.json`
      );
      const resData = await response.json();
      const loadedBanners = [];
      for (const key in resData) {
        loadedBanners.unshift({
          id: key,
          disabled: resData[key].disabled,
          url: resData[key].url,
          time: resData[key].time,
          pressable: resData[key].pressable,
          address: resData[key].address,
        });
      }
      setNewsBanners(loadedBanners);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatisticBanners = async () => {
    try {
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners/statistics.json`
      );
      const resData = await response.json();
      const loadedBanners = [];
      for (const key in resData) {
        loadedBanners.unshift({
          id: key,
          disabled: resData[key].disabled,
          url: resData[key].url,
          time: resData[key].time,
          pressable: resData[key].pressable,
          address: resData[key].address,
        });
      }
      setStatisticBanners(loadedBanners);
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdatePrimary = async (type: string) => {
    const object =
      type === "disabled"
        ? { disabled: !primaryBanner?.disabled }
        : type === "pressable"
        ? { pressable: !primaryBanner?.pressable }
        : { address: addressUrl };
    try {
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      const token = idTokenResult?.token;
      const response = await fetch(
        `${environment.databaseURL}/terravivaBanners/primary.json?auth=${token}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(object),
        }
      );
      if (response.ok) {
        getPrimaryBanner();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePrimaryImage = async (e: any) => {
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    try {
      setImageSaving(true);
      let date = new Date();
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      const token = idTokenResult?.token;
      const newFileName =
        "terra-viva-primary-banner-" + moment(date).format("YY-MM-DD-hh-mm-ss");
      let storageRef = storage.ref();

      let uploadTask = storageRef
        .child("terraviva-banners/" + newFileName)
        .put(image);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (error) => {
          throw error;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (newUrl) => {
            // DELETE OLD FILE
            let desertRef = storage.refFromURL(primaryBanner?.url || "");
            desertRef
              .delete()
              .then(async () => {
                await fetch(
                  `${environment.databaseURL}/terravivaBanners/primary.json?auth=${token}`,
                  {
                    method: "PATCH",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      url: newUrl,
                    }),
                  }
                );
                getPrimaryBanner();
                setImageSaving(false);
              })
              .catch((error) => {
                setImageSaving(false);
                throw new Error(error);
              });
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addBanner = async (e: any, route: string) => {
    const image = e.target.files[0];
    if (!image) {
      return;
    }
    try {
      let date = new Date();
      const idTokenResult = await auth.currentUser?.getIdTokenResult();
      const token = idTokenResult?.token;
      const newFileName =
        `${route}-banner-` + moment(date).format("YY-MM-DD-hh-mm-ss");
      let storageRef = storage.ref();

      let uploadTask = storageRef
        .child("terraviva-banners/" + newFileName)
        .put(image);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {},
        (error) => {
          throw error;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (newUrl) => {
            await fetch(
              `${environment.databaseURL}/terravivaBanners/${route}.json?auth=${token}`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  id: newFileName,
                  url: newUrl,
                  disabled: false,
                  time: 1,
                }),
              }
            );
            if (route === "master") {
              getMasterBanners();
            } else if (route === "news") {
            } else {
              getStatisticBanners();
            }
          });
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onPressAddressButton = () => {
    if (!editingAddress) {
      setEditingAddress(true);
      setAddressUrl(primaryBanner?.address);
      return;
    }
    setTouched(true);
    if (!validURL(addressUrl)) {
      return;
    }
    onUpdatePrimary("address");
    setTouched(false);
    setEditingAddress(false);
  };

  return (
    <Container>
      {/* <Column>
        <TitleContainer>
          <Title>Primary Banner</Title>
          <FileSelector
            label="Alterar imagem"
            onChange={onChangePrimaryImage}
          />
        </TitleContainer>
        {primaryBanner && (
          <>
            {imageSaving ? (
              <Spinner size={8} containerWidth={300} containerHeight={600} />
            ) : (
              <BannerImage src={primaryBanner.url} />
            )}
          </>
        )}
        {!imageSaving && (
          <>
            <ButtonRow>
              <Label>Ativado</Label>
              <Switch
                style={{ marginTop: 8 }}
                value={primaryBanner?.disabled ? false : true}
                onChange={onUpdatePrimary.bind(this, "disabled")}
              />
            </ButtonRow>
            <ButtonRow>
              <Label>Pressionável</Label>
              <Switch
                style={{ marginTop: 8 }}
                value={primaryBanner?.pressable || false}
                onChange={onUpdatePrimary.bind(this, "pressable")}
              />
            </ButtonRow>
            <AddressRow>
              <Label>Endereço:</Label>
              <UrlButtons>
                {editingAddress && (
                  <Button
                    style={{ marginRight: 4 }}
                    label="Cancelar"
                    size="small"
                    color={Colors.grey2}
                    onClick={() => {
                      setEditingAddress(false);
                      setTouched(false);
                    }}
                  />
                )}
                <Button
                  label={
                    editingAddress
                      ? "Salvar"
                      : primaryBanner?.address
                      ? "Alterar"
                      : "Adicionar"
                  }
                  size="small"
                  color={
                    editingAddress
                      ? Colors.success
                      : primaryBanner?.address
                      ? Colors.warning
                      : Colors.quinary
                  }
                  onClick={onPressAddressButton}
                  loading={urlSaving}
                />
              </UrlButtons>
            </AddressRow>
            {!editingAddress ? (
              <AddressText>{primaryBanner?.address}</AddressText>
            ) : (
              <Input
                value={addressUrl}
                onChange={(e: any) => setAddressUrl(e.target.value)}
                shouldValidate
                touched={touched}
                errorMessage={"Please enter a valid TC link"}
                invalid={!validURL(addressUrl) && touched}
              />
            )}
          </>
        )}
      </Column> */}

      <Column>
        <TitleContainer>
          <Title>MASTER</Title>
          <FileSelector
            label="Novo banner"
            onChange={(e) => addBanner(e, "master")}
          />
        </TitleContainer>
        {masterBanners.map((item) => (
          <BannerCard
            key={item.id}
            item={item}
            onActionCompleted={getMasterBanners}
            route="master"
          />
        ))}
      </Column>

      <Column>
        <TitleContainer>
          <Title>Noticias</Title>
          <FileSelector
            label="Novo banner"
            onChange={(e) => addBanner(e, "news")}
          />
        </TitleContainer>
        {newsBanners.map((item) => (
          <BannerCard
            key={item.id}
            item={item}
            onActionCompleted={getNewsBanners}
            route="news"
          />
        ))}
      </Column>

      <Column>
        <TitleContainer>
          <Title>Estatisticas</Title>
          <FileSelector
            label="Novo banner"
            onChange={(e) => addBanner(e, "statistics")}
          />
        </TitleContainer>
        {statisticBanners.map((item) => (
          <BannerCard
            key={item.id}
            item={item}
            onActionCompleted={getStatisticBanners}
            route="statistics"
          />
        ))}
      </Column>
    </Container>
  );
};

export default TerraVivaBanners;
