import styled, { keyframes } from "styled-components"
import Colors from "../../../constants/colors"

export const Container = styled.div``

export const Icon = styled.img<{size?: number}>`
    width: ${({size}) => size || 30}px;
    height: auto;
`

export const animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

export const Loader = styled.div`
  width: 8px;
  height: 8px;
  border: 8px solid ${Colors.primary};
  border-top: 8px solid ${Colors.cream}; 
  border-radius: 50%;
  animation-name: ${animation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`