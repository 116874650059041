import React, { useEffect, useCallback, useState } from 'react'
import classes from './Modules.module.css'
import { useDispatch } from 'react-redux'
import * as moduleActions from '../../store/actions/moduleActions'
import * as textPdfActions from '../../store/actions/textPdfActions'
import ModuleRow from '../../components/items/ModuleRow/ModuleRow'
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import Colors from '../../constants/colors'
import TextItem from '../../components/items/TextItem/TextItem'
import ButtonBoxSmall from '../../components/UI/ButtonBoxSmall/ButtonBoxSmall'
import ButtonText from '../../components/UI/ButtonText/ButtonText'
import { useHistory } from 'react-router-dom'
import Alert from '../../components/UI/Alert/Alert'
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import SubModuleLine from '../../components/items/SubModuleLine/SubModuleLine'
import SearchBar from '../../components/UI/SearchBar/SearchBar';

const Modules = props => {
    const [loading, setLoading] = useState(false)
    const [moduleKey, setModuleKey] = useState(null)
    const [selectedModule, setSelectedModule] = useState(null)
    const [selectedSubModule, setSelectedSubModule] = useState(null)
    const [selectedText, setSelectedText] = useState(null)
    const [alertVisible, setAlertVisible] = useState(false)
    const [alertType, setAlertType] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)
    const [extended, setExtended] = useState(false)
    const [dragSubItem, setDragSubItem] = useState(null)
    const [dragTextItem, setDragTextItem] = useState(null)
    const [dragModItem, setDragModItem] = useState(null)
    const [searchedTexts, setSearchedTexts] = useState([])
    const [subDropping, setSubDropping] = useState(false)

    const [modules, setModules] = useState([])
    const [loadedTexts, setLoadedTexts] = useState([])
    const [semTexts, setSemTexts] = useState([])
    const [modulesVersion, setModulesVersion] = useState(null)
    const [textsVersion, setTextsVersion] = useState(null)

    const dispatch = useDispatch()
    const history = useHistory()

    const loadDatas = useCallback(async () => {
        setLoading(true)
        try {
            const mods = await dispatch(moduleActions.fetchModules());
            setModules(mods)

            const texts = await dispatch(textPdfActions.fetchTextPdfs());
            setLoadedTexts(texts)
            let rTexts = texts.filter(t => t.listaTextosId === 'textosSemAssociacao')
            let reversedSemTexts = rTexts.reverse()
            setSemTexts(reversedSemTexts)

            const modVer = await dispatch(moduleActions.getModulesVersion());
            setModulesVersion(modVer)

            const texVer = await dispatch(textPdfActions.getTextsVersion())
            setTextsVersion(texVer)

            setLoading(false)
        } catch (err) {
            console.log('Errorr: ', err.message);
            setLoading(false)
        }
    }, [dispatch]);

    useState(() => {
        if (modules?.length > 0) {
            setSelectedModule(modules[0])
            console.log('module selected')
        }
    }, [])

    useEffect(() => {
        let controller = new AbortController();
        loadDatas()
        console.log('EVENTTTT')
        return () => controller?.abort();
    }, [loadDatas]);

    useEffect(() => {
        if (selectedSubModule?.listaTextosId === 'textosSemAssociacao' && semTexts.length > 0) {
            setSearchedTexts(semTexts)
        } else {
            setSearchedTexts(loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId))
        }
    }, [semTexts.length, loadedTexts, selectedSubModule])

    const onClickModule = async (mod) => {
        const res = await dispatch(textPdfActions.fetchTextPdfs())
        setLoadedTexts(res)
        setModuleKey(mod.key)
        setSelectedModule(mod)
        setSelectedSubModule(null)
        setSelectedText(null)
    }

    const onClickSubModule = async (submod, modKey) => {
        const res = await dispatch(textPdfActions.fetchTextPdfs())
        setLoadedTexts(res)
        setModuleKey(modKey)
        console.log('SubMod: ', submod);
        setSelectedModule(null)
        setSelectedSubModule(submod)
        setSelectedText(null);
    }

    const onClickText = (text) => {
        setSelectedModule(null)
        setSelectedText(text)
    }

    const onClickDelete = async () => {
        if (alertType === "deleteModule") {
            await dispatch(moduleActions.deleteModule(selectedModule.key, selectedModule.image))
            await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
            setSelectedModule(null)
            loadDatas()
        }
        if (alertType === "deleteSubModule") {
            const isLastSub = modules[moduleKey].submodulos.length - 1 == selectedSubModule.id ? true : false
            await dispatch(moduleActions.deleteSubModule(moduleKey, selectedSubModule.id, selectedSubModule.image, isLastSub))
            await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
            // loadDatas()
            const newSelectedModule = modules[moduleKey]
            let decreasedSubModules = [...newSelectedModule.submodulos]
            decreasedSubModules = decreasedSubModules.filter(sub => sub.id !== selectedSubModule.id)
            console.log('DS: ', newSelectedModule)
            newSelectedModule.submodulos = decreasedSubModules
            setSelectedModule(newSelectedModule)
        }
        if (alertType === "deleteText") {
            const isLastText = loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId).length - 1 == selectedText.key ? true : false
            await dispatch(textPdfActions.deleteTextPdf(selectedText.listaTextosId, selectedText.id, selectedText.key, isLastText, selectedText.image))
            await dispatch(textPdfActions.updateTextsVersion(textsVersion + 1))
            setSelectedText(null)
            // loadDatas()
            let decreasedTexts = [...searchedTexts.filter(t => t.id !== selectedText.id)]
            setSearchedTexts(decreasedTexts)

            console.log('DS: ', decreasedTexts)
        }
        setAlertVisible(false)
    }

    const handleSubDrag = (ev, item) => {
        setDragSubItem(item);
    };

    const handleSubDrop = async (ev, item) => {
        try {
            setSubDropping(true)
            await dispatch(moduleActions.changeSubmodulesOrder(selectedModule.key, dragSubItem.id, dragSubItem, item.id, item))
            await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
            // loadDatas()

            let drag = dragSubItem
            let drop = item

            // swicth and increase versions
            let oldDragVer = drag.versao
            let oldDropVer = drop.versao
            drop.versao = oldDragVer + 1
            drag.versao = oldDropVer + 1

            let keyDrop = drop.id
            let keyDrag = drag.id
            drop.id = keyDrag
            drag.id = keyDrop

            let newModule = selectedModule
            let newSubModules = [...newModule.submodulos]
            newSubModules[keyDrop] = drag
            newSubModules[keyDrag] = drop
            newModule.submodulos = newSubModules
            setSelectedModule(newModule)
            setSubDropping(false)
        } catch (err) {
            console.log('handleSubbDrop error: ', err)
            setSubDropping(false)
        }
    };

    const handleTextDrag = (ev, item) => {
        setDragTextItem(item);
    };

    const handleTextDrop = async (ev, item) => {
        await dispatch(textPdfActions.changeTextsOrder(item.listaTextosId, dragTextItem.key, dragTextItem, item.key, item))
        await dispatch(textPdfActions.updateTextsVersion(textsVersion + 1))
        // loadDatas()
        console.log('ST: ', searchedTexts)

        let drag = dragTextItem
        let drop = item

        console.log('DRAG: ', drag)
        console.log('DROP: ', drop)

        // swicth and increase versions
        let oldDragVer = drag.versao
        let oldDropVer = drop.versao
        let keyDrop = drop.key
        let keyDrag = drag.key
        drop.versao = oldDragVer + 1
        drag.versao = oldDropVer + 1
        drop.key = keyDrag
        drag.key = keyDrop

        let orderedTexts = [...searchedTexts]
        orderedTexts[keyDrop] = drag
        console.log(orderedTexts)
        orderedTexts[keyDrag] = drop
        setSearchedTexts(orderedTexts)
    };

    const handleModDrag = (ev, item) => {
        setDragModItem(item);
    };

    const handleModDrop = async (ev, item) => {
        await dispatch(moduleActions.changeModulesOrder(dragModItem, item))
        await dispatch(moduleActions.updateModulesVersion(modulesVersion + 1))
        // loadDatas()
        let drag = dragModItem
        let drop = item

        const dragKey = modules.findIndex(mod => mod.key === drag.key)
        const dropKey = modules.findIndex(mod => mod.key === drop.key)

        drag.versao = drag.versao + 1
        drop.versao = drop.versao + 1

        const newMods = [...modules]
        newMods[dragKey].orderNumber = drop.orderNumber
        newMods[dropKey].orderNumber = drag.orderNumber
        setModules(newMods.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1))
    };

    const CustomIcon = extended ? ReorderIcon : ViewModuleIcon

    if (loading) {
        return <div className={classes.centerIndicator}>
            <Dots />
        </div>
    }

    return (
        <div className={classes.Screen}>
            <div className={classes.modulesContainer} >
                <ButtonBoxSmall
                    title="Novo Modulo"
                    style={{ position: 'absolute', right: 5, top: 5 }}
                    onClick={() => { history.push('/modules/edit-module', { newOrderNumber: modules[modules.length - 1].orderNumber + 1 }) }}
                    type="success"
                />
                <div style={{ position: 'absolute', left: 5, top: 5 }} onClick={() => { setExtended(!extended) }}>
                    <CustomIcon />
                </div>
                {modules && !subDropping && modules.map((item, index) => (
                    <ModuleRow
                        key={index.toString()}
                        row={item}
                        onClickModule={onClickModule.bind(this, item)}
                        onClickSubModule={onClickSubModule}
                        isModuleSelected={selectedModule === item}
                        selected={selectedSubModule}
                        // shouldOpened={moduleId === item.key ? true : false}
                        extended={extended}
                        handleDrag={(ev) => handleModDrag(ev, item)}
                        handleDrop={(ev) => handleModDrop(ev, item)}
                    />
                ))}
                <div className={classes.semContainer}>
                    <p className={classes.semButton} id={selectedSubModule?.listaTextosId === 'textosSemAssociacao' ? classes.semButtonActive : null} onClick={() => { setSelectedModule(null); setSelectedSubModule({ listaTextosId: 'textosSemAssociacao' }) }}>
                        TEXTOS SEM ASSOCIAÇÃO
                    </p>
                </div>
            </div>

            {selectedModule &&
                <div className={classes.subModuleContainer} >
                    <ButtonBoxSmall
                        hide={selectedModule?.submodulos?.length > 0}
                        className={classes.deleteButton}
                        type="danger"
                        title="Deletar Modulo"
                        style={{ position: 'absolute', left: 5, top: 5 }}
                        onClick={() => {
                            setAlertType('deleteModule');
                            setAlertMessage(`Quer deletar o módulo ${selectedModule.title}?  Todos os submódulos relativos também serão excluídos!`);
                            setAlertVisible(true);
                        }}
                    />
                    <ButtonBoxSmall
                        title="Editar Modulo"
                        style={{ position: 'absolute', right: 5, top: 5 }}
                        onClick={() => { history.push('/modules/edit-module', { module: selectedModule }) }}
                        type="warning"
                    />
                    <div className={classes.titleContainer}>
                        <h5>{selectedModule.title}</h5>
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Código:</p>
                            <p className={classes.value}>{selectedModule.id}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Estado:</p>
                            <p className={classes.value}>{selectedModule.disabled == true ? "Desativado" : "Ativo"}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Invisível:</p>
                            <p className={classes.value}>{selectedModule.invisivel ? "SIM" : "Nao"}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Versão:</p>
                            <p className={classes.value}>{selectedModule.versao}</p>
                        </div>

                        <div className={classes.infoColumn} >
                            <div className={classes.infoRow} style={{ marginBottom: 5 }}>
                                <p className={classes.label}>Observação</p>
                                <p className={classes.label} style={{ color: selectedModule.useSpecialDescription ? 'green' : 'gray'}}>({selectedModule.useSpecialDescription ? 'Ativado' : 'Desabilitado'}):</p>
                            </div>
                            <p className={classes.value}>{selectedModule.observacao}</p>
                        </div>
                        <img className={classes.image} src={selectedModule.image} alt={selectedModule.title} />
                    </div>
                    <div className={classes.titleContainer}>
                        <div className={classes.iconTitle} >
                            <h5>SUB-MODULOS</h5>
                        </div>
                        <ButtonBoxSmall
                            title="Adicionar Sub-Modulo"
                            onClick={() => { history.push('/modules/edit-submodule', { moduleKey: selectedModule.key }) }}
                            type="success"
                        />
                    </div>
                    {selectedModule.submodulos.map((sub, index) => (
                        <SubModuleLine
                            key={index.toString()}
                            item={sub}
                            // onClick={onClickSubModule}
                            boxNumber={sub.id}
                            handleDrag={(ev) => handleSubDrag(ev, sub)}
                            handleDrop={(ev) => handleSubDrop(ev, sub)}
                        />
                    ))}
                    {selectedModule.submodulos?.length === 0 &&
                        <p>Não há submódulo ainda</p>
                    }
                </div>
            }

            {selectedSubModule &&
                <div className={classes.subModuleContainer} id={selectedSubModule?.listaTextosId === 'textosSemAssociacao' ? classes.semTitle : null} >
                    {selectedSubModule?.listaTextosId !== 'textosSemAssociacao' &&
                        <div className={classes.titleContainer}>
                            <div className={classes.iconTitle} >
                                <DashboardRoundedIcon style={{ color: Colors.primary }} />
                                <h5>{selectedSubModule.title}</h5>
                            </div>
                            <ButtonBoxSmall
                                hide={loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId)?.length > 0}
                                className={classes.deleteButton}
                                type="danger"
                                title="Deletar Sub-Modulo"
                                style={{ position: 'absolute', left: 5, top: 5 }}
                                onClick={() => {
                                    setAlertType('deleteSubModule');
                                    setAlertMessage(`Quer deletar o sub-módulo ${selectedSubModule.title}?`);
                                    setAlertVisible(true);
                                }}
                            />
                            <ButtonBoxSmall
                                title="Editar Sub-Modulo"
                                style={{ position: 'absolute', right: 5, top: 5 }}
                                onClick={() => { history.push('/modules/edit-submodule', { subModule: selectedSubModule, moduleKey: moduleKey }) }}
                                type="warning"
                            />
                        </div>
                    }
                    {selectedSubModule?.listaTextosId !== 'textosSemAssociacao' &&
                        <div className={classes.infoContainer}>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Key:</p>
                                <p className={classes.value}>{selectedSubModule.id}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Textos:</p>
                                <p className={classes.value}>{selectedSubModule.listaTextosId}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Texto Único:</p>
                                <p className={classes.value}>{selectedSubModule.idTextoASerExibido}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Estado:</p>
                                <p className={classes.value}>{selectedSubModule.disabled ? "Desativado" : "Ativo"}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Invisível:</p>
                                <p className={classes.value}>{selectedSubModule.invisivel ? "SIM" : "Nao"}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>URL:</p>
                                <p className={classes.value}>{selectedSubModule.url}</p>
                            </div>
                            <div className={classes.infoRow} >
                                <p className={classes.label}>Versão:</p>
                                <p className={classes.value}>{selectedSubModule.versao}</p>
                            </div>

                            <div className={classes.infoColumn} >
                                <p className={classes.labelColumn} style={{ marginBottom: 5 }}>Exibe Diretamente Texto:</p>
                                <p className={classes.value}>{selectedSubModule.exibeDiretamenteTexto ? "SIM" : "NAO"}</p>
                            </div>
                            <div className={classes.infoColumn} >
                                <p className={classes.labelColumn} style={{ marginBottom: 5 }}>Observação:</p>
                                <p className={classes.value}>{selectedSubModule.observacao}</p>
                            </div>
                            <div className={classes.infoColumn} >
                                <p className={classes.labelColumn} style={{ marginBottom: 5 }}>Imagem:</p>
                                <p className={classes.value}>{selectedSubModule.image}</p>
                            </div>
                        </div>
                    }

                    <div className={classes.titleContainer}>
                        <div className={classes.iconTitle} >
                            <DescriptionRoundedIcon style={{ color: Colors.primary, width: 16 }} />
                            <h5>{selectedSubModule?.listaTextosId === 'textosSemAssociacao' ? "TEXTOS SEM ASSOCIAÇÃO" : "TEXTOS"}</h5>
                        </div>
                        <ButtonBoxSmall
                            hide={selectedSubModule.exibeDiretamenteTexto && loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId).length > 0}
                            title="Adicionar Texto"
                            onClick={() => {
                                history.push('/modules/edit-text', {
                                    subModule: selectedSubModule,
                                    text: null,
                                    semAs: selectedSubModule?.listaTextosId === 'textosSemAssociacao' ? true : false
                                })
                            }}
                            type="success"
                        />
                    </div>

                    <SearchBar
                        style={{ alignSelf: 'center', marginBottom: 20, width: '90%' }}
                        data={selectedSubModule?.listaTextosId === 'textosSemAssociacao'
                            ? semTexts
                            : loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId)
                        }
                        setFixedData={(val) => { setSearchedTexts(val) }}
                        searchedValue1='title'
                        searchedValue2='id'
                    />

                    {
                        searchedTexts?.map((text, index) => (
                            <TextItem
                                key={index.toString()}
                                item={text}
                                onClick={onClickText.bind(this, text)}
                                isSelected={selectedText === text}
                                handleDrag={(ev) => handleTextDrag(ev, text)}
                                handleDrop={(ev) => handleTextDrop(ev, text)}
                            />
                        ))
                    }

                    {/* {selectedSubModule?.listaTextosId !== 'textosSemAssociacao' &&
                        loadedTexts.filter(t => t.listaTextosId === selectedSubModule?.listaTextosId).map((text, index) => (
                            <TextItem
                                key={index.toString()}
                                item={text}
                                onClick={onClickText.bind(this, text)}
                                isSelected={selectedText === text}
                                handleDrag={(ev) => handleTextDrag(ev, text)}
                                handleDrop={(ev) => handleTextDrop(ev, text)}
                            />
                        ))
                    } */}
                </div>
            }

            {selectedText &&
                <div className={classes.textsContainer} >
                    <ButtonBoxSmall
                        className={classes.deleteButton}
                        type="danger"
                        title="Deletar Texto"
                        style={{ position: 'absolute', left: 5, top: 5 }}
                        onClick={() => {
                            setAlertType('deleteText');
                            setAlertMessage(`Quer deletar o texto ${selectedText.title}?`);
                            setAlertVisible(true);
                        }}
                    />
                    <ButtonBoxSmall
                        title="Editar Texto"
                        style={{ position: 'absolute', right: 5, top: 5 }}
                        onClick={() => {
                            history.push('/modules/edit-text', {
                                subModule: selectedSubModule,
                                text: selectedText,
                                semAs: selectedSubModule?.listaTextosId === 'textosSemAssociacao' ? true : false
                            })
                        }}
                        type="warning"
                    />
                    <div className={classes.titleContainer}>
                        <h6 className={classes.textTitle} >{selectedText.title}</h6>
                    </div>
                    <div className={classes.infoContainer}>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Código:</p>
                            <p className={classes.value}>{selectedText.id}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Key:</p>
                            <p className={classes.value}>{selectedText.key}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Tipo:</p>
                            <p className={classes.value}>{selectedText.type || 'PDF'}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Textos:</p>
                            <p className={classes.value}>{selectedText.listaTextosId}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Submódulo:</p>
                            <p className={classes.value}>{selectedText.subModuloId}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Caminho:</p>
                            <p className={classes.value}>{selectedText.caminhoFirebaseStorage}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Data:</p>
                            <p className={classes.value}>{selectedText.dataPublicacao}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Dou:</p>
                            <p className={classes.value}>{selectedText.dou}</p>
                        </div>
                        {/* <div className={classes.infoRow} >
                        <p className={classes.label}>Meta Tags:</p>
                        <p className={classes.value}>{selectedText.metaTags}</p>
                    </div> */}
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Origem:</p>
                            <p className={classes.value}>{selectedText.origem}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Página:</p>
                            <p className={classes.value}>{selectedText.pagina}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Sessão:</p>
                            <p className={classes.value}>{selectedText.sessao}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Texto Pago:</p>
                            <p className={classes.value}>{selectedText.textoPago ? "SIM" : "NAO"}</p>
                        </div>
                        <div className={classes.infoRow} >
                            <p className={classes.label}>Versão:</p>
                            <p className={classes.value}>{selectedText.versao}</p>
                        </div>

                        <div className={classes.infoColumn} >
                            <p className={classes.label} style={{ marginBottom: 5 }}>Observação:</p>
                            <p className={classes.value}>{selectedText.observacao}</p>
                        </div>
                        {selectedText.image &&
                            <img className={classes.image} src={selectedText.image} alt={selectedText.title} />
                        }
                    </div>
                    {selectedText.type === 'EDITOR'
                        ? (
                            <ButtonText
                                title="Visualize o conteúdo"
                                onClick={() => history.push('/text-content?id=' + selectedText.id, { title: selectedText.title })}
                            />
                        )
                        : <a target="_blank" rel="noopener noreferrer" href={`https://pdf.xlei.com.br/preview?pdfId=${selectedText.id}`}>Visualize o documento</a>
                    }
                </div>
            }
            <Alert
                visible={alertVisible}
                message={alertMessage}
                onClickButton={onClickDelete}
                onClickCancel={() => { setAlertVisible(false) }}
            />
        </div>
    )
}

export default Modules
