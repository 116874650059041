import Advertisement from "../../models/Advertisement";
import environment, { auth, storage } from '../../env/environment'
import firebase from 'firebase/'
import moment from 'moment'

export const SET_ADS = 'SET_ADS';
export const CREATE_AD = 'CREATE_AD';
export const DELETE_AD = 'DELETE_AD';
export const UPDATE_AD = 'UPDATE_AD';

export const fetchAds = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${environment.databaseURL}/patrocinadores.json`);

            if (!response.ok) {
                throw new Error('Something went wrong with fetching Ads')
            }
            const resData = await response.json();
            const loadedAds = [];
            for (const key in resData) {
                loadedAds.unshift(
                    new Advertisement(
                        key,
                        resData[key].ativo,
                        resData[key].nome,
                        resData[key].observacao,
                        resData[key].enderecoDaImagem,
                        resData[key].link,
                    )
                )
            }
            dispatch({
                type: SET_ADS,
                ads: loadedAds,
            })
        } catch (err) {
            throw (err);
        }
    }
}

export const createAds = (file, name, description, state, link) => {
    return async (dispatch) => {
        let date = new Date()
        const fileName = name.replace(' ', '_') + '_' + moment(date).format('YY-MM-DD-hh-mm-ss').replace(' ', '_')
        let storageRef = storage.ref()
        let uploadTask = storageRef.child('patrocinadores/' + fileName).put(file);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
            }, (error) => {
                throw error
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
                    const idTokenResult = await auth.currentUser?.getIdTokenResult();
                    const token = idTokenResult?.token
                    const response = await fetch(`${environment.databaseURL}/patrocinadores.json?auth=${token}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            nome: name,
                            observacao: description,
                            ativo: state,
                            enderecoDaImagem: url,
                            link: link,
                        })
                    }
                    )
                    if (!response.ok) {
                        const errorData = await response.json()
                        throw new Error(errorData.error)
                    }
                    const resData = await response.json();

                    dispatch({
                        type: CREATE_AD,
                        adData: {
                            id: resData.name,
                            nome: name,
                            observacao: description,
                            ativo: state,
                            enderecoDaImagem: url,
                            link: link,
                        }
                    })
                })
            }
        )
    }
}

export const deleteAd = (id, url) => {
    return async (dispatch) => {
        let desertRef = storage.refFromURL(url);
        desertRef.delete().then(async () => {
            const idTokenResult = await auth.currentUser?.getIdTokenResult();
            const token = idTokenResult?.token
            const response = await fetch(
                `${environment.databaseURL}/patrocinadores/${id}.json?auth=${token}`,
                {
                    method: 'DELETE'
                });
            if (!response.ok) {
                throw new Error('Something went wrong!')
            }
            dispatch({
                type: DELETE_AD,
                aid: id
            });
        }).catch((error) => {
            throw new Error(error)
        });
    }
}

export const updateAd = (id, name, description, active, newFile, oldUrl, link) => {
    return async (dispatch) => {
        let date = new Date()
        const newFileName = name.replace(' ', '_') + '_' + moment(date).format('YY-MM-DD-hh-mm-ss').replace(' ', '_')
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const token = idTokenResult?.token
        let storageRef = storage.ref()
        // UPLOAD FILE
        if (newFile != null) {
            let uploadTask = storageRef.child('patrocinadores/' + newFileName).put(newFile);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot) => {
                }, (error) => {
                    throw error
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(async (newUrl) => {
                        // DELETE OLD FILE
                        let desertRef = storage.refFromURL(oldUrl);
                        desertRef.delete().then(async () => {
                            console.log("old file deleted")
                            const response = await fetch(`${environment.databaseURL}/patrocinadores/${id}.json?auth=${token}`, {
                                method: 'PATCH',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    nome: name,
                                    observacao: description,
                                    ativo: active,
                                    enderecoDaImagem: newUrl,
                                    link: link
                                })
                            })
                            if (!response.ok) {
                                const errorData = await response.json()
                                throw new Error(errorData.error)
                            }
                            dispatch({
                                type: UPDATE_AD,
                                aid: id,
                                adData: {
                                    nome: name,
                                    observacao: description,
                                    ativo: active,
                                    enderecoDaImagem: newUrl,
                                    link: link,
                                }
                            })
                        }).catch((error) => {
                            throw new Error(error)
                        });
                    })
                }
            )
        } else {
            const response = await fetch(`${environment.databaseURL}/patrocinadores/${id}.json?auth=${token}`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    nome: name,
                    observacao: description,
                    ativo: active,
                    enderecoDaImagem: oldUrl,
                    link: link,
                })
            })
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(errorData.error)
            }
            dispatch({
                type: UPDATE_AD,
                aid: id,
                adData: {
                    nome: name,
                    observacao: description,
                    ativo: active,
                    enderecoDaImagem: oldUrl,
                    link: link,
                }
            })
        }




    }
};