import React, { useState, useEffect, useCallback } from 'react'
import classes from './EditTextEditor.module.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import environment, { db, storage } from '../../env/environment'
import firebase from 'firebase/'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as textPdfActions from '../../store/actions/textPdfActions'
import ButtonBox from '../../components/UI/ButtonBox/ButtonBox'
import { convertToHTML } from 'draft-convert'

import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

const EditSubModulePage = props => {
  const [editorState, setEditorState] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { textId } = location?.state
  const { title } = location?.state

  useEffect(() => {
    loadData()
  }, [textId])

  const loadData = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${environment.databaseURL}/contents/${textId}/content.json`
      )
      if (!response.ok) {
        throw new Error('Something went wrong with fetching')
      }
      const htmlResult = await response.json()
      console.log('htmlResult: ', htmlResult)

      const contentBlock = htmlToDraft(htmlResult)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        const currentEditorData = EditorState.createWithContent(contentState)
        setEditorState(currentEditorData)
      }

      setLoading(false)
    } catch (err) {
      console.log('Error: ', err.message)
      setLoading(false)
    }
  }

  const onEditorStateChange = val => {
    setEditorState(val)
  }

  function uploadCallback (file) {
    return new Promise((resolve, reject) => {
      uploadImage(file)
        .then(link => {
          resolve({ data: { link } })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  function uploadImage (file) {
    let random = Math.floor(Math.random() * 1000000)

    const name = textId + ' - ' + random
    return new Promise((resolve, reject) => {
      if (!file) {
        reject('Invalid file.')
      }

      let storageRef = storage.ref()
      let uploadImage = storageRef.child(`content/${name}`).put(file)
      uploadImage.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {},
        error => {
          console.log('Errorrr: ', error)
          throw error
        },
        () => {
          uploadImage.snapshot.ref.getDownloadURL().then(async url => {
            resolve(url)
          })
        }
      )
    })
  }

  const updateDataHandler = async () => {
    // setLoading(true)

    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    console.log('html: ', html)
    try {
      db.ref(`/contents`)
        .child(textId)
        .update({ content: html })
      history.push('/')
    } catch (err) {
      console.log('Error: ', err.message)
      // setLoading(false)
    }
  }

  return (
    <div className={classes.Screen}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>Edit Content: </p>
        <p className={classes.textTitle}>{title}</p>
      </div>
      <div className={classes.Row}>
        <Editor
          editorState={editorState}
          toolbarClassName={classes.toolbarClassName}
          wrapperClassName={classes.wrapperClassName}
          editorClassName={classes.editorClassName}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              uploadCallback: uploadCallback,
              previewImage: true,
              inputAccept: 'image/jpeg,image/jpg,image/png',
              alignmentEnabled: false,
              defaultSize: {
                height: 'auto',
                width: '100%'
              }
            }
          }}
        />
        <ButtonBox
          type='success'
          style={{ marginLeft: 16 }}
          title='Salvar'
          onClick={updateDataHandler}
        />
      </div>
    </div>
  )
}

export default EditSubModulePage
