import React from 'react'
import Colors from '../../../constants/colors'
import { Label, Container, Loader } from './styles'
import { Props } from './types'

const Button: React.FC<Props> = ({ label, onClick, color, size='large', style, loading }) => {

    if(loading) {
        <Container style={style} onClick={onClick} color={color || Colors.primary} size={size} >
            <Loader/>
        </Container>
    }
    return (
        <Container style={style} onClick={onClick} color={color || Colors.primary} size={size} >
            <Label size={size}>{label}</Label>
        </Container>
    )
}

export default Button