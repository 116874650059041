import React from 'react'
import '../App.css'

import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccount';
import SlideshowRoundedIcon from '@material-ui/icons/SlideshowRounded';
import NotificationsRoundedIcon from '@material-ui/icons/Notifications';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
// import PostAddRoundedIcon from '@material-ui/icons/PostAdd';
import ChatBubbleRoundedIcon from '@material-ui/icons/ChatBubbleRounded';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import GavelRoundedIcon from '@material-ui/icons/GavelRounded';
import PublicIcon from '@material-ui/icons/Public';

export const SidebarData = [
    {
        title: 'Módulos', 
        icon: <DashboardRoundedIcon style={{width: 21}} />, 
        link: '/'
    },
    // {
    //     title: 'Texts', 
    //     icon: <PostAddRoundedIcon style={{width: 21}} />, 
    //     link: '/texts'
    // },
    {
        title: 'Mensagens', 
        icon: <ChatBubbleRoundedIcon style={{width: 21}} />, 
        link: '/messages'
    },
    {
        title: 'Notificações', 
        icon: <NotificationsRoundedIcon style={{width: 21}} />, 
        link: '/notifications'
    },
    {
        title: 'Usuários',
        icon: <SupervisorAccountRoundedIcon style={{width: 21}} />, 
        link: '/users'
    },
    {
        title: 'Patrocinadores', 
        icon: <SlideshowRoundedIcon style={{width: 21}} />, 
        link: '/advertisements'
    },
    {
        title: 'FAQs', 
        icon: <LiveHelpRoundedIcon style={{width: 21}} />, 
        link: '/faqs'
    },
    {
        title: 'Sobre', 
        icon: <InfoRoundedIcon style={{width: 21}} />, 
        link: '/about'
    },
    {
        title: 'Textos Legais', 
        icon: <GavelRoundedIcon style={{width: 21}} />, 
        link: '/legal'
    },
    {
        title: 'Publicidade', 
        icon: <PublicIcon style={{width: 21}} />, 
        link: '/terraviva-admin'
    },
    {
        title: 'Terra Viva Banners', 
        icon: <PublicIcon style={{width: 21}} />, 
        link: '/terraviva-banners'
    },
]
